const EditHoursSubmit = async (getAccessTokenSilently, userId, businessHours, locationId ) => {
   const apiUrl = process.env.REACT_APP_API_URL;
    const locationData = {
      userId,
      businessHours,
      locationId,
  
    };



  
    try {
      // Get the access token silently
      const accessToken = await getAccessTokenSilently();
  
      // Send POST request with the access token in the Authorization header
      const response = await fetch(`${apiUrl}/manage/locations/editHours`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`, // Include the access token
        },
        body: JSON.stringify(locationData),
      });
  
      // Check for response status
      if (!response.ok) {
        throw new Error(`Failed to create location: ${response.statusText}`);
      }
  
      // Parse and return the response JSON
      const result = await response.json();
      return result; // Resolve with result on success
    } catch (error) {
      console.error('Error creating location:', error);
      throw error; // Reject with error on failure
    }
  };
  
  // Export the function as the default export
  export default EditHoursSubmit;