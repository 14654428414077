import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAccountContext } from 'src/context/AccountContext';

const useAddNewUser = () => {
    const { accountData } = useAccountContext();    
    const { getAccessTokenSilently } = useAuth0();
    const [addUserLoading, setAddUserLoading] = useState(false);
    const [addUserError, setAddUserError] = useState(null);
    const [success, setSuccess] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [price, setPrice] = useState(null); 
    const [productId, setProductId] = useState(null);
    const customerId = accountData?.customer_id; 




    useEffect(() => {
        const fetchSubscriptionData = async () => {
          try {
            // Get the access token silently
            const accessToken = await getAccessTokenSilently();
    
            // Fetch data with Authorization header
            const response = await fetch(
              `${apiUrl}/subscriptions/plans/getSubscription/addAdditionalUser`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`, // Include the token
                },
              }
            );
                
                // Check if the response is OK (status code 200-299)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
  
                const data = await response.json();
                setSubscriptionData(data.addAdditionalUser); // Adjust according to the title
                setPrice(data.addAdditionalUser.price); // Adjust according to the title
                setProductId(data.addAdditionalUser.PID); // Adjust according to the title

            } catch (err) {
                setError('Error fetching subscription data: ' + err.message);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
  
        fetchSubscriptionData();
    }, []); // Empty dependen


    
  



    const addNewUser = async (subscriptionId, quantity) => {
        setAddUserLoading(true);
        setAddUserError(null);
        setSuccess(false);

        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${apiUrl}/subscriptions/manage/addAdditional`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    customerId,
                    productId, // Ensure you're sending productId here
                    price,
                    quantity:1,  // Add the quantity field to specify how many locations to add
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update subscription');
            }

            const data = await response.json();
            setSuccess(true);
            return data; // Return the response data if needed
        } catch (err) {
            setAddUserError(err.message);
            console.error(err);
        } finally {
            setAddUserLoading(false);
        }
    };

    return {
        addNewUser,
        addUserLoading,
        addUserError,
        success,
    };
};

export default useAddNewUser;