import React, {useEffect, useState} from 'react';
import { Box, Button, IconButton, InputAdornment, TextField, Typography, Divider, Paper,Stack, Dialog,  } from '@mui/material';
import AddLocationCard from './addLocationCard';
import AddUserCard from './addUserCard';
import DeleteUserCard from './deleteUserCard';  
import DeleteLocationCard from './deleteLocationCard';
import RemovePlan from './removePlan';
import RemovePlanMultiUser from './removePlanMultiUser';
import AddPlan from './addPlan';
import UpdateLocationCard from './updateLocation';
import CloseIcon from '@mui/icons-material/Close';
import CancelCloseDialog from 'src/components/modal/CancelCloseDialog';




const ReviewDialog = ({
 open, 
 onClose, 
 subtitle,
 addedLocation, 
 updatedLocation,
  addedUser,
  deletedUser,
  deletedLocation,  
  updatedUser,
  addedUserPrice,
  addedLocationPrice,
  userName,
  role,
 price, 
 buttonText,
 firstName,
  lastName,
  plan,
  buttonAction,
  isLoading,
  buttonLoadingText,
  name,
  phone,
  email,
  item,
  changedPlan,
  newPlanName, 
  newPlanFeatureOne,
  newPlanFeatureTwo,
  newPlanFeatureThree,
  oldPlanName,
  oldPlanFeatureOne,
  oldPlanFeatureTwo,  
  oldPlanFeatureThree,
  oldPlanPrice,
  newPlanPrice,
  handleCancel,
  CancelCloseMessage, 
  CancelCloseSubText,
  right,
  addedUserCost,



}) => { 



  const [openCancelCloseDialog, setOpenCancelCloseDialog] = useState(false);


  const handleOpenCancelCloseDialog = () => { 
    setOpenCancelCloseDialog(true);
  };

  const handleCloseCancelCloseDialog = () => {
    setOpenCancelCloseDialog(false);
  };



  const newUsers = right.filter(user => user?.isNewUser === true);




    return (
      <Dialog open={open} 
      onClose={isLoading ? null : onClose} // Disable closing while loading
      >
<Box
  sx={{
    backgroundColor: '#F2F9FF',
    border: '1.25px solid rgba(173, 216, 230, 0.5)',
    padding: '.5rem',

    maxWidth: '750px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '1rem',
  }}
>
<IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>

        <Box
        sx={{
          overflowY: 'auto',
          backgroundColor: 'transparent',
          width:'500px',
 
        }}
      >

<Typography
  sx={{
    color: '#48768C',
    fontWeight: 600,
    fontSize: '16.5px',
    marginTop: '.5rem',
    mb:.5,
    textAlign: 'left',
    padding: '.25rem',
  }}
>
  Review Your Plan Changes
</Typography>
        
 {subtitle && (     
<Typography
  sx={{
    color: '#48768C',
    fontWeight: 600,
    fontSize: '15.5px',
    marginBottom: '.25rem',
    textAlign: 'left',
    padding: '.25rem',
  }}
>
  {subtitle}
</Typography> 
)}

<Box sx={{padding:1}}>


{changedPlan && ( 
  <>
  <Stack direction='row' spacing={17} sx={{pl:'8px', mb:1, mt:1}} >
    <Box sx={{border:'1px solid rgba(191, 218, 231, 0.9)',  borderRadius:'6px', width:'25%', backgroundColor: 'rgba(191, 218, 231, 0.40)' ,boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)', ml:'6px', mb:1, mt:1}}>

<Typography sx={{fontSize:'12.5px', fontWeight:600, color:'#4A6B75',px:'5px', letterSpacing:'-2%',    
 }}>

{newPlanPrice}   
</Typography>
        </Box> 
        <Box sx={{border:'1px solid rgba(191, 218, 231, 0.9)',  borderRadius:'6px', width:'25%', backgroundColor: 'rgba(191, 218, 231, 0.40)' ,boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)', ml:'6px', mb:1, mt:1}}>

<Typography sx={{fontSize:'12.5px', fontWeight:600, color:'#4A6B75',px:'5px', letterSpacing:'-2%',    
 }}>

{oldPlanPrice}
</Typography>
        </Box> 
        </Stack>
  <Stack direction="row" spacing={2} >  
 
  <AddPlan
 newPlanName={newPlanName}
        newPlanFeatureOne={newPlanFeatureOne}
        newPlanFeatureTwo={newPlanFeatureTwo}
        newPlanFeatureThree={newPlanFeatureThree}
  
  />
  
<RemovePlan

oldPlanName={oldPlanName}
oldPlanFeatureOne={oldPlanFeatureOne}
oldPlanFeatureTwo={oldPlanFeatureTwo}
oldPlanFeatureThree={oldPlanFeatureThree}

/>
</Stack>
</>
)}





        {addedLocation && (
          <>
        <Box sx={{border:'1px solid rgba(191, 218, 231, 0.9)',  borderRadius:'6px', width:'19%', backgroundColor: 'rgba(191, 218, 231, 0.40)' ,boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)', ml:'4px'}}>

<Typography sx={{fontSize:'12.5px', fontWeight:600, color:'#4A6B75',px:'5px', letterSpacing:'-2%',    
 }}>

    {addedUserPrice}
</Typography>
        </Box>
 <AddLocationCard
 name={name}
 email={email}
 phone={phone}
 assignedUser='insert data'
 addedEmployees={false}
 assignedLocation='insert data'
 firstName='insert data'
 lastName='insert data' 
 userName='insert data'
 right='insert data'
 users='insert data'
 />
</> 
        )}


{updatedLocation && (
<UpdateLocationCard
updatedName='insert data'
newUserName='insert data'
users='insert data'
accountData='insert data'
/> 
)}

{deletedLocation && (

<>
           <Box sx={{border:'1px solid rgba(191, 218, 231, 0.9)',  borderRadius:'6px',  height:'14%', backgroundColor: 'rgba(191, 218, 231, 0.40)' ,boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)', ml:'4px', mt:2, width:'19%'}}>

<Typography sx={{fontSize:'12.5px', fontWeight:600, color:'#4A6B75',px:'5px', letterSpacing:'-2%',    
 }}>

   {addedUserPrice}
</Typography>
        </Box>
    <DeleteLocationCard   
      name={item?.name}
      phone={item?.phone} 
      email={item?.email} 



    />
              </>
)}


<>
      {newUsers?.length > 0 && newUsers.map((user, index) => (
        <React.Fragment key={index}>
          <Box
            sx={{
              border: '1px solid rgba(191, 218, 231, 0.9)',
              borderRadius: '6px',
              height: '14%',
              backgroundColor: 'rgba(191, 218, 231, 0.40)',
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
              ml: '4px',
              mt: 2,
              width: '19%',
            }}
          >
            <Typography
              sx={{
                fontSize: '12.5px',
                fontWeight: 600,
                color: '#4A6B75',
                px: '5px',
                letterSpacing: '-2%',
              }}
            >
              {/* Assuming addedUserPrice is a property of each user, otherwise replace with relevant data */}
              {addedUserCost || "N/A"}
            </Typography>
          </Box>
          <AddUserCard
            title="Added New User"
            role={user.title || "N/A"}
            firstName={user.first_name || "N/A"}
            lastName={user.last_name || "N/A"}
            userName={user.user_name || "N/A"}
            name={name || "N/A"}
            multiUser={true}
          />
        </React.Fragment>
      ))}
    </>


{deletedUser && (

<>
           <Box sx={{border:'1px solid rgba(191, 218, 231, 0.9)',  borderRadius:'6px',  height:'14%', backgroundColor: 'rgba(191, 218, 231, 0.40)' ,boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)', ml:'4px', mt:2, width:'19%'}}>

<Typography sx={{fontSize:'12.5px', fontWeight:600, color:'#4A6B75',px:'5px', letterSpacing:'-2%',    
 }}>

   {addedUserPrice}
</Typography>
        </Box>
    <DeleteUserCard  
        title="Removed User"
        role={role}
        firstName={firstName}
        lastName={lastName}
        userName={userName}
        multiUser={false}



    />
              </>
)}


{updatedUser && (

    <AddUserCard
    title="Updated Exsisting User"
    newUserName='insert data'
    firstName='insert data'
    lastName='insert data'
    name='insert data'
    

    />
)}



    </Box>
    <CancelCloseDialog
        open={openCancelCloseDialog}
        onClose={handleCloseCancelCloseDialog}
        buttonAction={handleCancel}
        CancelCloseMessage={CancelCloseMessage}
        CancelCloseSubText={CancelCloseSubText}
        />
    <Box 
  sx={{ 
    display: 'flex', 
    flexDirection: 'row', 
    pt: 2, 
    mt: 1,  
    alignItems: 'center', 
    justifyContent: 'center', // Center horizontally
    width: '100%' 
  }}
>
  <Button 
  onClick={buttonAction}
  disabled={isLoading}
    sx={{
      display: 'flex',
      border: '1px solid #48768C',
      borderRadius: '10px',
      padding: '5px',
      color: '#48768C',
      backgroundColor: '#FFFFFF',
      mb: 1,
      alignItems: 'center',
      width: '97%',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: '#DBEAF0',
      },
      '&:active': {
        backgroundColor: '#DBEAF0',
      },
    }}
    color="primary"
  >
  {isLoading ? buttonLoadingText : buttonText} 
  </Button>
  
</Box>
      </Box>
      </Box>

      </Dialog>

    );
}

export default ReviewDialog;