import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.grey[500], 0.12),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.grey[500], 0.32), // Default border color
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2F5C71', // Border color on hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#48768CBA', // Border color on focus
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
          '& .MuiInputLabel-root': {
            color: '#6c90a2', // Default label color
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#5a8397', // Label color when focused
            fontSize: '16px',

          },
        },
        input: {
          '&::placeholder': {
            color: '#B0BEC5', // Placeholder text color
            fontWeight: 500,
            fontFamily: 'Source Sans Pro, sans-serif', // Placeholder font styling
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            padding: '10px 14px',
            '&::placeholder': {
              color: '#B0BEC5',
              fontWeight: 500,
              fontFamily: 'Source Sans Pro, sans-serif',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.grey[500], 0.32), // Default border color
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2F5C71', // Border color on hover
            color: '#48768C',

          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#48768CBA', // Border color on focus
            color: '#48768C',
          },
        },
      },
    },
  };
}