import React, { useState, useEffect } from 'react';
import { Link, useHistory,useNavigate } from 'react-router-dom'; // Import useHistory hook
import { alpha } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../context/AuthContext.js';
import { useAppContext } from '../../context/AppContext.js';
import ChangeLocationModal from '../../components/modal/ChangeLocationModal.js';
import { useAccountContext } from 'src/context/AccountContext.js';
import LockedIcon from 'src/public/assets/icons/icons/locked.png';
import { set } from 'lodash';


const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    link: '/dashboard/app',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    link: '/dashboard/Account/Profile',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    link: '/dashboard/Account/Notifications',
  },
];


export default function AccountPopover({accountData}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID; 
  const clientToken = process.env.REACT_APP_CLIENT_TOKEN;
  const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
  const { isAuthenticated: isAuth0Authenticated, logout: auth0Logout } = useAuth0();
  const { logout: customLogout } = useAuth();
  const { username, subUserData, setSubUserData } = useAuth();
  const [open, setOpen] = useState(null);
  const [locations, setLocations] = useState([]);
  const [initialLocation, setInitialLocation] = useState('');
  const [isSwitchLocationModalOpen, setIsSwitchLocationModalOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [editData, setEditData] = useState({
    assigned_location: '',
  });
  const { reloadComponents, updateActiveLocation, fetchLatestUserData, handleReloadComponents, showSnackBar } = useAppContext();
  const { single_user } = accountData || {};
  const { single_location } = accountData || {};  

  const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
  const navigate = useNavigate();


  



  const [userLocation, setUserLocation] = useState('');
  const [locationChanged, setLocationChanged] = useState(false);
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const [forceRender, setForceRender] = useState(false);
  const [loading, setLoading] = useState(false);  



  useEffect(() => {
    // Toggle forceRender to trigger a re-render
    setForceRender((prev) => !prev);
  }, [subUserData, accountData]);





  const initials = subUserData
    ? `${subUserData.first_name?.charAt(0)}${subUserData.last_name?.charAt(0)}`.toUpperCase()
    : '';


  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenSwitchLocationModal = () => {

    // Set the initial value of the location dropdown to the user's active location
    setEditData({
      assigned_location: subUserData.active_location,
    });

    fetchLatestUserData();
    setIsSwitchLocationModalOpen(true);

  };



  const handleLogout = async () => {
  customLogout();
  resetContext(); // Reset the context to clear user state
  setTimeout(() => {
    auth0Logout({ returnTo: window.location.origin });
  }, 500);
};


  const { resetContext } = useAuth();

  const handleSwitchUser = () => {
    resetContext(); // Reset the context to clear user state
    setIsSwitchUserModalOpen(true);
    setOpen(false);
  
    // Get the navigate function from useNavigate hook
  
    // Navigate to the dashboard app page
    navigate('/dashboard/app');
  };
  



  const handleEditSave = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${apiUrl}/api/users/${user.sub}/location/${username}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          assigned_location: editData.assigned_location,
        }),
      });
  
      if (response.ok) {
        // Fetch the updated user data directly from the API
        const updatedUserDataResponse = await fetch(
          `${apiUrl}/api/users/profile/${user.sub}/${username}`,
          {
            method: 'GET', // or 'POST', 'PUT', etc. depending on your needs
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            },
          }
        );
        const updatedUserData = await updatedUserDataResponse.json();
      
        // Update the user data in the context directly
        setSubUserData(updatedUserData);
        handleReloadComponents();
        setOpen(false);
        setIsSwitchLocationModalOpen(false);
        showSnackBar(`Location Changed: ${updatedUserData.location_name}`, true);  
        setLoading(false);
      } else {
        console.error('Failed to update location');
        showSnackBar('Failed to update location', false);
        setLoading(false);
      }
      } catch (error) {
        console.error('Error:', error);
        showSnackBar('Failed to update location', false);
        setLoading(false);  
      }
      };
  


  return (
    <>
      {isAuthenticated && (
        <IconButton
          onClick={handleOpen}
          sx={{
            mt: 1,
            mr: 4,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                '&:hover': { boxShadow: 'none!important' },
              },
            }),
          }}
        >
          <Avatar sx={{           backgroundColor: '#48768C',

 padding: .75, '&:hover': { boxShadow: 'none!important' } }}>
            {initials ? (
            <Typography  sx={{ color: '#FBFBFB', margin: 1,fontSize:'1.1rem',fontWeight:600, '&:hover': { boxShadow: 'none!important' } }}>
              {initials}
     
            </Typography>
                     ) : (
                      <img src={LockedIcon} alt="User" style={{ width: 25, height: 25 }} />
                    )}
          </Avatar>
        </IconButton>
      )}

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 'auto',
            ml: 'auto',
            width: 180,
            border: '1.25px solid rgba(173, 216, 230, 0.5)',
            backgroundColor: '#F2F9FF',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
              '&:hover': {
                boxShadow: 'none!important',
              },
            },
          },
        }}
        slotProps={{ backdrop: { onClick: handleClose } }}
      >
        {isAuthenticated && (
          <Box sx={{ my: 1.5, px: 1.9 }}>
            <>
              <Typography variant="subtitle2" noWrap  sx={{color:'#48768C'}}>
                {subUserData &&
                  (subUserData.first_name && subUserData.last_name
                    ? `${subUserData.first_name} ${subUserData.last_name}`
                    : 'Name not available')}
              </Typography>


              {!single_location && subUserData && accountData && subUserData.location_name !== null && subUserData.location_name !== undefined && (
    <Typography
        key={forceRender}
        variant="body2"
        sx={{ color: '#628197' }}
        noWrap
    >
        {subUserData.location_name}
    </Typography>
)}

{accountData && single_location === true && (
  <Typography  sx={{ color: '#628197', whiteSpace: 'nowrap', fontSize:12 }} noWrap>
                  {accountData.company_email}
                </Typography>
              )}

              
            </>
          </Box>
        )}
        <Divider sx={{ borderStyle: 'dashed' }} />

        {isAuthenticated && (
          <>
          
            {MENU_OPTIONS.map((option) => (
              <MenuItem

                key={option.label}
                to={option.link}
                component={Link}
                onClick={handleClose}
                sx={{   '&:hover': { boxShadow: 'none!important' } }}
              >
            <Typography  sx={{ fontSize:'.80rem',borderRadius: 0.75, color:'#5A798F', mt:.25 }}>
                {option.label}
                </Typography>
              </MenuItem>
            ))}
            <Divider sx={{ borderStyle: 'dashed' }} />
            {accountData && accountData.single_location === false && accountData.total_locations >= 2 && (
  <MenuItem
    onClick={handleOpenSwitchLocationModal} 
    sx={{ borderRadius: 0.75, mb: 0.25 }}
  >
    <Typography sx={{ fontSize: '.80rem', borderRadius: 0.75, color: '#5A798F' }}>
      Switch Location
    </Typography>
  </MenuItem>
)}

            {accountData && accountData.single_location === false && (
              <ChangeLocationModal
                isOpen={isSwitchLocationModalOpen}
                onClose={() => setIsSwitchLocationModalOpen(false)}
                onSave={handleEditSave}
                editData={editData}
                setEditData={setEditData}
                locations={locations}
                initialLocation={subUserData ? subUserData.active_location : ''}
                loading={loading}
                setLoading={setLoading}
              />
            )}
            {(user?.sub === clientToken && username === clientId) && (
              <MenuItem component={Link} to="/dashboard/AdminPanel" onClick={handleClose}>
            <Typography  sx={{ fontSize:'.80rem',borderRadius: 0.75,color:'#5A798F' }}>

                Admin Dashboard
                </Typography>
              </MenuItem>
            )}
{accountData && accountData.single_user !== true && accountData.total_employees >= 2 && (
  <MenuItem onClick={handleSwitchUser}>
    <Typography sx={{ fontSize: '.80rem', borderRadius: 0.75, color: '#5A798F' }}>
      Switch User
    </Typography>
  </MenuItem>
)}
            <MenuItem onClick={handleLogout}>
            <Typography  sx={{ fontSize:'.80rem',borderRadius: 0.75,color:'#5A798F' }}>

              Logout
              </Typography>
              </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}