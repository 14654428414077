import React, {useState, useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,Box, Fade, Stack } from '@mui/material';
import AlertIcon from 'src/public/assets/icons/icons/alert.png';





const ConfirmDialog = ({ open, onClose, onConfirm,  isLoading, header, message, subText, buttonAction, addedUserPrice,hideReviewDialog, 
  buttonText, cancelButtonText }) => {
  const [openReviewDialog, setOpenReviewDialog] = useState(false); 


  
  const handleCloseReviewDialog = () => { 
    setOpenReviewDialog(false);
  };

  const handleOpenReviewDialog = () => {  
    if (hideReviewDialog === false) {
    setOpenReviewDialog(true);
    }
    else {
      onConfirm();
    }
  };
 
  useEffect(() => {
    if (open === false) {
      setOpenReviewDialog(false)
    }
  }
  , [open])


  
  
  return (
    <Dialog open={open} onClose={onClose} 
    TransitionComponent={Fade}
    transitionDuration={{ enter: 300, exit: 300 }}
    PaperProps={{
        style: {  
          backgroundColor: '#747C86', 
          width: '200px', 
          border: '1px solid rgba(173, 216, 230, 0.7)', 
          minWidth: '400px',
          borderRadius: '18px',
          bottom: '5%',
          maxWidth: '600px',
        },
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
>     
  <DialogTitle
    sx={{
      width: '100%',
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      padding: 3,
      color: "#374247",
      alignItems: "center",
    }}
  >
    <Box sx={{}}>
      <img
        src={AlertIcon}
        alt="detailsIcon"
        style={{
          border: 'transparent',
          width: "auto",
          height: "40px",
          backgroundColor:'#48768C3B',
          borderRadius: "6px",
          padding: "1px",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
          marginBottom: "5px", 
        }}
      />
    </Box>
    <Typography sx={{ color: "#48768C", mb: 0.5, fontWeight: 600, mt: 1.5, fontSize: '18px' }}>
      {header}
    </Typography>
  </DialogTitle>
  <DialogContent
    sx={{         
      mt: -1,
      fontWeight: 600,
      letterSpacing: '0.02em',
      backgroundColor: "#FFFFFF",
      color: '#48768C',
      padding: '.75rem',
    
    }}
  >
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      backgroundColor: 'rgba(191, 218, 231, 0.1)',
      border: '1px solid rgba(173, 216, 230, 0.7)', 
      borderRadius: '10px',  
      fontSize: 15, 
      padding: '.75rem', 
      pt: 2, 
      px:1.5,
    }}>            
      <Typography sx={{ fontSize: '15px',   alignItems: 'center', textAlign: 'center', }}>
        {message}
      </Typography>
      <Typography sx={{ fontSize: '14.5px', mt: 1.5, fontWeight: 'bold',       alignItems: 'center', textAlign: 'center', width:'auto'
 }}>
        {subText}
      </Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2,pt:1, pb:2 }}>
        <Stack direction="column" spacing={2.5} sx={{width:'100%', display:'flex'}}>
        
          <Button 
            onClick={buttonAction} 
            disabled={isLoading}     
            sx={{
              display: 'flex',
              border: '1px solid #48768C3B',
              borderRadius: '10px',
              padding: '8px',
              color: '#48768C',
              width:'100%',
              backgroundColor: '#A4C5D230',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',




              '&:hover': {
                backgroundColor: '#48768C1A',
              },
              '&:active': {
                backgroundColor: '#A4C5D230',
              },
              }}
              color="primary"
              >
{ buttonText }
          </Button>
          <Button 
            onClick={onClose}
            sx={{
              marginRight: '10px',
              border: '1px solid #A4C5D2E8',
              borderRadius: '10px',
              padding: '8px',
              color: '#48768C',
              width:'100%',
              backgroundColor: '#FFFFFF',
              '&:hover': { backgroundColor: '#48768C12' },
              '&:active': { backgroundColor: '#DBEAF0' },
            }}
            color="primary"
          >
            {cancelButtonText}
          </Button>
        </Stack>
      </Box>
    </Box>
  </DialogContent>
    </Dialog>
   );
};

export default ConfirmDialog;
