import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Card,
    Typography,
    Paper,
    TableFooter,
    Button,
    Popover,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CardMedia,
    Skeleton
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from '../../context/AppContext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useChart } from '../../components/chart';
import LinearProgress from '@mui/material/LinearProgress';
import { useAccountContext } from '../../context/AccountContext'; // Import AppPr
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { set } from 'lodash';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import DeleteIcon from "../../public/assets/icons/icons/delete.png";
import DetailsIcon from "../../public/assets/icons/icons/details.png";
import  fetchGlobalNotifications  from '../../utils/notificationUtils'; 
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MessageDetails from '../../../src/components/modal/MessageDetails.js';
import { useWidgetContext } from 'src/context/WidgetContext';
import Scheduled from "../../public/assets/illustrations/scheduled.png";
import StatusTag from 'src/components/color-utils/statusTags';
import CreateCustomerModal from 'src/components/modal/CreateCustomerModal';
import saveCustomerData from '../../components/customers/saveCustomerData';
import { useAuth } from 'src/context/AuthContext';
import ScheduledDialog from 'src/components/modal/ScheduledDialog';













const StatusIcon = ({ status }) => {
    let icon;
    let color;

    switch (status) {
        case null:
            icon = <HelpOutlineIcon />;
            color = '#7589A2'; // Blue color
            break;
        case 'Sent':
            icon = <CheckCircleIcon />;
            color = '#2BBB9E'; // Light green color
            break;
        case 'Failed':
            icon = <CancelPresentationIcon />;
            color = '#8C4350'; // Red color
            break;
            case 'Scheduled':
                icon = <AlarmOnIcon />;
                color = '#5B5B62'; // Red color
                break;
        default:
            icon = null;
    }

    return icon ? <span style={{ color,  }}>{icon}</span> : null;
};

export default function ScheduledMessages() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { reloadComponents, showSnackBar } = useAppContext();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { accountData } = useAccountContext(); // Use the accountData from the context

    const [loading, setLoading] = useState(false);
    const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const anchorRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { handleReloadComponents } = useAppContext();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [isMessageDetailsOpen, setIsMessageDetailsOpen] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null); // Step 1: State variable to track selected row index
    const { scheduledNotificationsData, loadingScheduledNotifications, pauseAutoRefresh, resumeAutoRefresh, fetchAllWidgetTypes } = useWidgetContext();
   
   
   
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [customerFeedData, setCustomerFeedData] = useState([]);
    const { subUserData } = useAuth();  


    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
      const img = new Image();
      img.src = Scheduled;
      img.onload = () => setImageLoaded(true);
  
      // Optional: Cleanup if the component unmounts before the image loads
      return () => {
        img.onload = null;
      };
    }, []);
  





    // Handle saving customer
    const handleSaveCustomer = async (firstName, lastName, location_name) => {
      const success = await saveCustomerData(selectedPhoneNumber, firstName, lastName, user.sub, location_name,  );
    
      if (success) {
        handleCloseModal();
      }
    };  
    
    
    
      const handleOpenModal = () => {
        setModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setModalOpen(false);
      };
      
    

    


   
    
  
  
  
   
  
    const handleDeleteScheduledMessage = async (selectedRow) => {
      if (!selectedRow) return; // Check if a row is selected
      const messageId = selectedRow.scheduled; // Assuming 'id' is the property containing the message ID
      const accessToken = await getAccessTokenSilently();

      try {
      const response = await fetch(`${apiUrl}/hooks/scheduled-messages/${messageId}/cancel`, 
        {  
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );


        if (response.ok) {
          showSnackBar('Scheduled message deleted successfully!', true);
          // Refresh the scheduled messages after deletion
          handleReloadComponents(); // Reload components after successful update
        } else {
          console.error('Failed to delete scheduled message');
          showSnackBar('Failed to delete scheduled message. Please try again', false);
        }
      } catch (error) {
        console.error('Error deleting scheduled message:', error);
        showSnackBar('Failed to delete scheduled message. Please try again', false);
      }
    };

    const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState('auto');

  useLayoutEffect(() => {
    const calculateInitialHeight = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.scrollHeight;
        setContainerHeight(containerHeight - 125);
      }
    };

    calculateInitialHeight(); // Calculate initial height before component mounts

    return () => {
      // Cleanup function (if needed)
    };
  }, []); // Run only once on component mount

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.scrollHeight;
        setContainerHeight(containerHeight - 125);
      }
    };

    const viewportWidth = window.innerWidth;
    if (viewportWidth >= 1280 && viewportWidth <= 1600) {
      const timeoutId = setTimeout(handleResize, 1000); // Delay to ensure proper rendering
      window.addEventListener('resize', handleResize);

      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('resize', handleResize);
      };
    } else {
      // Reset the container height if the viewport width is not within the specified range
      setContainerHeight('auto');
    }
  }, []);



    

    const handleOpenPopover = (event, rowData) => {
        if (event && rowData) {
            pauseAutoRefresh(); 
            setAnchorEl(event.currentTarget);
            setSelectedRow(rowData);
            setPopoverOpen(true);
        }
    };

  


    const handleClosePopover = () => {
      setSelectedRow(null);
        setPopoverOpen(false);
        resumeAutoRefresh(); 



    };

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSelectedRow(null);
        setSnackbarOpen(false);
        
    };




 
    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const options = { month: 'short', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
  };
  
  const formatTime = (timeStr) => {
      const time = new Date(timeStr);
      const options = { hour: 'numeric', minute: 'numeric', hour12: true };
      return time.toLocaleTimeString(undefined, options);
  };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
    };

    

    const [visibleRows, setVisibleRows] = useState([]);

    useEffect(() => {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        setVisibleRows(screenWidth <= 1440 ? customerFeedData.slice(0, 8) : customerFeedData);
      };
  
      handleResize(); // Call initially
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [customerFeedData]);
  
  
  
      const handleCloseDialog = () => { 
        resumeAutoRefresh();  
        setConfirmationOpen(false);
        setSelectedRow(null);
      };
  
  
  
      const theme = createTheme({
        components: {
          MuiTableHead: {
            styleOverrides: {
              root: {
                background: '#FFFFFF',height: '42.5px', letterSpacing:'-1%', lineHeight:'-1%' ,minHeight:'65px'   
    
              },
            },
          },
        },
      });
    
      const StyledHeader = styled(TableCell)({  
    
        color: '#447c92',borderBottom: 'none',letterSpacing:'-2%', lineHeight:'-2%',minHeight:'65px'
      });
    
      const StyledRow = styled(TableCell)({  
        color:'#5e9fbc', fontWeight: 600,letterSpacing:'-2%', lineHeight:'-2%' 
      });
    
      const StyledHeaderText = styled(Typography)({ 
        fontWeight:500, fontSize:13.5, align:'center',margin:'auto', letterSpacing:'-2%', lineHeight:'-2%',minHeight:'20px'
      });
    


    const handleOpenMessageDetails = (row) => {
      setSelectedRow(row);  
      setIsMessageDetailsOpen(true);
      setPopoverOpen(false);

    };


    const handleOpenDialog = (row) => {
      setSelectedRow(row);
      setConfirmationOpen(true);
      setPopoverOpen(false); // Close the popover when opening the dialog
  };
    
    
    const handleCloseMessageDetails = () => {
      setIsMessageDetailsOpen(false);
      resumeAutoRefresh();

    };


    const handleUpdateScheduledMessage = async () => { 
      if (!selectedRow) return; // Check if a row is selected 

        setLoading(true); // Set loading to true when the button is clicked
    
        try {
          // Call the delete function
          await handleDeleteScheduledMessage(selectedRow);
    
          
          setConfirmationOpen(false);
          fetchAllWidgetTypes();
          setLoading(false); 
          // Reset loading state after the operation is complete
        } catch (error) {
          console.error('Error deleting scheduled message:', error);
        } finally {
          setLoading(false); // Reset loading state after the operation is complete
        }
      };
    
    
      return (
        <div>
          <Box
            sx={{
              mt: .5,
              borderRadius: '15px',
              backgroundColor: '#FFFFFF',
            }}
          >

      
<Paper elevation={4}
      sx={{
        justifyContent: 'center',alignSelf:'center',
        width: '99.5%',
        borderRadius: '15px',
        backgroundColor: 'transparent',
        borderBottom: '1.25px solid rgba(173, 216, 230, 0.8)',


      }}
    >
   

{(loadingScheduledNotifications || !scheduledNotificationsData) && (

                <Box>
          <Typography
            align="center"
            sx={{
              background: '#e2f2f7',
                            width: '100%',
              padding: '20px',
              color: '#447c92',
              fontSize: '.8rem',
              fontWeight: 600,  
            }}
          >
                    Loading Your Notifications Scheduled For Delivery...
                  </Typography>
                  <LinearProgress
                    color="secondary"
                    sx={{
                      height: 2,
                      '& .MuiLinearProgress-barColorSecondary': {
                        backgroundColor: '#2BBB9E',
                      },
                    }}
                  />
                </Box>
              )}
      
      {!loadingScheduledNotifications && scheduledNotificationsData && scheduledNotificationsData.length === 0 && (
   <Box
   sx={{
     display: 'flex',
     flexDirection: 'column',
     width: '100%',
     justifyContent: 'center',
     alignContent: 'center',
     background: 'radial-gradient(circle, #EEF6F9, #D4E9F2)',
     alignItems: 'center',
     height: '69vh',
     borderRadius: '10px',
     padding: '20px',
     overflow: 'hidden',
   }}
 >
 <Box sx={{ textAlign: 'center', mt: -1, mb: -1, position: 'relative', width: { xs: '60vw', sm: '40vw', md: '30vw', lg: '25vw' }, height: { xs: '60vw', sm: '40vw', md: '30vw', lg: '25vw' } }}>
  <Skeleton
    variant="rectangular"
    sx={{
      width: '100%',
      height: '100%',
      borderRadius: '10px',
      display: imageLoaded ? 'none' : 'block',
      backgroundColor: 'transparent',
      objectFit: 'contain',


    }}
  />
  <CardMedia
    component="img"
    src={Scheduled}
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: imageLoaded ? 1 : 0,
      transition: 'opacity 0.2s ease-in-out',
      objectFit: 'contain',
      borderRadius: '10px',
    }}
    onLoad={() => setImageLoaded(true)}
  />
</Box>
    <Typography
      align="center"
      sx={{
        fontSize: { xs: 16, sm: 18 }, // Responsive font size
        color: '#447c92',
        mb: '15px',
        textAlign: 'center',
        fontWeight: 600,
      }}
    >
      No Notifications Scheduled For Delivery
    </Typography>

    <Typography
      align="center"
      sx={{
        fontSize: { xs: 14, sm: 16 }, // Responsive font size
        color: '#447c92',
        mb: '20px',
        textAlign: 'center',
        fontWeight: 600,
      }}
    >
      Notifications that are scheduled for delivery will automatically be displayed here.
    </Typography>
  </Box>
)}
      
      {!loadingScheduledNotifications && scheduledNotificationsData && scheduledNotificationsData.length > 0 && (
   <TableContainer
   sx={{  background:'#f1f8fb',  borderRadius:'12px',         border: '1.25px solid rgba(173, 216, 230, 0.5)',display:'flex',

   
   }}
 >
    <Table size="small">
    <ThemeProvider theme={theme}>

    <TableHead >

        <TableRow >
          <StyledHeader align="center" style={{ width: '14%',  }}> <StyledHeaderText> Date Scheduled  </StyledHeaderText></StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}> <StyledHeaderText>Time Scheduled</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}> <StyledHeaderText>Sending To</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}><StyledHeaderText>Ticket Number</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '20%' }}><StyledHeaderText>Notification</StyledHeaderText> </StyledHeader>
          {accountData && accountData.single_location === false && (
            <StyledHeader align="center" style={{ width: '14%' }}><StyledHeaderText> Location</StyledHeaderText> </StyledHeader>
          )}
          <StyledHeader align="center" style={{ width: '10%' }}><StyledHeaderText> Status</StyledHeaderText> </StyledHeader>
        </TableRow>
      </TableHead>
      </ThemeProvider>

      <TableBody
      >
        {scheduledNotificationsData.map((activity, index) => (
            <TableRow
              key={index}
              onClick={(event) => handleOpenPopover(event, activity)}
              sx={{ height: '53px', '&:last-child td, &:last-child th': { border: 0 },
              backgroundColor: selectedRow === activity ? 'rgba(173, 216, 230, 0.3)' : 'transparent',

              
              '&:hover': {
                backgroundColor: 'rgba(173, 216, 230, 0.1)', // Background color on hover
                boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)', // Box shadow on hover
                cursor: 'pointer', // Change cursor to pointer on hover
              },
            }}
            >

            <StyledRow align="center" sx={{}}  >{formatDate(activity.scheduled_time)}</StyledRow>
            <StyledRow align="center">{formatTime(activity.scheduled_time)}</StyledRow>
            <StyledRow align="center">{activity.customer_name ? activity.customer_name : formatPhoneNumber(activity.phone_number)}</StyledRow>
            <StyledRow align="center">{activity.ticket_number}</StyledRow>
            <StyledRow align="center">
              <Box height="32px" display="flex" alignItems="center" justifyContent="center" width="100%">
                <StatusTag status={activity.status} />
              </Box>
            </StyledRow>
            {accountData && accountData.single_location === false && (
              <StyledRow align="center">{activity.location}</StyledRow>
            )}
            <StyledRow align="center">
              <StatusIcon  sx={{backgroundColor:'transparent'}} status={activity.message_status} />
            </StyledRow>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <MessageDetails 
    open={isMessageDetailsOpen} 
    selectedRow={selectedRow} 
    onClose={handleCloseMessageDetails}
    handleOpenModal={handleOpenModal}
    setSelectedPhoneNumber={setSelectedPhoneNumber} 
    />

<CreateCustomerModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onSaveCustomer={handleSaveCustomer}
          selectedPhoneNumber={selectedPhoneNumber}
          location_name={subUserData?.location_name || ''} // Check if subUserData exists before accessing location_name
          />
  </TableContainer>
      )}
            </Paper>
          </Box>                        <Popover
                    open={popoverOpen}
                    anchorEl={anchorEl} // Use the anchor element state variable
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom', // Render Popover below the anchor element
                        horizontal: 'center', // Center horizontally
                      }}
                      transformOrigin={{
                        vertical: 'top', // Position Popover to the top of the anchor element
                        horizontal: 'center', // Center horizontally
                      }}
                    
                    PaperProps={{
                        sx: {
                          border: '1.25px solid rgba(173, 216, 230, 0.6)',
                
                          borderRadius: '8px', // Adjust the value to control the roundness
                        
                                                },
                                            }}>
                                                <Box
                                                sx={{backgroundColor: '#F2F9FF',}}
                                                >


                
 
                            <Paper
                                                                            sx={{backgroundColor: '#F2F9FF'}}

                            >


<Box
                onClick={() => { handleOpenMessageDetails(selectedRow);  }}  // Open message details on row click
              sx={{
    pt:'5px',  
    px:1,
    flexDirection: 'row', 
    display: 'flex', 
    backgroundColor: '#F2F9FF',
    color: '#8A5D6E', 
    '&:hover': {
      backgroundColor: '#E6F1FF', // Adjusted hover color to a lighter shade
    },
    '&.Mui-selected': {
      backgroundColor: '#E6F1FF', // Adjusted selected color to a darker shade
    },
    alignItems: 'center', // Center vertically
    cursor: 'pointer', // Change cursor to pointer on hover
      }}
>
<img
    src={DetailsIcon}
    alt="bell"
    style={{
      width: 'auto',
      height: '30px',
      borderRadius: '10px',
      padding: '3px',
      borderRadius: '6px',
    }}
  />
  <Typography 
    sx={{
      display: 'flex', 
      color:'#5e9fbc', 
      fontWeight: 600,
      fontSize: 14, 
      textAlign: 'center', // Fix typo here: 'textAlign' instead of 'alignText'
    }}
  >
    View Notification Details
  </Typography>
</Box>
  <Box
onClick={() => handleOpenDialog(selectedRow)}
sx={{
    px:1,
    pb:'5px',

    flexDirection: 'row', 
    display: 'flex', 
    backgroundColor: '#F2F9FF',
    color: '#8A5D6E', 
    '&:hover': {
      backgroundColor: '#E6F1FF', // Adjusted hover color to a lighter shade
    },
    '&.Mui-selected': {
      backgroundColor: '#E6F1FF', // Adjusted selected color to a darker shade
    },
    alignItems: 'center', // Center vertically
    cursor: 'pointer', // Change cursor to pointer on hover
  }}
>
  <img
    src={DeleteIcon}
    alt="bell"
    style={{
      width: 'auto',
      height: '30px',
      borderRadius: '10px',
      padding: '3px',
      borderRadius: '6px',
    }}
  />
  <Typography 
    sx={{
      display: 'flex', 
      color: '#D59EA8', 
      fontSize: 14, 
      fontWeight: 600, 
      textAlign: 'center', // Fix typo here: 'textAlign' instead of 'alignText'
    }}
  >
    Cancel Notification
  </Typography>

</Box>





                            </Paper>
                            </Box>
    
                </Popover>
              
                <ScheduledDialog
                    open={confirmationOpen} 
                    onClose={() => setConfirmationOpen(false)}
                    header='Cancel Scheduled Notification'
                    message='Are you sure you want to cancel this scheduled notification?'
                    subText='This notification will be erased from your message history and cannot be undone.'
                    buttonText={loading ? 'Deleting...' : 'Delete'}  
                    onConfirm={handleUpdateScheduledMessage}
                    isLoading={loading}
                    buttonLoadingText='Deleting...'

                      />



              
                </div>
);

}

