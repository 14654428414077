import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAccountContext } from './AccountContext';
import {useAppContext} from './AppContext';
import {useAuth} from './AuthContext'; 
import dayjs from 'dayjs';
import { set } from 'lodash';


const UserSelectedNotificationsContext = createContext();

export const UserSelectedNotificationsProvider = ({ children }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loadingHours, setLoadingHours] = useState(true);
  const { accountData } = useAccountContext();
  const {reloadComponents, handleReloadComponents} = useAppContext();

  const {username, isLoggedIn} = useAuth();  
  const [selectedNotifications, setSelectedNotifications] = useState({});

  const apiUrl = process.env.REACT_APP_API_URL;
  const [businessHours, setBusinessHours] = useState({
    Sunday: { start_time: null, end_time: null, is_closed: false },
    Monday: { start_time: null, end_time: null, is_closed: false },
    Tuesday: { start_time: null, end_time: null, is_closed: false },
    Wednesday: { start_time: null, end_time: null, is_closed: false },
    Thursday: { start_time: null, end_time: null, is_closed: false },
    Friday: { start_time: null, end_time: null, is_closed: false },
    Saturday: { start_time: null, end_time: null, is_closed: false },
  });
  const [startTimeValues, setStartTimeValues] = useState({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });
  const [endTimeValues, setEndTimeValues] = useState({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });




  useEffect(() => {
    if (accountData && accountData.single_user === true) {
    }
  }, [accountData]);



  const formatTime12Hour = (time) => {
    if (time) {
      const [hours, minutes] = time.split(':');
      const parsedTime = dayjs().set('hour', parseInt(hours)).set('minute', parseInt(minutes));
  
      return parsedTime.format('h:mm A'); // Format in 12-hour format with AM/PM
    }
    // If time is not available, use a default value
    return dayjs().set('hour', 0).set('minute', 0).format('h:mm A');
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserSelectedNotifications();
    }
  }, [isAuthenticated, user, reloadComponents, isLoggedIn]);



  const fetchUserSelectedNotifications = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${apiUrl}/api/user-notifications/get_user_notifications/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        setSelectedNotifications(data); // Set selected notifications as an object
      } else {
        console.error('Error fetching user selected notifications:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching user selected notifications:', error.message);
    }
  }





  useEffect(() => {
    if (accountData && accountData.customer_id && accountData.completed_setup === true) { 

    if (isAuthenticated && accountData && username && accountData.single_user===false   ) {
      fetchCurrentHours();
    } else  {

      fetchSingleUserHours();
    }
  }
  }, [isAuthenticated, user, username, accountData, reloadComponents]);


    const fetchCurrentHours = async () => {
      if (isAuthenticated && isLoggedIn ) {
        try {

          const apiUrl = process.env.REACT_APP_API_URL;
          const accessToken = await getAccessTokenSilently();
  

  
          const response = await fetch(`${apiUrl}/api/getUserSavedTimes/${user.sub}/${username}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const data = await response.json();
  
          const locationResponse = await fetch(`${apiUrl}/api/locations/${user.sub}/${username}/active`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const locationData = await locationResponse.json();
  
          if (response.ok && locationResponse.ok) {
            const updatedEditedHours = { ...businessHours };
  
            Object.keys(businessHours).forEach((dayOfWeek) => {
              const receivedData = data[dayOfWeek];
              if (receivedData) {
                const start_time = receivedData.start_time ? formatTime12Hour(receivedData.start_time) : null;
                const end_time = receivedData.end_time ? formatTime12Hour(receivedData.end_time) : null;
  
                updatedEditedHours[dayOfWeek] = {
                  start_time: start_time,
                  end_time: end_time,
                  is_closed: receivedData.is_closed || false,
                };
  
                const startTime = dayjs(`2022-04-17T${receivedData.start_time}`);
                const endTime = dayjs(`2022-04-17T${receivedData.end_time}`);
  
                setStartTimeValues((prev) => ({
                  ...prev,
                  [dayOfWeek]: startTime,
                }));
                setEndTimeValues((prev) => ({
                  ...prev,
                  [dayOfWeek]: endTime,
                }));
              }
            });

            setBusinessHours(updatedEditedHours);
          }
        } catch (error) {
          console.error('Error fetching user saved times:', error);
        }
      }
    };



    const fetchSingleUserHours = async () => {
      if (isAuthenticated && accountData ) {
        try {
          const encodedUserId = encodeURIComponent(user.sub);
          const accessToken = await getAccessTokenSilently();

          const response = await fetch(`${apiUrl}/api/getUserSavedTimes/${encodedUserId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          const data = await response.json();
  
          if (response.ok) {
            const updatedEditedHours = { ...businessHours };
  
            Object.keys(businessHours).forEach((dayOfWeek) => {
              const receivedData = data[dayOfWeek];
              if (receivedData) {
                // Check if receivedData.start_time and receivedData.end_time are provided
                // If provided, format them in 12-hour format
                const start_time = receivedData.start_time ? formatTime12Hour(receivedData.start_time) : null;
                const end_time = receivedData.end_time ? formatTime12Hour(receivedData.end_time) : null;
  
                updatedEditedHours[dayOfWeek] = {
                  start_time: start_time,
                  end_time: end_time,
                  is_closed: receivedData.is_closed || false,
                };
  
                // Calculate the initial time values based on your data
                const startTime = dayjs(`2022-04-17T${receivedData.start_time}`);
                const endTime = dayjs(`2022-04-17T${receivedData.end_time}`);
  
                setStartTimeValues((prev) => ({
                  ...prev,
                  [dayOfWeek]: startTime,
                }));
                setEndTimeValues((prev) => ({
                  ...prev,
                  [dayOfWeek]: endTime,  // Update endTimeValues here
                }));
              }
            });
            // Set the editedHours state with the updated values
            setBusinessHours(updatedEditedHours);
          }
        } catch (error) {
          console.error('Error fetching user saved times:', error);
        }
      }
    };
  


  


  const contextValue = {
    businessHours,
    setBusinessHours,
    selectedNotifications,
    setSelectedNotifications,
    fetchCurrentHours,
    fetchSingleUserHours, 
    startTimeValues,
    endTimeValues,
  };

  return (
    <UserSelectedNotificationsContext.Provider value={contextValue}>
      {children}
    </UserSelectedNotificationsContext.Provider>
  );
};

export const useUserSelectedNotifications = () => {
  const context = useContext(UserSelectedNotificationsContext);
  if (context === undefined) {
    throw new Error('useUserSelectedNotifications must be used within a UserSelectedNotificationsProvider');
  }
  return context;
};



