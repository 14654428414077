import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Avatar, IconButton, DialogTitle, Dialog, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import newCustomerIcon from "../../public/assets/icons/icons/newCustomer.png";
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from 'src/context/AppContext';
import { set } from 'lodash';
import { PrimaryButton, CancelButton, SecondaryButton } from 'src/components/Buttons/ModalButtons';
import {useWidgetContext} from 'src/context/WidgetContext'; 

const CreateCustomerModal = ({ open, onClose, selectedPhoneNumber, location_name, setCustomerSaved }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const {fetchAllWidgetTypes} = useWidgetContext(); 


  useEffect(() => {
    if (!open) {
      setFirstName('');
      setLastName('');
    }
  }, [open]);

  const handleFirstNameChange = (e) => {
    const input = e.target.value;
    setFirstName(input.charAt(0).toUpperCase() + input.slice(1));
  };
  
  const handleLastNameChange = (e) => {
    const input = e.target.value;
    setLastName(input.charAt(0).toUpperCase() + input.slice(1));
  };

  const getInitials = () => {
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const handleConfirmationClose = () => {
    setIsConfirmationOpen(false);
    onClose();
  };

  const handleSaveCustomerData = async () => {
    setCustomerSaved(false);
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
      const data = {
        phoneNumber: selectedPhoneNumber,
        firstName,
        lastName,
        userSub: user.sub, // Assuming you have the userSub
        location_name
      };
  
      const response = await fetch(`${apiUrl}/api/customers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data),
      });

      handleReloadComponents(); // Reload components from context
      fetchAllWidgetTypes(); // Fetch all widget types
  
      if (response.ok) {
        showSnackBar('Customer saved successfully!', true);

        setIsLoading(false);
        setCustomerSaved(true);
        onClose();


      } else {
        console.error('Failed to save customer:', response.statusText);
        showSnackBar('Failed to save customer. Please try again', false);
        setIsLoading(false);
        setCustomerSaved(false);
        onClose();

      }
    } catch (error) {
      console.error('Failed to save customer:', error);
      showSnackBar('Failed to save customer. Please try again', false); 
      setIsLoading(false);
      setCustomerSaved(false);
      onClose();

    }
  };
  


  return (
    <Dialog open={open} onClose={onClose}
    fullWidth={true} // Make the dialog take up the full width
    PaperProps={{ sx: { borderRadius: "18px", border: '1.25px solid rgba(173, 216, 230, 0.7)', } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          pt: 3,
          pb: 2.45,
          color: "#374247",
        }}
      >
        <img
          src={newCustomerIcon}
          alt="newCustomerIcon"
          style={{
            border: '1.25px solid rgba(173, 216, 230, 0.7)',
            marginLeft:'5px',
            width: "auto",
            height: "31px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "2px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />

      </DialogTitle>
	
      <DialogContent
        sx={{
          width: '100%',
          backgroundColor: "#FFFFFF",
          padding: "29px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >

          <Typography  sx={{  color: "#48768C" , fontSize:'21px', mb:2, fontWeight:600}}>
          Create Customer{" "}
        </Typography>
       

      



<Box sx={{ mb:2,                   border: '1.25px solid rgba(173, 216, 230, 0.7)',
 borderRadius: "15px",backgroundColor: 'rgba(191, 218, 231, 0.1)', boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px",
 }}>
   <Avatar
    sx={{
      ml: 2,
      backgroundColor: 'rgba(8, 19, 42, 0.7)',
      width: 50,
      height: 50,
      mr: 2,
      mt:2,
      mb:1.5,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}
  >
{firstName || lastName ? (
      // Render initials when at least one field has content
      <Typography sx={{ fontSize: '24px' }}>  {getInitials()}</Typography>
    ) : (
      // Render Person Add Icon when both fields are empty
      <PersonAddOutlinedIcon sx={{ width: '24px', height: '24px' }} />
    )}
</Avatar>

<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left',              px:2,
 }}>     
 
 
  <TextField
 placeholder="First Name"
 variant="standard"  
 helperText="First Name"
        sx={{
          width: '100%',
          ml:1,
          color: '#48768C',
          pb: 2,
          mr: 2,
          "& .MuiInput-underline": {
                
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: '#2F5C71', // onHover border color
            },
            "&.Mui-focused:before": {
              borderBottomColor: '#2F5C71', // onFocus border color
            },
            "&.Mui-focused:after": {
              borderBottomColor: '#48768CBA', // Focused border color (with transparency)
              borderWidth: '2px', // Optional: Adjust border width for focus state
            },
          },
          '& input': {
            textAlign: 'left',
            color: '#48768C',
          },

        }}
        value={firstName}
        onChange={handleFirstNameChange}
      />
      <TextField
            placeholder="Last Name"
            variant="standard"  
            helperText="Last Name"
        sx={{
          width: '100%',

          color: '#48768C',
          pb: 2,
          "& .MuiInput-underline": {
                
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: '#2F5C71', // onHover border color
            },
            "&.Mui-focused:before": {
              borderBottomColor: '#2F5C71', // onFocus border color
            },
            "&.Mui-focused:after": {
              borderBottomColor: '#48768CBA', // Focused border color (with transparency)
              borderWidth: '2px', // Optional: Adjust border width for focus state
            },
          },
          '& input': {
            textAlign: 'left',
            color: '#48768C',
          },

        }}
        value={lastName}
        onChange={handleLastNameChange}
      />
</Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, px:2 }}>
  <TextField
    value={selectedPhoneNumber}
    disabled
    placeholder="Phone Number"
    variant="standard"  
    helperText="Phone Number"    

    sx={{
      ml:1,

width:'100%',
      color: '#48768C',
      textAlign: 'left',
      pb: 2,
      '& input': {
        width: '100%', // Make the input take up the entire width
        textAlign: 'left', // Center the text within the input
        color: '#48768C',

      },
    }}
  />
</Box>
<Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <SecondaryButton
  sx={{

    mb: 3,  
    fontSize: '1rem', // Increase font size
    width: '95%',
   
    }}
    
    onClick={handleSaveCustomerData } disabled={!firstName || !lastName || isLoading}>   
    
  {isLoading ? 'Saving...' : 'Save'}
</SecondaryButton>


        </Box>
</Box>


      </DialogContent>
     
    </Dialog>
  );
};

export default CreateCustomerModal;
