import React, { createContext, useContext, useState, useEffect } from 'react';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Retrieve the initial state from sessionStorage or use default values
  const initialLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true' || false;
  const initialUserVerified = sessionStorage.getItem('userVerified') === 'true';
  const initialAccessToken = sessionStorage.getItem('accessToken') || null;
  const initialUsername = sessionStorage.getItem('username') || '';
  const initialSubUserData = (() => {
    const data = sessionStorage.getItem('subUserData');
    try {
      return data ? JSON.parse(data) : null; // Safely parse JSON or return null
    } catch (error) {
      console.error('Invalid JSON in sessionStorage for subUserData:', error);
      return null; // Fallback to null on error
    }
  })();

  const [isLoggedIn, setLoggedIn] = useState(initialLoggedIn);
  const [userVerified, setUserVerified] = useState(initialUserVerified);
  const [accessToken, setAccessToken] = useState(initialAccessToken);
  const [username, setUsername] = useState(initialUsername);
  const [subUserData, setSubUserData] = useState(initialSubUserData);

  const fetchLatestUserData = (updatedUserData) => {
    setSubUserData(updatedUserData);
    setLoggedIn(true); // Trigger a re-render by updating a state variable
  };

  useEffect(() => {
    // Save the state to sessionStorage whenever it changes
    sessionStorage.setItem('isLoggedIn', isLoggedIn);
    sessionStorage.setItem('userVerified', userVerified);
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('username', username);
    sessionStorage.setItem('subUserData', JSON.stringify(subUserData)); // Save subUserData as a string
  }, [isLoggedIn, userVerified, accessToken, username, subUserData]);




  const login = (newUsername, newSubUserData) => {
    setLoggedIn(true);
    setUserVerified(true);
    setUsername(newUsername);
    setSubUserData(newSubUserData); // Set the new subUserData on login
    
  };  

  const resetContext = () => {
    setLoggedIn(false);
    setUserVerified(false);
    setAccessToken(null);
    setUsername('');
    setSubUserData(null); // Reset subUserData
    localStorage.clear();
    sessionStorage.clear(); // Clear sessionStorage instead of localStorage
  };

  const logout = () => {
    setLoggedIn(false);
    setUserVerified(false);
    setAccessToken(null);
    setSubUserData(null); // Reset subUserData
  };



  const contextValue = {
    isLoggedIn,
    userVerified,
    accessToken,
    login,
    username,
    resetContext,
    logout,
    setAccessToken,
    subUserData,
    setSubUserData, // Include the setSubUserData function
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;

};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return {
    ...context,
    setSubUserData: context.setSubUserData,  // Include the setSubUserData function
  };
};
