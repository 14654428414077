import React, { useState } from 'react';
import SingleUserSingleLocationModal from './SingleUserSingleLocationModal';
import SingleLocationMultiUserModal from './SingleLocationMultiUserModal';
import CreateSingleUserModal from './CreateSingleUserModal';
import NewSingleUserFromAuth0 from './NewSingleUserFromAuth0';
import NewAccountSuccess from './NewAccountSuccess';
import NewAccountSuccessSingleUser from './NewAccountSuccessSingleUser';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Modal, Box, Typography, Paper } from '@mui/material';
import EmployeeChoiceModal from './EmployeeChoiceModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useAccountContext } from 'src/context/AccountContext';
import NewUserNotification from '../../../../src/hooks/NewUserNotification'





// ... (your imports)

const NewUserSingleLocation = ({ onClose }) => {


  const [step, setStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [hasMultipleEmployees, setHasMultipleEmployees] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [newPin, setNewPin] = useState('');
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [pinError, setPinError] = useState('');


  const { user, getAccessTokenSilently } = useAuth0();
  const { resetContext } = useAccountContext();
  const [loading, setLoading] = useState(false);  

  const handleNext = () => {
    setIsModalOpen(false);
    setStep(2); // Move to the next step after adding the location
  };

  const handleEmployeeChoice = (choice) => {
    setHasMultipleEmployees(choice);
    setStep(choice === 'no' ? 3 : 4);
  };


  const handleNewSingleUserFromAuth0 = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently(); // Get access token silently
  
      // Validate that PINs match
      if (newPin === confirmNewPin) {
        // Create a new user with the provided username and PIN
        const response = await fetch(`${apiUrl}/api/users/single-location`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Add authorization header with access token
          },
          body: JSON.stringify({
            firstName: newFirstName,
            lastName: newLastName,
            userName: newUsername,
            pin: newPin,
            auth0UserId: user.sub,
            is_admin: true, // Include the is_admin property set to true
            completed_setup: true, // Include the completed_setup property set to true  
            single_user: false,
            single_location: true,
            title: 'Account Administrator',
          }),
        });
  
        const responseData = await response.json();
  
        if (response.ok) {
          // You might want to refresh the user data or perform other actions on success
          await NewUserNotification(user.sub, getAccessTokenSilently); // Pass the user.sub as userId and use await
          setStep(6);
          setLoading(false);

  
        } else {
          console.error('Failed to create a new user:', responseData.error);
          setLoading(false);

        }
      } else {
        console.error('Entered PINs do not match');
        setLoading(false);

      }
    } catch (error) {
      console.error('Error creating a new user:', error);
      setLoading(false);

    }
  };


  


  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <SingleUserSingleLocationModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onAddLocation={handleNext}
          />
        );
        case 2:
          return (
            <EmployeeChoiceModal
            isOpen={true}
            onClose={() => setIsModalOpen(false)}
            handleEmployeeChoice={handleEmployeeChoice}
          />
        );
        case 3:
        return (
          <CreateSingleUserModal
            isOpen={hasMultipleEmployees === 'no'}  // Use hasMultipleEmployees to control modal visibility
            onClose={onClose} 
            onNext={() => setStep(8)} 
          />
        );
        case 4:
          return (
            <SingleLocationMultiUserModal
              isOpen={hasMultipleEmployees === 'yes'}
              onNext={() => setStep(5)}
            />
          );
        case 5:
          return (
            <NewSingleUserFromAuth0
              isOpen={hasMultipleEmployees === 'yes'} // Use hasMultipleEmployees to control modal visibility
              onClose={() => setStep(7)}
              onSave={handleNext}
              setIsFormValid={setIsFormValid}
              setPinError={setPinError}
              newPin={newPin}
              setNewPin={setNewPin}
              confirmNewPin={confirmNewPin}
              setConfirmNewPin={setConfirmNewPin}
              newFirstName={newFirstName}
              setNewFirstName={setNewFirstName}
              newLastName={newLastName}
              setNewLastName={setNewLastName}
              newUsername={newUsername}
              setNewUsername={setNewUsername}
              isFormValid={isFormValid}
              pinError={pinError}
              loading={loading}
              handleNewSingleUserFromAuth0={handleNewSingleUserFromAuth0}
              
            />
          );
      case 6:
        return <NewAccountSuccessSingleUser onClose={onClose} onNext={() => setStep(8)} />;
      case 7:
        return <NewAccountSuccess onClose={onClose} onNext={() => setStep(8)} />; 
      default:
        return null;
    }
  };

  return <>{renderStep()}</>;
};

export default NewUserSingleLocation;





