import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableContainer,
  Typography,
  Button,
  Card,
  Paper,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,

} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Icon as Iconify } from '@iconify/react';
import TablePagination from '@mui/material/TablePagination';
import FilterListIcon from '@mui/icons-material/FilterList';
import { TextField, InputAdornment } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { useAuth0 } from '@auth0/auth0-react';
import CreateCustomerModal from 'src/components/modal/CreateCustomerModal';
import { useAccountContext } from '../context/AccountContext'; // Import AppProvider from AccountContext

import { useAuth } from "../context/AuthContext";
import { Message } from '@mui/icons-material';
import {useAppContext} from '../context/AppContext';
import  fetchGlobalNotifications  from '../utils/notificationUtils'; 
import {MessagesFeedTable} from '../components/tables/messagesPage/MessagesFeedTable'; 
import {MultiLocationFeed} from '../components/tables/messagesPage/MultiLocationFeed'; 
import {SingleUserFeed} from '../components/tables/messagesPage/SingleUserFeed'; 
import {MultiUserUserSingleLocationFeed} from '../components/tables/messagesPage/MultiUserUserSingleLocationFeed'; 
import fetchWithAuth from 'src/utils/useJwtAuthenticatedFetch';
import { useMessageContext } from '../context/MessagesContext';













export function MessagesFeed() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { username, subUserData } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState(''); // Define searchPhoneNumber state
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const isSingleUser = accountData?.single_user; // Check if single_user is true in accountData
  const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
  const [userData, setUserData] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMessageDetailsOpen, setIsMessagesDetailsOpen] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const {reloadComponents, handleReloadComponents} = useAppContext();
  const { messagesFeedData, setMessagesFeedData, loading, setLoading, required_field, required_field_value } = useMessageContext();




  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage); // Update the rowsPerPage state
    setPage(0); // Reset the page to 0 when changing the number of rows
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return ''; // Return empty string if phoneNumber is null or undefined
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber; // Return original phoneNumber if no match
  };



  
  const handleSearchChange = (e) => {
    const enteredPhoneNumber = e.target.value;
  
    // Remove non-numeric characters
    const cleanedPhoneNumber = enteredPhoneNumber.replace(/\D/g, '');
  
    // Format the phone number dynamically as the user types
    let formattedPhoneNumber = '';
  
    if (cleanedPhoneNumber.length >= 1) {
      formattedPhoneNumber = `(${cleanedPhoneNumber.slice(0, 3)}`;
    }
    if (cleanedPhoneNumber.length >= 4) {
      formattedPhoneNumber += `) ${cleanedPhoneNumber.slice(3, 6)}`;
    }
    if (cleanedPhoneNumber.length >= 7) {
      formattedPhoneNumber += `-${cleanedPhoneNumber.slice(6, 10)}`;
    }
  
    setSearchPhoneNumber(formattedPhoneNumber);
  };
  
 
  const handleCloseMessageDetails = () => {
    setSelectedRow(null);
    setIsMessagesDetailsOpen(false);
  };










  


  const handleOpenMessageDetails = (row) => {
    setSelectedRow(row);
    setIsMessagesDetailsOpen(true);
  };




  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update the page state
  };

  useEffect(() => {
    // Access userData and subUserData wherever you need in your component

  }, [subUserData]);

  useEffect(() => {
    // Access userData and subUserData wherever you need in your component  
  }, [accountData]);




  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scrollbar
    return () => {
      document.body.style.overflow = ''; // Re-enable scrollbar on component unmount
    };
  }, []);




  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ width: '100%',  px:'4px', borderRadius: '26px',mt:-1
}}>


  <Box sx={{ position: 'absolute',  right: '37px', padding: '5px', pl:'10px',mt:2 , borderRadius: '26px'}}>
<TextField
variant="outlined"
label="Search by phone number"
value={searchPhoneNumber}
onChange={handleSearchChange}
    sx={{
      width: '100%',
      color:'#2A3D4F',
      '& .MuiOutlinedInput-root': {
        height: '40px',
        fontSize: '14px',

        '& fieldset': {
          borderColor: '#90A4AE', // Updated outline color
          fontSize: '14px',

        },
        '&:hover fieldset': {
          borderColor: '#90A4AE', // Updated outline color on hover
          fontSize: '14px',

        },
      },
    }}
    InputLabelProps={{
      style: { color: '#2A3D4F',          fontSize: '14px',
    }, // Updated label color
    }}
    InputProps={{
      style: { color: '#2A3D4F' }, // Updated text color to white
      startAdornment: (
        <InputAdornment position="start">
          <Iconify icon="bi:search" style={{ color: '#90A4AE' }} /> {/* Updated icon color */}
        </InputAdornment>
      ),
    }}
  />
</Box>


<Box
      sx={{
        width: '100%',
        mt: .5,
        borderRadius: '10px',
        overflow: 'auto',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)',
        

        
      }}>
<Typography
  sx={{
    pt: 1.5,
    color: '#48768C',
    fontWeight: 700,
    fontSize: 17,
    backgroundColor: 'rgba(255, 255, 255, 1.9)',
    borderBottom: 'none',
    pl: '18px',
    mb:'2px',letterSpacing:'-2%', lineHeight:'-2%'
  }}
>
  Notification History
</Typography>
<Typography
  sx={{
    color: '#48768C',
    mb:'5px', 
    fontSize: '0.9rem',
    backgroundColor: 'rgba(255, 255, 255, 1.9)',
    pl: '18px',
    mb:1,letterSpacing:'-2%', lineHeight:'-2%'
    
  }}
>
  View all of your companies sent notifications.
</Typography>

<>
  {/* Multi User Multi Location*/}
{!accountData?.single_user && !accountData?.single_location &&
      <MessagesFeedTable 
      messagesFeedData={messagesFeedData}
      loading={loading}
      selectedStatus={selectedStatus}
      searchPhoneNumber={searchPhoneNumber}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleOpenMessageDetails={handleOpenMessageDetails}
      setHoveredRowIndex={setHoveredRowIndex}
      setSelectedPhoneNumber={setSelectedPhoneNumber}
      isMessageDetailsOpen={isMessageDetailsOpen}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      setIsMessagesDetailsOpen={setIsMessagesDetailsOpen}
      handleCloseMessageDetails={handleCloseMessageDetails} />}

  {/* Single User Single Location*/}
      {accountData?.single_user && accountData?.single_location && (
  <SingleUserFeed
    messagesFeedData={messagesFeedData}
    loading={loading}
    selectedStatus={selectedStatus}
    searchPhoneNumber={searchPhoneNumber}
    page={page}
    rowsPerPage={rowsPerPage}
    handleChangePage={handleChangePage}
    handleChangeRowsPerPage={handleChangeRowsPerPage}
    handleOpenMessageDetails={handleOpenMessageDetails}
    setHoveredRowIndex={setHoveredRowIndex}
    setSelectedPhoneNumber={setSelectedPhoneNumber}
    isMessageDetailsOpen={isMessageDetailsOpen}
    selectedRow={selectedRow}
    setSelectedRow={setSelectedRow}
    setIsMessagesDetailsOpen={setIsMessagesDetailsOpen}
    handleCloseMessageDetails={handleCloseMessageDetails}
  />
)}



  {/* Multi User Single Location*/}
  {accountData &&  !accountData?.single_user && accountData?.single_location &&
 <MultiUserUserSingleLocationFeed  
          messagesFeedData={messagesFeedData}
          loading={loading}
          selectedStatus={selectedStatus}
          searchPhoneNumber={searchPhoneNumber}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOpenMessageDetails={handleOpenMessageDetails}
          setHoveredRowIndex={setHoveredRowIndex}
          setSelectedPhoneNumber={setSelectedPhoneNumber}
          isMessageDetailsOpen={isMessageDetailsOpen}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setIsMessagesDetailsOpen={setIsMessagesDetailsOpen}
          handleCloseMessageDetails={handleCloseMessageDetails} />}  
    </>

</Box>




      </Box>



    </LocalizationProvider>
  );
  
}


  export const ProtectedMessagesFeed = withAuthenticationRequired(MessagesFeed, {
    onRedirecting: () => <div>Redirecting you to the login page...</div>
  });

  export default MessagesFeed;