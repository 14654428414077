


export const handleMultiUserSave = async (        editData,
  getAccessTokenSilently,
  setSubUserData,
  activeLocation,
  userId,
  username) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      `${apiUrl}/api/locations/${editData.location_id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(editData),
      }
    );

    if (response.ok) {
      const updatedUserDataResponse = await fetch(
        `${apiUrl}/api/users/profile/${userId}/${username}`, // <- Add comma here
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Include access token in headers
          },
        }
      );
      const updatedUserData = await updatedUserDataResponse.json();

      setSubUserData(updatedUserData);

    } else {
      console.error('Failed to update location');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};