//Create Single User Modal

import React, { useState, useEffect } from 'react';
import { Modal, Typography, TextField, Button, Box,Fade, Skeleton, CardMedia } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import businessOwner from 'src/public/assets/businessOwner.png';
import NewUserNotification from '../../../../src/hooks/NewUserNotification'
import complete from 'src/public/assets/complete.png';
import { useSingleUser } from 'src/context/SingleUserContext';
import { useAccountContext } from 'src/context/AccountContext';
import { useAuth } from 'src/context/AuthContext';
import RestartSetupDialog from "./RestartSetupDialog";  






// Modal for creating an initial user from new Auth0 user signup
const CreateSingleUserModal = ({ isOpen, onClose, onSave, auth0User, locationData, onNext }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [newPin, setNewPin] = useState('');
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { accountData, isLoading } = useAccountContext();
  const { fetchLatestSingleUserData } = useSingleUser();
  const { username } = useAuth();
  const [loading, setLoading] = useState(false);  

  const [showRestartDialog, setShowRestartDialog] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);    


  const handleBackdropClick = (event) => {
    // Check if the click is on the backdrop by comparing target and currentTarget
    if (event.target === event.currentTarget) {
      setConfirmRestart(false)
      setShowRestartDialog(true); // Open RestartSetupDialog
    }
  };

  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };


  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = complete;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);



  const theme = createTheme({
    palette: {
      primary: {
        main: '#48768C', // Set the main color to white
      },
    },
  });

  const handleCreateSingleUser = async () => {
    if (user && user.firstName && user.lastName) {
      setLoading(true);
    try {
      const username = `${user.firstName.charAt(0).toLowerCase()}${user.lastName.toLowerCase()}`;
      const accessToken = await getAccessTokenSilently(); // Get access token silently
  
      // Create a new user with the provided username and PIN
      const userResponse = await fetch(`${apiUrl}/api/users/single-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, // Add authorization header with access token
        },
        body: JSON.stringify({
          firstName: user.firstName,
          lastName: user.lastName,
          userName: username,
          auth0UserId: user.sub,
          is_admin: true, // Include the is_admin property set to true
          single_user: true,
          title: 'Owner',

        }),
      });
  
      const userData = await userResponse.json();
  
      if (userResponse.ok) {
        // Trigger the onSave function passed as a prop
  
        // Clear local storage before executing other logic
        localStorage.removeItem('subUserData');
        localStorage.clear(); // Clear all items from local storage
        
        if (accountData && !isLoading) {
          if (accountData.single_user && !username) {
            await fetchLatestSingleUserData();

          } else {
            // Handle other cases
          }
        }
  
        // Create notifications
        await NewUserNotification(user.sub, getAccessTokenSilently); // Pass the user.sub as userId and use await
  
        onNext(); // Move to the next step
        window.location.reload();
      } else {
        console.error('Failed to create a new user:', userData.error);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error creating a new user:', error);
      setLoading(false);
    
      }
  }};

  
  return (
    <Modal open={isOpen} onClose={() => onClose(false)}
    slotProps={{
      backdrop: {
        onClick: handleBackdropClick, // Disable closing the modal by clicking on the backdrop
      },
    }}
    disableEscapeKeyDown // Disable closing the modal by pressing the escape key
  >
        <Fade in={isOpen} timeout={300}>

        <Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'30%',
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >

<Typography
          sx={{
            mt:3,
            fontSize: 21,
            textAlign: 'center',
            mb: 1,
            fontWeight: 600,
          }}
        >
          You're all set!
        </Typography>


        {!imageLoaded && (
    <Skeleton
      variant="rectangular"
      sx={{
        height: 'auto',
        maxWidth: '55%', maxHeight:'55%',
        objectFit: 'contain',
        borderRadius: '10px', 
        backgroundColor: '#EEF6F9', // Adjust as needed to match background
        transition: 'opacity 0.3s ease-in-out',
        opacity: imageLoaded ? 0 : 1,
      }}
    />
  )}
    <CardMedia
    component="img"
    image={complete}
    sx={{
      display: "block",
      margin: "auto",
      marginBottom: "20px",
maxWidth: '55%', maxHeight:'55%',
      objectFit: 'contain',
      transition: 'opacity 0.3s ease-in-out',
      opacity: imageLoaded ? 1 : 0,
    }}
    onLoad={() => setImageLoaded(true)}
  />




          <Typography
          sx={{
            textAlign: 'center',
            mb: 3,
            fontSize:14.5,
            fontWeight: 600,
          }}
        >
          Your account has been successfully created!
        </Typography>

        <Typography
          sx={{
            px:3,
            textAlign: 'center',
            mb: 3,
            fontSize:14,
            fontWeight: 600,
          }}
        >
          Update your locations hours or make any other changes to your account in the Account Management Section.
        </Typography>

        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
          disabled={loading}
        sx={{
          backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
          padding: 1,
          borderRadius: '15px',
          mb:3,
          mt:1  ,
          width: '350px',
          fontSize: 17,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
          },
          '&:active': {
            backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
          },
        }}
            onClick={handleCreateSingleUser}
            variant="contained"
            color="primary"
          >
           {loading ? 'Loading...' : 'Finish Setup'} 
          </Button>
        </Box>
        <RestartSetupDialog
        open={showRestartDialog}
        onClose={handleCloseRestartDialog}
        confirmRestart={confirmRestart}
        setConfirmRestart={setConfirmRestart}
/>
      </Box>
    </Fade>
    </Modal>
  );
};

export default CreateSingleUserModal;
