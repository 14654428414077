import React, { useState, useEffect } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Dialog, DialogContent, DialogTitle, Box, Stack, Button } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';  
import {useSubscriptionDetails} from '../routes/useSubscriptionDetails';
import { useValidateSubscription } from '../routes/validateSubscription';
import { useAppContext } from 'src/context/AppContext';
import useAddLocation from 'src/components/payments/hooks/useAddLocation';
import useAddNewUser from 'src/components/payments/hooks/useAddNewUser.js'
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';  
import CreditCard from 'src/public/assets/icons/icons/creditCard.png';  
import { useAuth0 } from '@auth0/auth0-react';





const NewSubscription = ({ open, onClose, planDetails, customerId, onSubscriptionUpdate}) => {
  const stripe = useStripe();
  const elements = useElements();
const apiUrl = process.env.REACT_APP_API_URL;
const [clientSecret, setClientSecret] = useState(null); 
const [error, setError] = useState(null);
const { getAccessTokenSilently } = useAuth0();

const priceId = planDetails ? planDetails.plan_price_id : null;
const { refreshSubscription } = useValidateSubscription();  
const [loading, setLoading] = useState(false);
const { showSnackBar } = useAppContext();





useEffect(() => {
  if (!customerId || !priceId) {
    return;
  }

  // Get the access token
  getAccessTokenSilently().then((token) => {
    fetch(`${apiUrl}/subscriptions/handle-subscription`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ customerId, priceId }),
    })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to fetch client secret');
        return res.json();
      })
      .then((data) => {
        if (data.clientSecret) {
          setClientSecret(data.clientSecret); // Updates the state
        } else {
          throw new Error('Invalid client secret received');
        }
      })
      .catch((error) => {
        console.error('Error fetching clientSecret:', error);
        setError('Failed to initialize payment. Please try again.');
      });
  }).catch((error) => {
    console.error('Error getting access token:', error);
    setError('Authorization failed. Please try again.');
  });
}, [apiUrl, customerId, priceId, showSnackBar, getAccessTokenSilently]);


const handleSubmit = async (event) => {
  event.preventDefault();

  if (!stripe || !elements || !clientSecret) {
    setError('Stripe has not loaded or clientSecret is missing');
    return;
  }

  setLoading(true);

  // Confirm the SetupIntent (for subscriptions)
  const { error: setupError } = await stripe.confirmSetup({
    elements,
    confirmParams: {
      // You can specify a return_url here if needed
    },
    redirect: 'if_required',
  });

  // Check for any error during the confirmation of SetupIntent
  if (setupError) {
    setError(setupError.message);
    setLoading(false);
    return;
  }

  // After confirming the SetupIntent, proceed with subscription creation
  try {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${apiUrl}/subscriptions/create-subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
        body: JSON.stringify({
        customerId,
        planDetails,
      }),
    });

    const subscriptionResponse = await response.json();


    if (response.ok) {

      // Immediately charge the customer for the first invoice
      if (subscriptionResponse.latest_invoice) {
        const paymentIntentClientSecret = subscriptionResponse.latest_invoice.payment_intent;

        const { error: paymentError } = await stripe.confirmPayment({
          clientSecret: paymentIntentClientSecret,
          elements,
          confirmParams: {
            return_url: `http://${apiUrl}/dashboard/Account/Billing`, // Your return URL
          },
        });

        if (paymentError) {
          setError(paymentError.message);
          setLoading(false);
          return;
        }
      }

      onSubscriptionUpdate();
      refreshSubscription();
      onClose();
      showSnackBar('Subscription created and charged successfully', true);
    } else {
      // Set a more descriptive error message based on the subscriptionResponse
      setError(subscriptionResponse.error || 'Failed to create subscription');
      showSnackBar('Unable to process your payment. Please try again', false);

    }
  } catch (error) {
    console.error('Error during subscription creation:', error); // Log the error for debugging
    setError('An unexpected error occurred. Please try again.'); // Generic error message
    showSnackBar('Unable to process your payment. Please try again', false);
  } finally {
    setLoading(false);
  }
};




  return (
    <Dialog open={open} onClose={onClose}
    PaperProps={{ sx: { borderRadius: "20px", minWidth:'510px' } }}>
    <DialogTitle
    sx={{
      alignItems: "center",
      height: "85px",
      backgroundColor: "#FFFFFF",
      pt: 2,
      color: "#374247",
      top: 0,             // Stick to the top
      zIndex: 1100,       // Ensure it stays on top
      pb:2,
    }}
  >
    <img
      src={CreditCard}
      style={{
        border: "1.25px solid rgba(173, 216, 230, 0.7)",
        width: "auto",
        height: "30px",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        padding: "3px",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
        borderRadius: "6px",
      }}
    />
     <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 3,
          color: "#7589A2",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
        </IconButton>
                    <Typography
              sx={{
                color: "#48768C",
                fontWeight: 600,
                lineHeight: "-2%",
                letterSpacing: "-.2px",
                fontSize: "18.75px",
                mt: "10px",
                height: "50px",
              }}
            >
              Add New Payment Method{" "}
            </Typography>
    
  </DialogTitle>


          
               <DialogContent sx={{marginBottom:1, }}>
     



<PaymentForm
customerId={customerId}
priceId={priceId}
clientSecret={clientSecret}
apiUrl={apiUrl}
onSubscriptionUpdate={onSubscriptionUpdate}
onClose={onClose}
refreshSubscription={refreshSubscription}
planDetails={planDetails}
handleSubmit={handleSubmit}
/>


               </DialogContent>
   

 
    </Dialog>
  );
};

export default NewSubscription;