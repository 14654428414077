import React, { useEffect, useState } from 'react';
import {
  Typography,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Modal,
  Box,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  

} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import editCustomerIcon from "../../public/assets/icons/icons/editCustomer.png";
import DeleteCustomerConfirmation from './DeleteCustomerConfirmation';
import { is } from 'date-fns/locale';
import { useAppContext } from 'src/context/AppContext';
import {useCustomersContext} from '../../context/CustomersContext'; // Import the useCustomersContext hook	
import { PrimaryButton, CancelButton, SecondaryButton } from 'src/components/Buttons/ModalButtons';
import DeleteDialog from './DeleteDialog';
import DeleteIcon from '../../public/assets/icons/icons/deleteRed.png';
import {useWidgetContext} from 'src/context/WidgetContext'; 







const CustomerEditModal = ({
  open,
  onClose,
  customer,
  onUpdate,
  onDelete,
}) => {
  const [localCustomer, setLocalCustomer] = useState(null);
  const [isDeleteDialogOpen,setIsDeleteDialogOpen,] = useState(false);
  const [loading, setLoading] = useState(false);
  const {handleDeleteCustomer, fetchCustomers} = useCustomersContext(); // Import the handleDeleteCustomer function from the CustomersContext
  const {fetchAllWidgetTypes} = useWidgetContext(); // Import the fetchAllWidgetTypes function from the WidgetContext 
  const {handleReloadComponents, showSnackBar} = useAppContext(); // Import the handleReloadComponents and showSnackBar functions from the AppContext 



  useEffect(() => {
    if (customer) {
      setLocalCustomer({ ...customer });
    } else {
      setLocalCustomer(null);
    }
  }, [customer]);

  useEffect(() => {
    if (!open) {
      setIsDeleteDialogOpen(false); // Reset delete dialog state when modal is closed
    }
  }, [open]);

  const handleUpdateCustomer = async () => {
    setLoading(true);
   await onUpdate(localCustomer);
    setLoading(false);
    onClose();
  };

  const getInitials = () => {
    if (localCustomer && localCustomer.first_name && localCustomer.last_name) {
      const firstNameInitial = localCustomer.first_name[0];
      const lastNameInitial = localCustomer.last_name[0];
      return `${firstNameInitial}${lastNameInitial}`;
    }
    return '';
  };


  const message = `Are you sure you want to delete ${localCustomer ? `${localCustomer.first_name} ${localCustomer.last_name}` : ""}`;

  const handleDelete = async (loading) => {
    try {
      setLoading(true);
      const success = await handleDeleteCustomer(localCustomer.phone_number);
      if (success) {
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    } finally {
      setLoading(false);
      fetchAllWidgetTypes();
      fetchCustomers();
    }
  };

  

  return (
    <Dialog 
    open={open} onClose={onClose}
    fullWidth={true} // Make the dialog take up the full width
    PaperProps={{ sx: { borderRadius: "20px", } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          pt: 3,
          pb: 2.45,
          color: "#374247",
        }}
      >
        <img
          src={editCustomerIcon}
          alt="editCustomerIcon"
          style={{
            border: "1px solid #B0BEC5",
            marginLeft:'7px',
            width: "auto",
            height: "31px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "3px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />

      </DialogTitle>
	
      <DialogContent
        sx={{
          width: '100%',
          backgroundColor: "#FFFFFF",
          padding: "29px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
          
        }}
      >

<Typography  sx={{  color: "#48768C" , fontSize:'21px', mb:2, fontWeight:600}}>
          Edit Customer{" "}
        </Typography>
        <Box sx={{ mb:2,                   border: '1.25px solid rgba(173, 216, 230, 0.7)',
 borderRadius: "15px",backgroundColor: 'rgba(191, 218, 231, 0.1)', boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px",
 }}> 

      
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
<Box sx={{ flexGrow: 1, }} />
  <IconButton         onClick={() => { setIsDeleteDialogOpen(true);  }}
>
<Box sx={{}}>
      <img
        src={DeleteIcon}
        alt="detailsIcon"
        style={{
          border: '1px solid #8C435036',
          width: "auto",
          height: "32px",
          backgroundColor:'#8C435036',
          borderRadius: "6px",
          padding: "1px",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
          marginBottom: "5px", 
        }}
      />
    </Box>
  </IconButton>
</Box>
<Box sx={{ display: 'flex',flexDirection: 'row', alignItems: 'center', width: '100%', mb:1 }}>



</Box>
<Avatar
    sx={{
      ml: 2,
      backgroundColor: 'rgba(8, 19, 42, 0.7)',
      width: 55,
      height: 55,
      mb: 2,
      mr: 2,
      mt:-5,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}
  >
    <Typography sx={{ fontSize: '22px' }}>{getInitials()}</Typography>
  </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', mb: 1,              px:2,
 }}>


   
          <TextField
            placeholder="First Name"
            variant="standard"  
            helperText="First Name"
            fullWidth
            value={localCustomer ? localCustomer.first_name || '' : ''}
            onChange={(e) =>
              setLocalCustomer((prev) => ({
                ...prev,
                first_name: e.target.value || '',
              }))
            }
            sx={{
              ml:1,
              color: '#48768C',
              pb: 2,
              mr: 2,
              "& .MuiInput-underline": {
                
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: '#2F5C71', // onHover border color
            },
            "&.Mui-focused:before": {
              borderBottomColor: '#2F5C71', // onFocus border color
            },
            "&.Mui-focused:after": {
              borderBottomColor: '#48768CBA', // Focused border color (with transparency)
              borderWidth: '2px', // Optional: Adjust border width for focus state
            },
          },
              '& input': {
                width: '100%',
                textAlign: 'left',
                color: '#48768C',

              },
            }}
          />

          <TextField
            placeholder="Last Name"
            variant="standard"  
            helperText="Last Name"
            fullWidth
            value={localCustomer ? localCustomer.last_name || '' : ''}
            onChange={(e) =>
              setLocalCustomer((prev) => ({
                ...prev,
                last_name: e.target.value || '',
              }))
            }
            sx={{
              color: '#48768C',
              pb: 2,
              "& .MuiInput-underline": {
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: '#2F5C71', // onHover border color
            },
            "&.Mui-focused:before": {
              borderBottomColor: '#2F5C71', // onFocus border color
            },
            "&.Mui-focused:after": {
              borderBottomColor: '#48768CBA', // Focused border color (with transparency)
              borderWidth: '2px', // Optional: Adjust border width for focus state
            },
          },
              '& input': {
                width: '100%',
                textAlign: 'left',
                color: '#48768C',

              },
            }}
          />
</Box>
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 3,              px:2,}}>

          <TextField
            placeholder="Phone Number"
            variant="standard"  
            helperText="Phone Number"            fullWidth
            value={localCustomer ? localCustomer.phone_number || '' : ''}
            onChange={(e) =>
              setLocalCustomer((prev) => ({
                ...prev,
                phone_number: e.target.value || '',
              }))
            }
            sx={{
              ml:1,
              color: '#48768C',
              '& input': {
                color: '#48768C',

                width: '100%',
                textAlign: 'left',
              },
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',width:'100%', backgroundColor:'#FFFFFF', height:'auto', my:1 }}>


<SecondaryButton
  disabled={loading || !localCustomer?.first_name || !localCustomer?.last_name || !localCustomer?.phone_number}
sx={{
  fontSize: '17px',
  width: '95%',
  mb:1,
  
}}

onClick={handleUpdateCustomer}

>
{loading ? 'Saving...' : 'Save Changes'}
</SecondaryButton>
</Box>
        </Box>



        </DialogContent>
        <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={() => {setIsDeleteDialogOpen(false);}}

    
        onConfirm={handleDelete}
        handleOpenReviewDialog={handleDelete}


        header='Confirm Delete Customer'
        message = {message}
        subText='This will not remove any notifications you sent to this customer from your notification history.'
       buttonText='Delete Customer'
       hideReviewDialog={true}
       buttonLoadingText='Deleting...'
       isLoading={loading}
       />
    </Dialog>
  );
};

export default CustomerEditModal;
