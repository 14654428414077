import React, {useEffect} from 'react';  
import { Card, Grid, Typography, Button, Stack, IconButton, Box, CardMedia } from '@mui/material';
import NewSubscription from 'src/components/payments/components/NewSubscription.js';
import EditSubscription from 'src/components/payments/components/EditSubscription';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useCancelSubscription from 'src/components/payments/hooks/useCancelSubscription.js';
import { useSubscriptionDetails } from 'src/components/payments/routes/useSubscriptionDetails';
import { useValidateSubscription } from 'src/components/payments/routes/validateSubscription';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import VisaCard from 'src/public/assets/icons/icons/visaCard.png';
import MasterCard from 'src/public/assets/icons/icons/masterCard.png';
import CreditCard from 'src/public/assets/icons/icons/creditCard.png';  


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);




const BillingDataCard = ({ planDetails, name, status, cost, reoccurrence, paymentMethod, subscriptionId, customerId, 
  priceId, onSubscriptionUpdate, subscriptionValid, stripePromise, subscriptionDetails, 
  description, title, refetchSubscriptionDetails, billingAddress, subUserData, 
  billingHistory, billingContact, fetchBillingHistory, checked, paymentStatus, failedPaymentError, handleSubscriptionUpdate} ) => {
  const apiUrl = process.env.REACT_APP_API_URL; 
    const [showNewSubscription, setShowNewSubscription] = React.useState(false);
    const [showEditSubscription, setShowEditSubscription] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    





    const handleShowNewSubscription = () => {
        setShowNewSubscription(true);
    }


    const handleNewSubscriptionClose = () => {  
        setShowNewSubscription(false);
    }

    const handleShowEditSubscription = () => {  
        setShowEditSubscription(true);
    }

    const handleEditSubscriptionClose = () => {
        setShowEditSubscription(false);
    }

    

    const { isCancelling, cancelError, cancelSuccess, cancelSubscription } = useCancelSubscription();



    const handleCancelClick = () => {
      if (customerId && subscriptionId) {
        cancelSubscription(customerId, subscriptionId, onSubscriptionUpdate);
      }
    };

    useEffect(() => {
      if ( !subscriptionDetails ) {
        setIsLoading(true); 
      } else {
        setIsLoading(false);
      }
    }, [name, status, planDetails, subscriptionDetails]);







    const renderLogo = (brand) => {
      if (!brand) return CreditCard;  // Handle null or undefined early
      switch (brand.toLowerCase()) {
        case 'visa':
          return VisaCard;
        case 'mastercard':
          return MasterCard;
        default:
          return CreditCard;
      }
    };


  

    return (
      <>
      {!isLoading ? (

<Card
sx={{            
width:'100%', padding:1.5, backgroundColor: 'rgba(191, 218, 231, 0.1)',
border: '1px solid rgba(173, 216, 230, 0.7)', boxShadow: "0 4px 8px rgba(0,0,0,0.1)",

flexDirection: 'column',
justifyContent: 'center',overflowX: "auto", whiteSpace: "nowrap"}}
>







<Stack direction="row"> 
    <Typography
    color="textSecondary"
      sx={{
        ml:1,
        whiteSpace: "nowrap",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        fontSize: "17.5px",
        textTransform: "none",
        mt:.45
      }}
    >
      {title}
    </Typography>
    </Stack>
    

  <>
  {paymentStatus !== "failed" ? (
  <Typography
    sx={{
      ml:1,
      whiteSpace: "nowrap",
      fontWeight: 500,
      letterSpacing: "0.02em",
      fontSize: "14.5px",
      textTransform: "none",
      mt:-.15,
      color:'#48768C'
    }}
  >
    {description}
  </Typography>
) : (
  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', ml: '10px', mt: '3px', mb: -1 }}>
    <Typography
      sx={{
        pr: 2,
        fontWeight: 600,
        letterSpacing: "0.02em",
        fontSize: "13.75px",
        color: "#48768C",
        color: '#A65160',
        whiteSpace: 'wrap'
      }}
    >
      {failedPaymentError}
    </Typography>
  </Box>
)}

  </>





 
   <Box sx={{border: '1px solid rgba(173, 216, 230, 0.7)', justifyContent:'center', alignSelf: 'center', paddingY: 1.5,mt:1.5, mx:1, borderRadius:'5px',  overflowX: "auto", whiteSpace: "nowrap", mt:2.5}}>
     <Stack direction="row" sx={{ width: '100%', overflowX: "auto", whiteSpace: "nowrap" }}>
  <>
    <CardMedia
      sx={{
        border: renderLogo(subscriptionDetails?.card?.brand).src === CreditCard ?  '1.95px solid rgba(173, 216, 230, 0.9)' : '1.5px solid rgba(173, 216, 230, 0.5)',
        width: renderLogo(subscriptionDetails?.card?.brand).src === CreditCard ? '70px' : '50px',
        height: renderLogo(subscriptionDetails?.card?.brand).src === CreditCard ? '70px' : '40px',
        borderRadius: '5px',
        ml: 1,
        mr: 1,
        backgroundColor: renderLogo(subscriptionDetails?.card?.brand).src === CreditCard ? '#FFFFFF' : 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        objectFit: 'contain',

      }}
    >
      <img
        src={renderLogo(subscriptionDetails?.card?.brand ?? CreditCard)}
        alt="card"
        style={{
          width: renderLogo(subscriptionDetails?.card?.brand).src === CreditCard ? '70px' : '45px',
          height: renderLogo(subscriptionDetails?.card?.brand).src === CreditCard ? '70px' : 'auto',
          flexShrink: 0,
          objectFit: 'contain',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0,
          objectFit: 'contain',

        }}
      />
    </CardMedia>

    <Typography
      sx={{
        whiteSpace: "nowrap",
        color: '#48768C',
        fontWeight: 600,
        letterSpacing: "-0.02em",
        fontSize: "1rem",
        textTransform: "none",
        ml: '5px',
        mt: 1,
        display: subscriptionDetails?.card?.last4 ? 'none' : 'flex',
        mr:2
      }}
    >
      No Credit Card on File
    </Typography>

    {subscriptionDetails?.card && (
      <Box sx={{ display: 'flex', flexDirection: 'row', mt:.15 }}>
        <Typography
          sx={{
            whiteSpace: "nowrap",
            color: '#48768C',
            fontWeight: 600,
            letterSpacing: "-0.02em",
            fontSize: "1rem",
            textTransform: "none",

          }}
        >
          {subscriptionDetails?.card?.brand
            ? `${subscriptionDetails.card.brand.charAt(0).toUpperCase()}${subscriptionDetails.card.brand.slice(1)}`
            : ""}
        </Typography>

        <Typography
          sx={{
            whiteSpace: "nowrap",
            color: '#48768C',
            fontWeight: 600,
            letterSpacing: "-0.02em",
            fontSize: "1rem",
            textTransform: "none",
            ml: '5px',
            display: subscriptionDetails?.card?.last4 ? 'flex' : 'none',
          }}
        >
          Ending in {subscriptionDetails?.card?.last4 }
        </Typography>

        <Typography
          sx={{
            color: "#48768C",
            whiteSpace: "nowrap",
            fontWeight: 600,
            letterSpacing: "-0.01em",
            fontSize: "12px",
            textTransform: "none",
            border: '.75px solid #48768C',
            borderRadius: '50px',
            ml: 1.75,
            pl: "8px",
            pr: "8px",
            mt: 0,
            pt:'1px',
            mr:3,
            backgroundColor: '#FFFFFF',
            height:'22px',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            mt:-2,  
            display: subscriptionDetails?.card?.last4 ? 'flex' : 'none',
          }}
        >
          Default
        </Typography>
      </Box>
    )}
  </>
  {!subscriptionDetails?.card ? ( 
    <Button 
    onClick={handleShowNewSubscription} 
    sx={{
      display: 'flex',
      ml: 'auto',
      justifyContent: 'center',
      border: '1px solid #A4C5D2E8',
      borderRadius: '6px',
      padding: '1px 0',  // Consistent padding
      color: '#48768C',
      backgroundColor: '#FFFFFF',
      fontSize: 13.76,
      height: '35px', // Fixed height to match the first button
      mr: 1,
      px: 2,
      width: 'fit-content', // Adjust width to content
      minWidth: '100px', // Ensure a reasonable minimum width
      whiteSpace: 'nowrap', // Prevent text wrapping
      overflow: 'hidden', // Hide overflow content
      textOverflow: 'ellipsis', // Add ellipsis for overflowing text
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
     '&:hover': {
       backgroundColor: '#DBEAF0',
     },
     '&:active': {
       backgroundColor: '#DBEAF0',
     },
   }}
 >


Add New Card

    </Button>
) : (
 
    <Button 
    onClick={handleShowEditSubscription} 
    sx={{
           display:'flex',
           ml:'auto',
           justifyContent:'center',
     border: '1px solid #A4C5D2E8',
     borderRadius: '6px',
     padding: '1px 0',  // Consistent padding
     color: '#48768C',
     backgroundColor: '#FFFFFF',
     fontSize: 13.76,
     height: '35px',      // Fixed height to match the first button
     mr:1,
     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',

     '&:hover': {
       backgroundColor: '#DBEAF0',
     },
     '&:active': {
       backgroundColor: '#DBEAF0',
     },
   }}
 >


Edit
    </Button>
  )}
</Stack>
      {subscriptionDetails?.card && (
  <Typography
    align="left"
    sx={{
      mt: -2.45,
      ml: 2.5,
      pl: '46px',
      whiteSpace: "nowrap",
      color: '#48768CA8',
      fontWeight: 600,
      letterSpacing: "-0.015em",
      fontSize: ".85rem",
      textTransform: "none",
      
    }}
  >
    Expires: {`${subscriptionDetails.card.exp_month}/${subscriptionDetails.card.exp_year}`}
  </Typography>
)}

    </Box>



<Elements stripe={stripePromise}>
<NewSubscription open={showNewSubscription} onClose={handleNewSubscriptionClose}
customerId={customerId} 
priceId={priceId}
onSubscriptionUpdate={onSubscriptionUpdate} 
planDetails={planDetails}
stripePromise={stripePromise}
subscriptionId={subscriptionId}
billingAddress={billingAddress}


/>
<EditSubscription open={showEditSubscription} onClose={handleEditSubscriptionClose}
customerId={customerId} 
priceId={priceId}
onSubscriptionUpdate={onSubscriptionUpdate} 
planDetails={planDetails}
stripePromise={stripePromise}
subscriptionId={subscriptionId}
refetchSubscriptionDetails={refetchSubscriptionDetails}
billingAddress={billingAddress}
subUserData={subUserData}
billingHistory={billingHistory}
billingContact={billingContact}
fetchBillingHistory={fetchBillingHistory}
handleSubscriptionUpdate={handleSubscriptionUpdate}



/>
</Elements>
<Button

    onClick={handleShowNewSubscription}
    sx={{
      display:"none",
            border: '1px solid #48768C',
      borderRadius: '10px',
      padding: '8px',
      color: '#48768C',
      backgroundColor: '#FFFFFF',
      ml:.25,
      mt: 2,
      mb:1,
      justifyContent: 'center',
      alignItems: 'center', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: '#DBEAF0',
      },
      '&:active': {
        backgroundColor: '#DBEAF0',
      },
    }}
    color="primary"
  >
    Activate Your Subscription
  </Button>
<Button onClick={handleCancelClick} disabled={isCancelling}
     sx={{
      display:"none",
      border: '1px solid #48768C',
      borderRadius: '10px',
      padding: '8px',
      color: '#48768C',
      backgroundColor: '#FFFFFF',
      ml:.25,
      mt: 2,
      mb:1,
      justifyContent: 'center',
      alignItems: 'center', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: '#DBEAF0',
      },
      '&:active': {
        backgroundColor: '#DBEAF0',
      },
    }}
    color="primary"
  >
      {isCancelling ? 'Cancelling...' : 'Cancel Subscription'}
    </Button>
</Card>
   ) : (
    <Card
      sx={{
        width: "100%",
        padding: 1.5,
        backgroundColor: "rgba(191, 218, 231, 0.1)",
        border: "1px solid rgba(173, 216, 230, 0.7)",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          color: "#48768CBF",
          mr: "5px",
          whiteSpace: "nowrap",
          fontWeight: 500,
          letterSpacing: "-0.03em",
          fontSize: "18px",
          textTransform: "none",
          justifyContent: "center",
          alignItems: "center",
          fontStyle: "italic",
        }}
      >
        Loading billing information...
      </Typography>
    </Card>
  )}
</>
);
}

export default BillingDataCard; 