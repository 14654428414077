import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';  
import Typography from '@mui/material/Typography';  


export default function DataLoadingAlert({ title, alertText }) {
  const [open, setOpen] = React.useState(false);


  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true); // After 1 second, set open to true to show the alert
    }, 250);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 30,
        left: '55%',
        transform: 'translateX(-50%)',
        width: '20%',
      }}
    >

                  <Fade in={open} timeout={10}>

      <Stack sx={{ width: '100%' }} spacing={2}>
        <Collapse in={open} timeout={500}>  {/* Adjust the timeout for desired fade duration */}
          <Alert
            severity="info"
         
            sx={{
              mb: 1,
              opacity: open ? 1 : 0,  // Control the fade effect
              transition: 'opacity 0.5s ease-in-out', // Smooth fade-in transition
              border:'1px solid #43637A1F',
              borderRadius: '10px', 
            }}
          >
            <AlertTitle sx={{fontSize: "16px",
        color: "#316178D4",
        fontWeight: 600,
        fontFamily: "Source Sans Pro, sans-serif",
        textAlign: "left",letterSpacing:-.1}}>{title}</AlertTitle>
            
            
         <Typography sx={{        fontSize: "15px",
        color: "#316178D4",
        fontWeight: 500,
        fontFamily: "Source Sans Pro, sans-serif",
        textAlign: "left", mt:'-5px'}}>   {alertText} </Typography>


          </Alert>
        </Collapse>
      </Stack>
      </Fade>
    </div>
  );
}