import React, { useEffect, useState } from "react";
import { Modal, Typography, Button, Box, Fade, Skeleton, CardMedia } from '@mui/material';
import addLocation from 'src/public/assets/addLocation.png';
import RestartSetupDialog from "./RestartSetupDialog";  



const FirstLocationPrompt = ({ isOpen, onNext }) => {

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = addLocation;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  const [showRestartDialog, setShowRestartDialog] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);    


  const handleBackdropClick = (event) => {
    // Check if the click is on the backdrop by comparing target and currentTarget
    if (event.target === event.currentTarget) {
      setConfirmRestart(false)
      setShowRestartDialog(true); // Open RestartSetupDialog
    }
  };

  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };


  return (
    <Modal open={isOpen}
    slotProps={{
      backdrop: {
        onClick: handleBackdropClick, // Disable closing the modal by clicking on the backdrop
      },
    }}
    disableEscapeKeyDown // Disable closing the modal by pressing the escape key
  >

<Fade in={isOpen} timeout={400}>


      <Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'30%',
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',
          padding:2,

        }}
      >               
      <Typography
      gutterBottom
      sx={{
        mt:1,
        color: '#48768C',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 600,

      }}
    >
          Lets Add Your First Location
        </Typography>  


        {!imageLoaded && (
    <Skeleton
      variant="rectangular"
      sx={{
        height: 'auto',
        maxWidth: '65%', maxHeight:'65%', 
        objectFit: 'contain',
        borderRadius: '10px', 
        backgroundColor: '#EEF6F9', // Adjust as needed to match background
        transition: 'opacity 0.3s ease-in-out',
        opacity: imageLoaded ? 0 : 1,
      }}
    />
  )}
    <CardMedia
    component="img"
    image={addLocation}
    sx={{
      display: "block",
      margin: "auto",
      marginBottom: "20px",
      maxWidth: '65%', maxHeight:'65%', 

      objectFit: 'contain',
      transition: 'opacity 0.3s ease-in-out',
      opacity: imageLoaded ? 1 : 0,
    }}
    onLoad={() => setImageLoaded(true)}
  />



 
          <Typography
          sx={{
            color: '#48768C',
            fontSize: 14.5,
            fontWeight: 600,

            textAlign: 'center',
            mb: 4,
          }}
        >            
        For now, lets just add one location. Then you can always add your other locations later in the account management panel.
        </Typography>
        <Box sx={{ marginTop: 1, display: 'flex', justifyContent: 'center', width:'100%' }}>
        <Button onClick={onNext} variant="contained"
                  sx={{
                    mb:2,
                    backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
                    borderRadius: 2.25,
                    width: '90%',
                    fontSize: 18,
                    fontWeight: 600,

                    '&:hover': {
                      backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
                    },
                    '&:active': {
                      backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
                    },
                  }}>
            Next
          </Button>
        </Box>
        <RestartSetupDialog
        open={showRestartDialog}
        onClose={handleCloseRestartDialog}
        confirmRestart={confirmRestart}
        setConfirmRestart={setConfirmRestart}
/>
      </Box>
      </Fade>
    </Modal>
  );
};

export default FirstLocationPrompt;
