import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Modal,
  Select,
  MenuItem,
  Box,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Stack,
  Paper
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import editUser from "../../public/assets/icons/icons/editUser.png";
import userDetailsIcon from "../../public/assets/icons/icons/userDetails.png";
import userAccountDetailsIcon from "../../public/assets/icons/icons/userAccountDetails.png";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useAccountContext } from "src/context/AccountContext";
import ChangePinModal from "../modal/ChangePinModal";
import { useAuth0 } from "@auth0/auth0-react";
import Person2TwoToneIcon from '@mui/icons-material/Person2TwoTone';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


const ViewUsersModal = ({
  isOpen,
  onClose,
  onSave,
  viewData,
  setViewData,
  locations,
}) => {
  const [title, setTitle] = useState(""); // Initial role
  const [isAdmin, setIsAdmin] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const { accountData } = useAccountContext();
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const [selectedUserForPinChange, setSelectedUserForPinChange] =
    useState(null);
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const { user, getAccessTokenSilently } = useAuth0();


  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  const handleSave = () => {
    onSave();
    onClose();
  };





  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "13px" } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <DialogTitle
        sx={{
          padding: 2.5,

          width: "550px",
          display: "flex",
          alignItems: "center",
          height: "55px",
          backgroundColor: "#FFFFF",
          pt: 3,
          pb: 1.45,
          color: "#374247",
        }}
      >
        <i
          style={{
            border: "1.25px solid rgba(173, 216, 230, 0.8)",
            fontSize: "18px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "4px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
            color: "#7589A2",
            width: "auto",
            height: "28px",
          }}
          className="fa-regular fa-user"
        > </i>
      </DialogTitle>

      <DialogContent
        sx={{
          px: 2,
          pt:2,
          pb:1,
          width: "100%",
          backgroundColor: "#FFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
          <Typography  sx={{ color: "#48768C", fontSize:19, mb:1.5, fontWeight:600, pl:.55 }}>

          Employee Details{" "}
        </Typography>

            {/* Transparent overlay to intercept clicks */}

            <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  }}
>
  {/* Transparent overlay to intercept clicks */}
  <Box
    sx={{
      width: "100%",
      backgroundColor: "#F2F9FF",
      border: "1px solid rgba(173, 216, 230, 0.9)",
      padding: "10px", // Added consistent padding for breathing room
      borderRadius: "8px", // Rounds corners for a softer look
      py:'10px',
      pb:'20px', 
    }}
  >
    {/* Employee Information Section */}
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center", // Aligns text vertically with the icon
        mb: 1, // Space between sections
      }}
    >
      <img
        src={userDetailsIcon}
        alt="Employee Icon"
        style={{
          width: "25px",
          height: "25px",
          backgroundColor: "#FFFFFF",
          borderRadius: '5px',
          padding: "2px",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Slight shadow for depth
          border:'1px solid rgba(173, 216, 230, 0.9)',

        }}
      />
      <Typography
        sx={{
          ml: 1, // Space between icon and text
          color: "#48768C",
          fontSize: 17.15,
          fontWeight: 600,
          alignSelf: "center", // Vertically center the text with the icon
          mt:'2px'
        }}
      >
        Employee Information
      </Typography>
    </Box>

    <Typography
      sx={{
        fontSize: "16.75px",
        color: "#316178D4",
        fontWeight: 600,
        fontFamily: "Source Sans Pro, sans-serif",
        textAlign: "left",
        mt: 1,
        ml: 1,
      }}
    >
      {viewData.first_name} {viewData.last_name}
    </Typography>
<Stack direction="row" >
    <Typography
      sx={{
        fontSize: "16.5px",
        color: "#3161788A",
        fontWeight: 500,
        fontFamily: "Source Sans Pro, sans-serif",
        textAlign: "left",
        ml: 1,
      }}
    >
      {viewData.title}
    </Typography>
    <Typography
      sx={{
        fontSize: "16.5px",
        color: "#316178B8",
        fontWeight: 600,
        fontFamily: "Source Sans Pro, sans-serif",
        textAlign: "left",
        mx: "5px",
      }}
    >
     |
    </Typography>
    <Typography
            sx={{
              fontSize: "16.5px",
              color: "#3161788A",
              fontWeight: 500,
              fontFamily: "Source Sans Pro, sans-serif",
              textAlign: "left",
     
            }}
          >
            {viewData.assigned_location}
          </Typography>
          </Stack>

    {/* Account Information Section */}
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center", // Aligns text vertically with the icon
        mt: 1.5, // Space between sections
      }}
    >
      <img
        src={userAccountDetailsIcon}
        alt="Account Icon"
        style={{
          width: "27px",
          height: "27px",
          backgroundColor: "#FFFFFF",
          borderRadius: '5px',
          padding: "2px",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Slight shadow for depth
          border:'1px solid rgba(173, 216, 230, 0.9)',

        }}
      />
      <Typography
        sx={{
          ml: 1, // Space between icon and text
          color: "#48768C",
          fontSize: 17.15,
          fontWeight: 600,
          alignSelf: "center", // Vertically center the text with the icon
          mt:'2px'

        }}
      >
        Account Information
      </Typography>
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",

      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#F2F9FF",
            padding: "1px", // Added padding for breathing room
            borderRadius: "8px", // Rounds corners for a softer look
            mt:'15px'
          }}
        >
          <Stack direction="row" sx={{border:'1px solid rgba(173, 216, 230, 0.9)',
            mt:'-5px', borderRadius:'5px', backgroundColor:'#FFFFFF', width:'100%',
            alignItems:'center', py:'2px', mb:'5px'}}>
          <Paper elevation={4}
  sx={{
    width: "23px",
    height: "23px",
    backgroundColor: "#48768C1A",
    borderRadius: "5px",
    padding: "3px",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",  // Slight shadow for depth
    mr: 0.25,
    display: "flex",        // Added this
    justifyContent: "center",  // Centers horizontally
    alignItems: "center",   // Centers vertically
    border:'1px solid rgba(173, 216, 230, 0.9)',
    ml: 0.5,
  }}
>
  <Person2TwoToneIcon
    sx={{
      fontSize: "26px",
      color: "#48768CBF",
      
    }}
  />
</Paper>
<Typography
  sx={{
    fontSize: '16.5px',
    borderRadius: '3px',
    color: "#357493C4",
    fontFamily: 'Source Sans Pro, sans-serif',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#F2F9FF24',
    letterSpacing: -0.05,
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.05)', // Added slight text shadow
    display: 'flex', // Ensures the text container is a flexbox
    alignItems: 'center', // Vertically centers the text
    pt:'2px',
    pl:'8px',
  }}
>
            {viewData.username}
          </Typography>
          </Stack>
          <Stack direction="row" sx={{border:'1px solid rgba(173, 216, 230, 0.9)',
            mt:.5, borderRadius:'5px', backgroundColor:'#FFFFFF', width:'100%',
            alignItems:'center', py:'2px'}}>
          <Paper elevation={4}
  sx={{
    width: "23px",
    height: "23px",
    backgroundColor: "#48768C1A",
    borderRadius: "5px",
    padding: "3px",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",  // Slight shadow for depth
    mr: 0.25,
    display: "flex",        // Added this
    justifyContent: "center",  // Centers horizontally
    alignItems: "center",   // Centers vertically
    border:'1px solid rgba(173, 216, 230, 0.9)',
    ml: 0.5,


  }}
>
  <AdminPanelSettingsIcon
    sx={{
      fontSize: "26px",
      color: "#48768CBF",
      
    }}
  />
</Paper>
<Typography
  sx={{
    fontSize: '16.5px',
    borderRadius: '3px',
    color: "#357493C4",
    fontFamily: 'Source Sans Pro, sans-serif',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#F2F9FF21',
    letterSpacing: -0.06,
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.05)', // Added slight text shadow
    display: 'flex', // Ensures the text container is a flexbox
    alignItems: 'center', // Vertically centers the text
    pt:'2px',
    pl:'8px',
  }}
>
    {viewData.is_admin ? 'Administrator' : 'Standard User'}
          </Typography>
          </Stack>


        </Box>
      </Box>
    </Box>
  </Box>
</Box>
            






      </DialogContent>
      <DialogActions
        sx={{
          pt: 1,
          backgroundColor: "#FFFFF",
          width: "100%",
          mx: "auto", // Set left and right margin to auto to center horizontally
          my: "auto",
          display: "flex", // Use flexbox
          justifyContent: "center", // Center content horizontally
        }}
      ></DialogActions>
    </Dialog>
  );
};

export default ViewUsersModal;
