import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { set } from 'lodash';
import { useAuth } from './AuthContext';
import { handleCompleteSetup } from 'src/components/NewUserChecklist/completeSetup';


const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, getAccessTokenSilently } = useAuth0();
  const [accountData, setAccountData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [showNewUserSignUp, setShowNewUserSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [onboardingData, setOnboardingData] = useState({});
  const [onboardingProgress, setOnboardingProgress] = useState(0); // Overall progress
  const [requiredItemsProgress, setRequiredItemsProgress] = useState(0); // Required items progress
  const [optionalItemsProgress, setOptionalItemsProgress] = useState(0); // Optional items progress
  const [totalOptionalItems, setTotalOptionalItems] = useState(0); // Optional items count
  const [completedOnboarding, setCompletedOnboarding] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);  
  const {isLoggedIn} = useAuth();
  const [supressDialog, setSupressDialog] = useState(false);  


  






  const [notificationSettings, setNotificationSettings] = useState({
    messageSentAlerts: true,
    inactivityTimeout: true,
    promotionsOrOffers: true,
    dailySummaryReport: true,
    weeklyActivityReport: true,
    newCustomerAdded: true,
    approachingMonthlyLimit: true,
    dailyCostSummary: true,
    weeklyCostSummary: true,
    upcomingPaymentReminders: true,
  });





    const fetchAccountData = async (auth0UserId) => {
      try {
        const accessToken = await getAccessTokenSilently();
  
        const response = await fetch(`${apiUrl}/api/accounts/${auth0UserId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          setAccountData(data.accountData);
          setDataFetched(true);
          sessionStorage.setItem('accountData', JSON.stringify(data.accountData));
  
          // Handle empty response
          if (isEmptyObject(data)) {
            setShowNewUserSignUp(true);
          }
        } else {
          console.error('Error fetching account data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      }
    };
  
    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };



    useEffect(() => {
      const fetchAccountData = async (auth0UserId) => {
        try {
          const accessToken = await getAccessTokenSilently();
    
          const response = await fetch(`${apiUrl}/api/accounts/${auth0UserId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
    
          if (response.ok) {
            const data = await response.json();
            setAccountData(data.accountData);
            setDataFetched(true);
            sessionStorage.setItem('accountData', JSON.stringify(data.accountData)); // Use sessionStorage instead of localStorage
    
            // Handle empty response
            if (isEmptyObject(data)) {
              setShowNewUserSignUp(true);
            }
          } else {
            console.error('Error fetching account data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching account data:', error);
        }
      };
    
      const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      };
    
      if (!dataFetched && user && user.sub) {
        fetchAccountData(user.sub);
      }
    }, [apiUrl, user, dataFetched, getAccessTokenSilently]);
    
    useEffect(() => {
      const initialAccountDataString = sessionStorage.getItem('accountData'); // Use sessionStorage instead of localStorage
    
      try {
        const initialAccountData = initialAccountDataString
          ? JSON.parse(initialAccountDataString)
          : null;
        setAccountData(initialAccountData);
      } catch (error) {
        if (initialAccountDataString !== undefined) {
          console.error('Error parsing initialAccountData:', error);
        }
      }
    }, []);

  const setAuthData = (newAuth0UserId) => {
    setAccountData(null); // Reset account data
    setDataFetched(false); // Reset data fetched flag
  };

  const resetContext = () => {
    setAccountData(null);
    setDataFetched(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      if (user && user.sub && accountData) {  // Ensure user and user.sub are defined
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await fetch(`${apiUrl}/api/in-app-notifications/get_notifications/${user.sub}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch notifications data');
          }
  
          const data = await response.json();
          setNotificationSettings(data);
        } catch (error) {
          console.error('Error fetching notification settings:', error);
        }
      }
    };
  
    fetchData();
  }, [apiUrl, user, getAccessTokenSilently, accountData]);  // Add user and getAccessTokenSilently as dependencies


  const saveSettings = async (key, value) => {
    
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(`${apiUrl}/api/in-app-notifications/save_notifications/${user.sub}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ [key]: value }),
    });

    if (!response.ok) {

      throw new Error('Failed to save notification settings');
    }
  };



  const fetchOnboardingRecord = async (setLoading = () => {}) => {
    setError(null);
    try {
        // Ensure that user is defined
        if (user && user.sub ) {
        // Get the access token from Auth0
        const token = await getAccessTokenSilently();
        const userId = user.sub;  
        // Make the GET request using fetch
        const response = await fetch(`${apiUrl}/onboarding`, {
          method: 'POST', // Use POST instead of GET
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in Authorization header
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }), // Send auth0_user in the request body
        });

        if (response.status === 204) {
          setOnboardingData(null);
          setOnboardingProgress(0);
          setRequiredItemsProgress(0);
          setOptionalItemsProgress(0);
          setCompletedOnboarding(false);
          return;
        }

        // Check if the response was successful
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        // Parse the JSON data
        const data = await response.json();
        setOnboardingData(data);
        
        // Helper to calculate completed items
        const calculateCompletedItems = (data, items) => {
            return items.reduce((count, key) => (data[key] === true ? count + 1 : count), 0);
        };
        
        // Adjusted calculateOverallProgress function
        const calculateOverallProgress = (data, requiredItems, optionalItems) => {
            const totalItems = requiredItems.length + optionalItems.length; // Total items including required and optional
            const completedRequiredItems = calculateCompletedItems(data, requiredItems); // Completed required items
            const completedOptionalItems = calculateCompletedItems(data, optionalItems); // Completed optional items
        
            const completedItems = completedRequiredItems + completedOptionalItems; // Total completed items
        
            // Ensure that we don't divide by zero
            if (totalItems === 0) return 0;
        
            const overallProgress = (completedItems / totalItems) * 100; // Overall completion percentage
        
            return Math.min(overallProgress, 100); // Cap the percentage at 100
        };
        
        // Calculate progress for required items
        const calculateRequiredItemsProgress = (data, requiredItems) => {
            const completedRequiredItems = calculateCompletedItems(data, requiredItems);
            return (completedRequiredItems / requiredItems.length) * 100; // Required items completion percentage
        };
        
        // Calculate progress for optional items
        const calculateOptionalItemsProgress = (data, optionalItems) => {
            const completedOptionalItems = calculateCompletedItems(data, optionalItems);
            
            // Ensure that we don't divide by zero
            if (optionalItems.length === 0) return 0;
        
            return (completedOptionalItems / optionalItems.length) * 100; // Optional items completion percentage
        };
        
        // Update the getOptionalItems function to ensure it calculates correctly
        const getOptionalItems = (accountData) => {
            if (accountData && accountData.single_user) {
                return ['company_logo'];
            } else if (accountData && accountData.single_location && !accountData.single_user) {
                return ['add_users_confirmed', 'company_logo'];
            } else {
                return ['add_locations_confirmed', 'add_users_confirmed', 'company_logo'];
            }
        };
        
        // Required items for all users
        const requiredItems = ['email_verified', 'hours_confirmed', 'notifications_confirmed'];
        
        // Fetch and calculate optional items first
        const optionalItems = getOptionalItems(accountData);
        
        // Perform the calculations
        const overallProgress = calculateOverallProgress(data, requiredItems, optionalItems);
        const requiredProgress = calculateRequiredItemsProgress(data, requiredItems);
        const optionalProgress = calculateOptionalItemsProgress(data, optionalItems);
        
        // Set the calculated progress
        setOnboardingProgress(overallProgress);
        setRequiredItemsProgress(requiredProgress);
        setOptionalItemsProgress(optionalProgress);
        setTotalOptionalItems(optionalItems.length); // Set total optional items after calculation
        
        // Check if onboarding is complete
        if (overallProgress === 100 && accountData.onboarding_complete === false) {
            setCompletedOnboarding(true);
        } else {
            setCompletedOnboarding(false);
        }
    }

    } catch (error) {
        console.error('Error fetching onboarding record:', error);
        setError('Failed to fetch onboarding record');
    } finally {
        setLoading(false);
    }
};







useEffect(() => {
  if (accountData && accountData.onboarding_complete == false) {
      fetchOnboardingRecord();
  }
}, [accountData]); // Dependency on accountData










  const contextValue = {
    accountData,
    setAuthData,
    resetContext,
    showNewUserSignUp,
    setShowNewUserSignUp,
    setNotificationSettings,
    saveSettings,
    notificationSettings,
    onboardingData,
    onboardingProgress,
    requiredItemsProgress,
    optionalItemsProgress,
    totalOptionalItems,
    completedOnboarding,
    fetchAccountData,
    fetchOnboardingRecord,
    setCompletedOnboarding,
    setSupressDialog,
    supressDialog,
    setAccountData,
    
  };

  return <AccountContext.Provider value={contextValue}>{children}</AccountContext.Provider>;
};

export const useAccountContext = () => {
  return useContext(AccountContext);
};