import React, { useState } from 'react';
import { Rating,Dialog, DialogTitle, DialogContent,DialogContentText, DialogActions, Button,Typography, TextField, FormControl, InputLabel, Select, MenuItem, styled, IconButton, Stack } from '@mui/material';
import reviewIcon from '../../../public/assets/icons/navbar/reviewSmall.png';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from 'src/context/AppContext';
import { PrimaryButton, CancelButton, SecondaryButton } from 'src/components/Buttons/ModalButtons';





const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '22px',
    padding: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    border: 'transparent', // Add white border
    boxShadow: '5px', // Remove black border
  },
}));


const LeaveFeedbackDialog = ({ isOpen, onClose, onSubmit }) => {
  const [feedbackData, setFeedbackData] = useState({
    rating: 0,
    strength: '',
    improvementAreas: '',
    additionalComments: ''
  });
  const [isLoading, setIsLoading] = useState(false);  

  const handleRatingChange = (event, newValue) => {
    setFeedbackData(prevState => ({
      ...prevState,
      rating: newValue
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleReloadComponents , showSnackBar} = useAppContext();

  
  const handleSubmit = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const userId = user.sub;
  
    const sendFeedback = async () => {
      setIsLoading(true); 
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`${apiUrl}/api/feedback/${userId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` // Add this line
          },
          body: JSON.stringify(feedbackData)
        });
  
        const data = await response.json();

        if (data.error) {
          console.error('Failed to send feedback:', data.error);
          showSnackBar('Failed to submit your feedback. Please try again', false);
          handleReloadComponents();
          setIsLoading(false);
          onClose();


        } else {
          showSnackBar('Feedback submitted. Thanks for letting us know your thoughts!', true);
          handleReloadComponents();
          setIsLoading(false);
          onClose();
        }
      } catch (error) {
        console.error('An error occurred:', error);
        showSnackBar('Failed to submit your feedback. Please try again', false);
        handleReloadComponents();
        setIsLoading(false);
        onClose();

      }
    };
  
    sendFeedback();

  };
    






  return (
    <div>
    <StyledDialog open={isOpen} onClose={onClose}>
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 4,
            top: 6,
            color: '#7589A2',
            backgroundColor: 'transparent',
            zIndex: 1,
            borderRadius: '12px',
            width: '35px',
            height: '35px',
            padding: '3px',
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', height: '90px',backgroundColor:'#FFFFFF',pt:4, pb:4.45, color:'#374247'   }}>
        <Stack direction="column" sx={{display:'flex',alignItems: 'center', height: '90px', mr:'auto', mb:1, mt:3, gap:1.5}}>
  <img src={reviewIcon} alt="bug" style={{ width: 'auto', height: '32px', backgroundColor:'#FFFFFF',border: '1.25px solid rgba(173, 216, 230, 0.8)',padding:'4px',boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.09)',borderRadius:'6px', marginRight:'auto' }} />
  <Typography color="textSecondary"  sx={{   fontSize:19.5, fontWeight:600, marginRight:'auto'   
 }}>Tell Us About Your Experience</Typography>
 </Stack>
</DialogTitle>
<DialogContent
          sx={{backgroundColor:'#FFFFFF', width:'545px',minHeight:'315px',mt:1.5 }}>       
      <Typography sx={{ fontSize: '17px', fontWeight:600,pl:'2px',color:'#48768C',pb:'3px'}}>Rate Your Overall Experience </Typography>
        <Rating
        sx={{ fontSize: '32px', fontWeight:600,color:'#68CFBA  ',pb:'5px', }}
          name="rating"
          value={feedbackData.rating}
          onChange={handleRatingChange}
        />
        <TextField
          name="strength"
          label="What about us do you like?"
          multiline
          fullWidth
          margin="normal"
          variant="outlined"
          value={feedbackData.strength}
          onChange={handleChange}
        />
        <TextField
          name="improvementAreas"
          label="What would you like to see improved?"
          multiline
          fullWidth
          margin="normal"
          variant="outlined"
          value={feedbackData.improvementAreas}
          onChange={handleChange}
        />
        <TextField
          name="additionalComments"
          label="Are there any features you would like to see added in the future?"
          multiline
          fullWidth
          margin="normal"
          variant="outlined"
          value={feedbackData.additionalComments}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions
      
      sx={{backgroundColor:'#FFFFFF',width:'94%',mx:2, my:'auto'}}>
      
      <CancelButton 
              sx={
                {
                  mr:'8px',fontSize: '16px',
                  mb:1, 
                  height:'51px',width:'25%', 


            }}
              onClick={onClose}>Cancel</CancelButton>
<PrimaryButton
  sx={{

    width: '100%',
    height: '51px',
    fontSize: '18px',
    mb:1,}}
  onClick={handleSubmit}
  variant="contained"
  disabled={!feedbackData.rating || isLoading} // Disable if rating is empty

>
  {isLoading ? 'Submitting...' : 'Submit'}
</PrimaryButton>
      </DialogActions>
    </StyledDialog>
    </div>

  );
};

export default LeaveFeedbackDialog;
