import React, {useEffect, useState} from 'react';
import { Modal, Typography, Button, Box,Fade, Skeleton, CardMedia } from '@mui/material';
import complete from 'src/public/assets/complete.png';


const NewAccountSuccess = ({ isOpen, onNext }) => {


  useEffect(() => {
    // Clear all items from local storage when component mounts
    localStorage.clear();
  }, []);


  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = complete;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);


  return (
    <Modal open={true}
    slotProps={{
      backdrop: {
        onClick: null, // Disable closing the modal by clicking on the backdrop
      },
    }}
    disableEscapeKeyDown // Disable closing the modal by pressing the escape key
  >
          <Fade in={isOpen} timeout={400}>

<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'35%',
padding:2,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >              
      <Typography gutterBottom
      sx={{
        mt:1,
        color: '#48768C',
        textAlign: 'center',
        fontSize: 21,
        fontWeight: 600,
        mb:1
      }}
    >

      Your Account Has Been Created!
    </Typography>


    {!imageLoaded && (
    <Skeleton
      variant="rectangular"
      sx={{
        height: 'auto',
        maxWidth: '60%', maxHeight:'60%',
        objectFit: 'contain',
        borderRadius: '10px', 
        backgroundColor: '#EEF6F9', // Adjust as needed to match background
        transition: 'opacity 0.3s ease-in-out',
        opacity: imageLoaded ? 0 : 1,
      }}
    />
  )}
    <CardMedia
    component="img"
    image={complete}
    sx={{
      display: "block",
      margin: "auto",
      marginBottom: "20px",
maxWidth: '60%', maxHeight:'60%',
      objectFit: 'contain',
      transition: 'opacity 0.3s ease-in-out',
      opacity: imageLoaded ? 1 : 0,
    }}
    onLoad={() => setImageLoaded(true)}
  />


        <Typography paragraph
                  sx={{
                    color: '#48768C',
                    fontSize: 15,
                    fontWeight: 500,

                    textAlign: 'center',
                    mb: 2,
                  }}
                >
          Head over to the Account Settings section to add more locations and employees or to make any other changes. </Typography>
          <Typography
                    sx={{
                      mt:1,
                      color: '#48768C',
                      fontSize: 14.5,
                      fontWeight: 600,
                      textAlign: 'center',
                      mb: 4,
                    }}
                  >
            
             You can now login using the new username and pin you just created.
        </Typography>
        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
        sx={{
          backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
          padding: 1,
          borderRadius: '15px',
          mb:3,
          width: '85%',
          fontSize: 17,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
          },
          '&:active': {
            backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
          },
        
                 }}  onClick={() => {
              onNext();
              window.location.reload(); // Refresh the page
            }} variant="contained" color="primary">
            Finish Setup
          </Button>
        </Box>
      </Box>
    </Fade>
    </Modal>
  );
};

export default NewAccountSuccess;
