//Multi User Single Location Scheme

import React, { useState, useEffect } from 'react';
import { Modal, Typography, TextField, Button,Box,  Avatar, Fade, Skeleton, CardMedia } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import addUser from 'src/public/assets/addUser.png';
import { useAccountContext } from 'src/context/AccountContext';
import NewUserNotification from '../../../../src/hooks/NewUserNotification'
import RestartSetupDialog from "./RestartSetupDialog";  




// Modal for creating an initial user from new Auth0 user signup
const NewSingleUserFromAuth0 = ({ isOpen, onClose, onSave, auth0User, locationData,
setIsFormValid, newFirstName, setNewFirstName, newLastName, setNewLastName, newUsername, setNewUsername, newPin, setNewPin, confirmNewPin, setConfirmNewPin, pinError, setPinError, loading, setLoading,
isFormValid, handleNewSingleUserFromAuth0  


 }) => {



  const [imageLoaded, setImageLoaded] = useState(false);


  const [showRestartDialog, setShowRestartDialog] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);    


  const handleBackdropClick = (event) => {
    // Check if the click is on the backdrop by comparing target and currentTarget
    if (event.target === event.currentTarget) {
      setConfirmRestart(false)
      setShowRestartDialog(true); // Open RestartSetupDialog
    }
  };

  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };

  useEffect(() => {
    const img = new Image();
    img.src = addUser;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);


  useEffect(() => {
    // Check if all fields are filled and valid
    setIsFormValid(
      newFirstName.trim() !== '' &&
        newLastName.trim() !== '' &&
        newUsername.trim() !== '' &&
        newPin.trim() !== '' &&
        confirmNewPin.trim() !== '' &&
        newPin === confirmNewPin &&
        /^[a-zA-Z]+$/.test(newFirstName) &&
        /^[a-zA-Z]+$/.test(newLastName) &&
        /^\d{4,6}$/.test(newPin)
    );

    // Check if PINs match
    setPinError(confirmNewPin !== '' && newPin !== confirmNewPin ? 'Pins must match' : '');
  }, [newFirstName, newLastName, newUsername, newPin, confirmNewPin]);

  

  const theme = createTheme({
    palette: {
      primary: {
        main: '#48768C', // Set the main color to white
      },
    },
  });


  
  

  return (
    <Modal open={isOpen} 
    onClose={() => {}} // Disable default close behavior
    slotProps={{
      backdrop: {
        onClick: handleBackdropClick, // Disable closing the modal by clicking on the backdrop
      },
    }}
    disableEscapeKeyDown // Disable closing the modal by pressing the escape key
  >
          <Fade in={isOpen} timeout={400}>

<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'28%',
padding:3,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '60px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >

<Typography
          sx={{
            mt:1,
            fontWeight: 600,
            fontSize: 21,
            textAlign: 'center',
            mb: 1,
          }}
        >
          Create Your Account Administrator
        </Typography>


             {!imageLoaded && (
    <Skeleton
      variant="rectangular"
      sx={{
        height: 'auto',
        maxWidth: '55%', maxHeight:'55%',
        objectFit: 'contain',
        borderRadius: '10px', 
        backgroundColor: '#EEF6F9', // Adjust as needed to match background
        transition: 'opacity 0.3s ease-in-out',
        opacity: imageLoaded ? 0 : 1,
      }}
    />
  )}
    <CardMedia
    component="img"
    image={addUser}
    sx={{
      display: "block",
      margin: "auto",
      marginBottom: "20px",
      maxWidth: '55%', maxHeight:'55%',
      objectFit: 'contain',
      transition: 'opacity 0.3s ease-in-out',
      opacity: imageLoaded ? 1 : 0,
    }}
    onLoad={() => setImageLoaded(true)}
  />



        <Typography
          sx={{
            fontSize: 14,
            textAlign: 'center',
            mb: 4,
            fontWeight: 600,
          }}
        >
          An account administrator can make important changes to your account, such as adding or removing users, and managing locations
        </Typography>
       
       
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  width: '100%', mt:1 }}>


        <ThemeProvider theme={theme}>

        <TextField
          label="First Name*"
          variant="outlined"
          size="small"

          fullWidth
          value={newFirstName}
          onChange={(e) => setNewFirstName(e.target.value.replace(/[^a-zA-Z]/g, ''))}
          InputProps={{ style: { color: '#48768C', borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, mr:1.5, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />
                </ThemeProvider>
                <ThemeProvider theme={theme}>


        <TextField
          label="Last Name*"
          variant="outlined"
          size="small"

          fullWidth
          value={newLastName}
          onChange={(e) => setNewLastName(e.target.value.replace(/[^A-Za-z]/ig, ''))}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />      
        </ThemeProvider>
        </Box>               

   
                <ThemeProvider theme={theme}>

        <TextField
          label="Username*"
          variant="outlined"
          size="small"

          fullWidth
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />                        </ThemeProvider>

<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  width: '100%', }}>


                <ThemeProvider theme={theme}>

        <TextField
          label="New PIN"
          size="small"

          variant="outlined"
          type="password"
          fullWidth
          value={newPin}
          onChange={(e) => setNewPin(e.target.value.replace(/\D/g, '').slice(0, 6))}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, mr: 2.5, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          required
        />                     </ThemeProvider>

                <ThemeProvider theme={theme}>

         <TextField
          label="Confirm New PIN"
          size="small"
          variant="outlined"
          type="password"
          fullWidth
          value={confirmNewPin}
          onChange={(e) => setConfirmNewPin(e.target.value.replace(/\D/g, '').slice(0, 6))}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          required
          error={!!pinError}
          helperText={pinError}
        
        />                   </ThemeProvider>
        </Box>
        <Box sx={{ marginTop: 1.5, display: 'flex',  width:'100%',justifyContent: 'center',mb:2}}>
          <Button
                      disabled={!isFormValid || loading}

          sx={{
            backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
            borderRadius: 1.25,
            width: '90%',
            fontSize: '18.5px',
            fontWeight: 600,
    
            '&:hover': {
              backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
            },
            '&:active': {
              backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
            },
          }} onClick={handleNewSingleUserFromAuth0} variant="contained" color="primary">
            {loading ? 'Creating New User...' : 'Create User'} 
          </Button>
        </Box>
        <RestartSetupDialog
        open={showRestartDialog}
        onClose={handleCloseRestartDialog}
        confirmRestart={confirmRestart}
        setConfirmRestart={setConfirmRestart}
/>
      </Box>
      </Fade>
    </Modal>
  );
};

export default NewSingleUserFromAuth0;
