import React from 'react';
import { Box, Button, IconButton, InputAdornment, TextField, Typography, Divider, Paper,Stack } from '@mui/material';
import AddLocationCard from './components/addLocationCard';
import AddUserCard from './components/addUserCard';
import RemovePlan from './components/removePlan';
import RemovePlanMultiUser from './components/removePlanMultiUser';
import AddPlan from './components/addPlan';
import UpdateLocationCard from './components/updateLocation';
import { Remove } from '@mui/icons-material';
import { sub } from 'date-fns';


const Review = ({
    name,
    email,
    phone,
    subUserData,
    handleSaveLocationClick,
    handleSaveNewUserClick,
    handleCreateNewEmployeeClick,
    handleToggle,
    checked,
    left,
    right,
    handleCheckedRight,
    handleCheckedLeft,
    isSaveButtonEnabled,
    isSaveUserButtonEnabled,
    isNewUserSaved,
    setNewUserSaved,
    showNewUserPin,
    confirmNewUserNewPin,
    setFirstName,
    setLastName,
    setUserName,
    setPin,
    handleConfirmNewPinChange,
    handleTogglePinVisibility,
    toggleShowConfirmNewPin,
    setIsAssignEmployeeVisible,
    users,
    isAssignEmployeeVisible,
    isMoveUserVisible,
    setNewUserName,
    isLoading,
    showPin,
    showConfirmNewPin,
    firstName,
    lastName,
    newUserName,
    pin,
    showNewUser,
    showNewUserConfirm,
    handleNewUserConfirmChange,
    handleToggleNewUserConfirmVisibility,
    toggleShowNewUserConfirm,
    handleNewUserPinChange,
    handleToggleNewUserPinVisibility,
    toggleShowNewUserPin,
    isSaveNewUserButtonEnabled,
    handleSaveNewUserConfirmClick,
    handleSaveNewUserPinClick,
    handleSaveNewUser,
    assignedUser,
    assignedLocation,
    userName,
    accountData,
    updatedName
    

}) => { 

  const [loading, setLoading] = React.useState(false);



    return (

        <Box className="review_text_container" >

        <Box
        sx={{
          overflowY: 'auto',
          maxHeight: '350px', // Adjust this height as needed
          backgroundColor: 'transparent',
        }}
      >
                <Typography className="review_text_header">
          Review Your Plan Changes
        </Typography>
        <Stack direction="row" sx={{display:'flex', alignItems:'center', }} spacing={1}>
            {accountData.single_user ? (
            <RemovePlan />  
            ) : (
              <RemovePlanMultiUser />
            )}
            <AddPlan />
            </Stack>
            <Typography className="review_features_header">
          Review Your Added Users & Locations
        </Typography>
        <Box sx={{border:'1px solid rgba(191, 218, 231, 0.9)',  borderRadius:'6px', width:'13%', height:'14%', backgroundColor: 'rgba(191, 218, 231, 0.40)' ,boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)', ml:'2px'}}>

<Typography sx={{fontSize:'12.5px', fontWeight:600, color:'#4A6B75',px:'5px', letterSpacing:'-2%',    
 }}>

    $5/month
</Typography>
        </Box>
 <AddLocationCard
 name={name}
 email={email}
 phone={phone}
 assignedUser={assignedUser}
 assignedLocation={assignedLocation}
 firstName={firstName}
 lastName={lastName}
 userName={userName}
 right={right}
 users={users}  



 
 />
<UpdateLocationCard
updatedName={updatedName}
newUserName={newUserName}
users={users}
accountData={accountData}
left={left}
/> 

        {!assignedUser && (
          <>
           <Box sx={{border:'1px solid rgba(191, 218, 231, 0.9)',  borderRadius:'6px', width:'13%', height:'14%', backgroundColor: 'rgba(191, 218, 231, 0.40)' ,boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)', ml:'2px', mt:2}}>

<Typography sx={{fontSize:'12.5px', fontWeight:600, color:'#4A6B75',px:'5px', letterSpacing:'-2%',    
 }}>

    $0/month
</Typography>
        </Box>
    <AddUserCard 
        title="Added New User"
        name={name}
        firstName={firstName}
        lastName={lastName}
        userName={userName}



    />
              </>

)}

{accountData.single_user && (
    <AddUserCard
    title="Updated Exsisting User"
    newUserName={newUserName}
    firstName={subUserData.first_name}
    lastName={subUserData.last_name}  
    name={updatedName}
    

    />
)}


    </Box>
      </Box>

    );
}

export default Review;