import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  CardMedia,
  Skeleton,
  Fade, 
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import location from 'src/public/assets/location.png';
import isUrl from 'validator/lib/isURL'; // Import the isURL function from the validator library
import RestartSetupDialog from "./RestartSetupDialog";  



const apiUrl = process.env.REACT_APP_API_URL;

const AddFirstLocationModal = ({ isOpen, onClose, onAddLocation, onNext }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showRestartDialog, setShowRestartDialog] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);    


  const handleBackdropClick = (event) => {
    // Check if the click is on the backdrop by comparing target and currentTarget
    if (event.target === event.currentTarget) {
      setConfirmRestart(false)
      setShowRestartDialog(true); // Open RestartSetupDialog
    }
  };

  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1C2A40',
      },
    },
  });

  useEffect(() => {
    const validateForm = () => {
      const isValidName = name.trim() !== '';
      const isValidEmail = validateEmail(email);
      const isValidPhone = validatePhoneNumber(phone);
      setIsFormValid(isValidName && isValidEmail && isValidPhone);
    };

    validateForm();
  }, [name, email, phone]);

  const validateEmail = (email) => {
    // Use a simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    // Use a regex to match the phone number format (xxx) xxx-xxxx
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };
  

  const formatPhoneNumber = (value) => {
    // Filter non-digit characters from the input value
    const cleaned = value.replace(/\D/g, '');
    // Apply formatting based on the number of digits
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      // Format as (xxx) xxx-xxxx
      return `(${match[1]})${match[2] ? ' ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    // Filter non-digit characters and limit length to 10 digits
    let value = e.target.value.replace(/\D/g, '').slice(0, 10);
    // Format the input value as the user types
    setPhone(formatPhoneNumber(value));
  };

  const handleAddLocationClick = async () => {
    setLoading(true);
    const locationData = {
      name,
      email,
      phone,
      auth0UserId: user.sub,
    };

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/locations/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(locationData),
      });
  
      if (!response.ok) {
        setLoading(false);
        throw new Error('Error adding location');
      }
  
      const data = await response.json();
  
      // Only call onAddLocation if it exists
      if (onAddLocation) {

        onAddLocation(data); // Pass the newly added location information to the parent
      }
  
      onNext(); // Move to the next step after adding the location
      setLoading(false);
    } catch (error) {
      console.error('Error adding location:', error);
      setLoading(false);
    }
  };


  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = location;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);


  return (
    <Modal
      open={isOpen}
      onClose={null}
      slotProps={{
        backdrop: {
          onClick: handleBackdropClick,
        },
      }}
      disableEscapeKeyDown
    >
            <Fade in={isOpen} timeout={400}>

      <Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'30%',
padding:2,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >
        <Typography
          sx={{
            color: '#48768C',
            textAlign: 'center',
            fontSize: 21,
            fontWeight: 600,
            mb: -2,
          }}
        >
          Add Your First Location
        </Typography>

        {!imageLoaded && (
    <Skeleton
      variant="rectangular"
      sx={{
        height: 'auto',
        maxWidth: "50%",
        maxWidth: '60%', maxHeight: '60%',
        objectFit: 'contain',
        borderRadius: '10px', 
        backgroundColor: '#EEF6F9', // Adjust as needed to match background
        transition: 'opacity 0.3s ease-in-out',
        opacity: imageLoaded ? 0 : 1,
      }}
    />
  )}
    <CardMedia
    component="img"
    image={location}
    sx={{
      display: "block",
      margin: "auto",
      marginBottom: "20px",
maxWidth: '60%', maxHeight: '60%',
      objectFit: 'contain',
      transition: 'opacity 0.3s ease-in-out',
      opacity: imageLoaded ? 1 : 0,
    }}
    onLoad={() => setImageLoaded(true)}
  />



        <Typography
          sx={{
            mt: 1,
            color: '#48768C',
            fontSize: 14,
            fontWeight: 600,
            textAlign: 'center',
            mb: 3,
          }}
        >
          Important stuff like your company's website, email, and location information are automatically added to
          your notifications.
        </Typography>

        <ThemeProvider theme={theme}>
          <TextField
            label="Location Nickname (Example: Main St.)"
            variant="outlined"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
            InputLabelProps={{ style: { color: '#48768C' } }}
            sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <TextField
            label="Location Email"
            variant="outlined"
            size="small"

            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
            InputLabelProps={{ style: { color: '#48768C' } }}
            sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
            error={email.trim() !== '' && !validateEmail(email)}
            helperText={email.trim() !== '' && !validateEmail(email) ? 'Invalid email' : ''}
          />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <TextField
            label="Location Phone Number"
            variant="outlined"
            size="small"

            mb={2}
            value={phone}
            onChange={handlePhoneChange}
            fullWidth
            InputProps={{ style: { color: '#48768C', borderRadius:'8px' } }}
            InputLabelProps={{ style: { color: '#48768C' } }}
            sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
            error={phone.trim() !== '' && !validatePhoneNumber(phone)}
            helperText={phone.trim() !== '' && !validatePhoneNumber(phone) ? 'Invalid phone number' : ''}
          />
        </ThemeProvider>
        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddLocationClick}
          disabled={!isFormValid || loading}
        sx={{
          backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
          padding: 1,
          borderRadius: '15px',
          mb:3,
          mt:1,
          width: '95%',
          fontSize: 17,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
          },
          '&:active': {
            backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
          },
        }}>
         {loading ? 'Adding Location...' : 'Continue'} 
        </Button>
        </Box>
        <RestartSetupDialog
        open={showRestartDialog}
        onClose={handleCloseRestartDialog}
        confirmRestart={confirmRestart}
        setConfirmRestart={setConfirmRestart}
/>
      </Box>
      </Fade>
    </Modal>
  );
};

export default AddFirstLocationModal;
