import React, {useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Box, IconButton, } from '@mui/material';
import { useAuth } from '../../../../context/AuthContext.js'; // Assuming subUserData is coming from this context
import MultiLocationUpgradeStepper from './MultiUserUpgradeStepper.js';
import { useAccountContext } from 'src/context/AccountContext';
import CloseIcon from '@mui/icons-material/Close';  
import { useNavigate } from 'react-router-dom';






const MultiUserUpgrade = ({ multiUserUpgradeDialogOpen, handleMultiUserUpgradeDialogClose, users, isDialogClosing }) => {
const { accountData } = useAccountContext();  

const {subUserData, username, logout: customLogout, resetContext} = useAuth();
const [isLoading, setIsLoading] = useState(false); // Loading state
const navigate = useNavigate(); // Initialize the hook
const [upgradeComplete, setUpgradeComplete] = useState(false);




const handleLogout = () => {
  localStorage.clear();
  resetContext(); 
  navigate('/'); 
  window.location.reload();
};





const handleConditionalClose = () => {
  // If upgrade is complete, run handleLogout; if not loading, close the dialog
  if (upgradeComplete) {
    handleLogout();
  } else if (!isLoading) {
    handleMultiUserUpgradeDialogClose();
  }
};






  return (
    <>
      <Dialog open={multiUserUpgradeDialogOpen} onClose={handleConditionalClose} PaperProps={{ sx: {  borderRadius: "20px", height:'auto',overflowX:'hidden'  } }}>
      <DialogContent sx={{ justifyContent: 'center', textAlign: 'center', display: 'flex', width: '100%', alignItems: 'center', backgroundColor: 'rgba(191, 218, 231, 0.2)', }}>
        <IconButton
        aria-label="close"
        onClick={handleConditionalClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
                {subUserData?.is_admin ? (
  <MultiLocationUpgradeStepper
  users={users}
  subUserData={subUserData}
  isDialogClosing={isDialogClosing} 
  setUpgradeComplete={setUpgradeComplete}
  setIsLoading={setIsLoading}
  isLoading={isLoading}
  />
          ) : (
            <Typography sx={{ color: '#48768C', fontSize: 15, fontWeight: 600, textAlign: 'center' }}>
              Only account administrators can handle account upgrades. Please contact your administrator to add more locations.
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{backgroundColor: 'rgba(191, 218, 231, 0.2)',}}>

        </DialogActions>
      </Dialog>

    
    </>
  );
};

export default MultiUserUpgrade;