import React, {useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Icon as Iconify} from '@iconify/react';
import {sentenceCase} from 'change-case';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Checkbox,
	IconButton,
	InputAdornment,
	Link,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
	Grid,
	Avatar,
} from '@mui/material';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {styled} from '@mui/material/styles';

import {withAuthenticationRequired} from '@auth0/auth0-react';
import {visuallyHidden} from '@mui/utils';
import {useAuth0} from '@auth0/auth0-react';
import CustomerEditModal from '../components/modal/CustomerEditModal';
import LinearProgress from '@mui/material/LinearProgress';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import {useAccountContext} from '../context/AccountContext'; // Import AppProvider from AccountContext
import CustomerDetailsDialog from 'src/components/modal/CustomerDetailsDialog';
import {useAppContext} from '../context/AppContext';
import  fetchGlobalNotifications  from '../utils/notificationUtils'; 
import SendIcon from "../public/assets/icons/navbar/send.png";
import EditIcon from "../public/assets/icons/icons/editLight.png";
import NewNotification from 'src/components/Custom-Notifications/NewNotification';
import { set } from 'lodash';
import {useCustomersContext} from '../context/CustomersContext'; // Import the useCustomersContext hook	
import DataLoadingAlert from 'src/components/alerts/components/DataLoadingAlert';





// Check if single_location is true in accountData

  
  

const CustomersPage = () => {
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [filterName, setFilterName] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [editedCustomer, setEditedCustomer] = useState(null);
	const apiUrl = process.env.REACT_APP_API_URL;
	const {user, isAuthenticated, getAccessTokenSilently} = useAuth0();
	const userSub = user?.sub; // Access user.sub safely, may be null or undefined
	const {accountData} = useAccountContext(); // Use the accountData from the context
	const isSingleUser = accountData?.single_user; // Check if single_user is true in accountData
	const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
	const [openDialog, setOpenDialog] = React.useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [openNewMultiModal, setOpenNewMultiModal] = useState(false);
	const {reloadComponents, handleReloadComponents, showSnackBar} = useAppContext();
	const {customers, totalCustomers, lastMessages, handleUpdateCustomer, handleDeleteCustomer,} = useCustomersContext(); // Use the customers and totalCustomers from the context
	const [isLoading, setIsLoading] = useState(false);
	const [notificationHistory, setNotificationHistory] = useState([]);



	



	




	const columns = [

		{
			id: 'name',
			label: 'Customers Name',

		},
		{
			id: 'phone_number',
			label: 'Phone Number',

		},
		{
			id: 'last_message_sent_date',
			label: 'Last Notification Sent',

		},

		...(isSingleLocation
			? []
			: [
					{
						id: 'last_status_update_sent',
						label: 'Location',
					},
				]),
		{
			id: 'date_created',
			label: 'Date Created',
		},
		{
			id: 'actions',
			label: 'Actions',
		},

	];

	function EnhancedTableHead(props) {
		const {onSelectAllClick, numSelected, rowCount} = props;
		const [openModal, setOpenModal] = useState(false);
		const [editedCustomer, setEditedCustomer] = useState(null);
		const apiUrl = process.env.REACT_APP_API_URL;
		const isSingleUser = accountData?.single_user;

		useEffect(() => {
			document.body.style.overflow = 'hidden'; // Disable scrollbar
			return () => {
				document.body.style.overflow = ''; // Re-enable scrollbar on component unmount
			};
		}, []);

		return (
			<TableHead

			>
		
				<TableRow style={{height: '0px',}}>
					{columns.map((column) => (
						<TableCell
							key={column.id}
							sx={{
								backgroundColor: '#FFFFFF',
																color: '#48768C',
								fontWeight: 600,
								fontSize: 13.5,								borderBottom: '1px solid rgba(173, 216, 230, 0.2)', 
								pt:1, 
								pb:1,
								pl:8,
								pr:2
								

							}}>
							{column.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}

	EnhancedTableHead.propTypes = {
		numSelected: PropTypes.number.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	useEffect(() => {
		// Access userData and subUserData wherever you need in your component
	}, [accountData]);

	useEffect(() => {
		document.body.style.overflow = 'hidden'; // Disable scrollbar
		return () => {
			document.body.style.overflow = ''; // Re-enable scrollbar on component unmount
		};
	}, []);

	const fetchNotificationHistory = async (customer) => {
	  
		try {
		  if (!customer) return; // Exit early if customer is null
	  
		  const apiUrl = process.env.REACT_APP_API_URL;
		  const accessToken = await getAccessTokenSilently();
	  
		  // Fetch notification history for the customer using their phone number and userSub
		  const response = await fetch(
			`${apiUrl}/notifications/${userSub}/${customer.phone_number}`,
			{
			  headers: {
				Authorization: `Bearer ${accessToken}`,
			  },
			}
		  );
	  
		  if (!response.ok) {
			throw new Error('Failed to fetch notification history');
		  }
	  
		  const data = await response.json();
		  return data; // Return data for further use
		} catch (error) {
		  console.error('Error fetching notification history:', error);
		  throw error; // Re-throw error for further handling
		}
	  };
	  
	  const handleRowClick = async (customer) => {
		setIsLoading(true); // Start loading state
	  
		try {

		  // Fetch the data
		  const data = await fetchNotificationHistory(customer);
	  
		  if (!data || data.length === 0) {
			return; // Exit if no data
		  }
	  
		  const dataWithDetails = data.map((notification) => ({
			...notification,
			phone_number: customer.phone_number,
			customer_name: `${customer.first_name} ${customer.last_name}`, // Concatenate first_name and last_name
		  }));
	  
		  setNotificationHistory(dataWithDetails); // Update state with modified data
		  setSelectedRow(customer); // Set selected row
		  setOpenDialog(true); // Open dialog
		  setOpenModal(false); // Ensure modal is closed when dialog opens
		} catch (error) {
		  console.error('Error loading data:', error);
		} finally {
		  setIsLoading(false); // End loading state
		}
	  };



	  


	const handleOpenModal = (customer) => {
		setSelectedRow(customer);
		setOpenModal(true);
		setOpenDialog(false); // Ensure dialog is closed when modal opens
	};

	const handleOpenNewMultiModal = (customer) => {
		setSelectedRow(customer);
		setOpenNewMultiModal(true);
	};

	const handleCloseNewMultiModal = () => {
		setOpenNewMultiModal(false);
		setSelectedRow(null);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};






	



		  
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;

	const filteredCustomers = customers.filter((customer) => {
		const name = `${customer.first_name} ${customer.last_name}`;
		return name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;
	});

	

	const formatDate = (date) => {
		const options = {
			year: '2-digit',
			month: '2-digit',
			day: '2-digit',
		};

		const formattedDate = date.toLocaleString('en-US', options);
		const [datePart] = formattedDate.split(','); // Split date and time
		return `${datePart}`; // Combine date and time with a space
	};

	const formatDateCreated = (date) => {
		if (!date) return '';

		const options = {month: 'numeric', day: 'numeric', year: '2-digit'};
		const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
			date,
		);

		return formattedDate;
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden'; // Disable scrollbar
		return () => {
			document.body.style.overflow = ''; // Re-enable scrollbar on component unmount
		};
	}, []);

	const isCustomerNotFound = filteredCustomers.length === 0;

	const isDeleteButtonVisible = selected.length > 0;

	const statusColors = {
		'Ready for Pickup': '#2E7D32',
		'Parts Arrived': '#0277BD',
		'Action Needed': '#F57F17',
		'Pending Approval': '#C62828',
	};

	const getInitials = (firstName, lastName) => {
		const firstInitial = firstName ? firstName.charAt(0) : '';
		const lastInitial = lastName ? lastName.charAt(0) : '';
		return `${firstInitial}${lastInitial}`.toUpperCase();
	};



	const location_name = customers.location_name;

	return (
		<Box sx={{ width: '100%',  px:'4px', borderRadius: '10px', mt:-1,
	}}>
		<Box
				sx={{
					width: '100%',
					mt: .5,
					borderRadius: '10px',
					overflow: 'auto',
					backgroundColor: '#FFFFFF',
          boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)',

          
				}}>
  <Typography
    sx={{
      pt: 1.5,
      color: '#48768C',
      fontWeight: 700,
      fontSize: 17,
      backgroundColor: 'rgba(255, 255, 255, 1.9)',
      borderBottom: 'none',
      pl: '18px',
      mb:'2px'
    }}
  >
Your Customers  </Typography>
  <Typography
    sx={{
      color: '#8BA9B7',
      mb:'5px', 
      fontSize: 14.5,
      backgroundColor: 'rgba(255, 255, 255, 1.9)',
      pl: '18px',
      mb:1,
	  fontWeight:600
      
    }}
  >
								{totalCustomers} Saved Contacts
  </Typography>
  

			<TableContainer
				component={Paper}
				sx={{
					display:'flex',
					height: '100%',
				color: '#48768C', 
					borderRadius:'12px',         border: '1.25px solid rgba(173, 216, 230, 0.5)',
					flexDirection: 'column',
				}}>
					

				<Table sx={{pl:2,}}>
					<EnhancedTableHead

						numSelected={selected.length}
						rowCount={customers.length}
					/>
					<TableBody sx={{					minHeight: '80vh',
}}>
						{filteredCustomers
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((customer) => {
								const {phone_number, first_name, last_name, date_created, location_name} =
									customer;
								const selectedCustomer = selected.indexOf(phone_number) !== -1;

								// Format the phone_number to match the format in lastMessages
								const formattedPhoneNumber = phone_number.replace(/\D/g, '');
								const lastMessage = lastMessages[formattedPhoneNumber];

				

								return (
									<TableRow
										key={phone_number}
										tabIndex={-1}
										selected={selectedCustomer}
										sx={{height: '10px', 											cursor: !isLoading ? "pointer" : "default",
										 '&:hover': {
											backgroundColor: 'rgba(173, 216, 230, 0.1)',}}} // Set the cursor style to 'pointer'
										onClick={() => handleRowClick(customer)} // Add this line
										disabled={isLoading}

									>
	
							<TableCell
							component="th"
							scope="row"
							sx={{
								backgroundColor: 'rgba(173, 216, 230, 0.2)',
								borderBottom: 'none',
							}}
							>
							<Box
								sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-start", // Adjusted alignment to flex-start
								}}
							>
								
								<Avatar
								sx={{
									fontSize: 16,        width: 40,
									height: 40,
									background: 'radial-gradient(circle, #EEF6F9, #FFFFFF)',
									border: '2px solid rgba(173, 216, 230, 0.9)', 
									borderRadius: '20px',
									padding: '4px',
									boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
									mr: '15px',
									color: '#48768C',
									fontWeight: 600,

								}}
								>
								<Typography
									sx={{
										fontSize: 16,          letterSpacing: '0.03em',
									fontWeight: 600,
									textAlign: 'center',
									

									}}
								>
									{getInitials(first_name, last_name)}
								</Typography>
								</Avatar>
								<Typography
								sx={{
									fontSize: 14,        letterSpacing: '0.02em',
									fontWeight: 600,
									color: '#48768C',

								}}
								noWrap
								>
								{first_name} {last_name}
								</Typography>
							</Box>
							</TableCell>
							<TableCell
							sx={{
								pl:7.75,

								fontSize: 14,   letterSpacing: '0.02em',
								backgroundColor: 'rgba(173, 216, 230, 0.2)',
								color: '#48768C',
								borderBottom: 'none',
							}}
							>
							{phone_number}
							</TableCell>
							<TableCell
							sx={{
								fontSize: 14,    letterSpacing: '0.02em',
								backgroundColor: 'rgba(173, 216, 230, 0.2)',
								color: '#48768C',
								borderBottom: 'none',
							pl:12
							}}
							>
							{lastMessage ? formatDate(new Date(lastMessage)) : ''}
							</TableCell>
							{!accountData.single_location && (
							<TableCell
								sx={{
								fontSize: 14,
								letterSpacing: '0.02em',
								backgroundColor: 'rgba(173, 216, 230, 0.2)',
								color: '#48768C',
								borderBottom: 'none',
								pl:8,


								}}
							>
								{location_name}
							</TableCell>
							)}
							<TableCell
							sx={{
								fontSize: 14,
								letterSpacing: '0.02em',
								backgroundColor: 'rgba(173, 216, 230, 0.2)',
								color: '#48768C',
								borderBottom: 'none',
								pl:9

							}}
							>
							{date_created ? formatDateCreated(new Date(date_created)) : ''}
							</TableCell>
							<TableCell
							sx={{
								backgroundColor: 'rgba(173, 216, 230, 0.2)',
								color: '#48768C',
								borderBottom: 'none',
							}}
							>
							<Box
								sx={{
									ml: -1,
									flexDirection: 'row',
									justifyContent: 'space-around',
							
								display: 'flex',
								}}
							>


             <Paper elevation={3}
									onClick={(event) => {
									event.stopPropagation();
									handleOpenNewMultiModal(customer);
									}}
									sx={{                 color: "#48768C",
						padding:0, 
									cursor: 'pointer', // Added a cursor for better interactivity
									transition: 'background-color 0.3s ease',
									'&:hover': {
										backgroundColor: '#D7E3E8',
										color: '#F0F0F0',
									  },
									}}  

    >
								
								<i  style={{color:'#86A4B2',fontSize:'18px',border: '.25px solid #90A4AE',padding:'6px',borderRadius:'8px',


 
								   	}} className="fa-regular fa-paper-plane"></i>  
								</Paper>  
	  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>

								<Paper elevation={3}
								onClick={(event) => {
									event.stopPropagation();
									handleOpenModal(customer);
								}}
								disabled={isLoading}
								sx={{   marginLeft:'35px',              color: "#48768C", 
								padding:0, 
											cursor: 'pointer', // Added a cursor for better interactivity
											transition: 'background-color 0.3s ease',
											'&:hover': {
												backgroundColor: '#D7E3E8',
												color: '#F0F0F0',
											  },
											
											}}
		
			>
								
								<i  style={{color:'#86A4B2',fontSize:'18px',border: '.25px solid #90A4AE',padding:'6px',borderRadius:'8px',

 
								   	 }} className="fa-solid fa-pencil"></i>


								</Paper>  
	  </Box>
				
							</Box>
							</TableCell>
									</TableRow>
								);
							})}
						{emptyRows > 0 && (
							<TableRow style={{height: 53 * emptyRows}}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
	
					{customers.length === 0 && (
						<TableBody>	
						<TableRow>
							<TableCell
								colSpan={8}
								sx={{
									width: '100%',
									textAlign: 'center',
									color: '#48768C',
									backgroundColor: 'rgba(173, 216, 230, 0.2)',
								}}>
								<Typography
									variant="body1"
									color="#48768C"
									align="center"
									sx={{

										fontSize: '.9rem',
										
										width: '100%',
										padding: '10px',
										borderBottomLeftRadius: '20px',
										borderBottomRightRadius: '20px',
									}}>
									Save customers to your contact list to send notifications even
									faster!
								</Typography>
							</TableCell>
						</TableRow>
						</TableBody>	
					)}
				</Table>

				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={customers.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page"
		
					SelectProps={{
						inputProps: {
							'aria-label': 'rows per page',
						},
						native: true,
					}}
					sx={{color:'#48768C',backgroundColor: 'rgba(173, 216, 230, 0.4)', }}
					/>
			</TableContainer>

			{isLoading && (
        <DataLoadingAlert 
          title="Loading..." 
          alertText="Loading notification history. Please wait." 
        />
      )}
			
			</Box>
			<CustomerDetailsDialog
  open={openDialog}
  onClose={handleCloseDialog}
  customer={selectedRow ? selectedRow : {}} // Pass selectedRow as customer if it exists, otherwise pass an empty object
  userSub={userSub}
  phone_number={selectedRow ? selectedRow.phone_number : ''}
  notificationHistory={notificationHistory}

/>

			<NewNotification
				open={openNewMultiModal}
				
				onClose={handleCloseNewMultiModal}
				customer={selectedRow}
				userSub={userSub}
				phone_number={selectedRow ? selectedRow.phone_number : ''} // Assuming phone_number is a property of selectedRow
			/>
    <Box sx={{ position: 'absolute',top:99,  right: '37px', padding: '5px', pl:'10px',mt:0 , borderRadius: '26px',}}>
				<TextField
					variant="outlined"
					label="Search Customer"
					value={filterName}
					onChange={handleFilterByName}
					sx={{
						width: '100%',
						color:'#2A3D4F',
						'& .MuiOutlinedInput-root': {
						  height: '45px',
						  fontSize: '14px',
				
						  '& fieldset': {
							borderColor: '#90A4AE', // Updated outline color
							fontSize: '14px',
				
						  },
						  '&:hover fieldset': {
							borderColor: '#90A4AE', // Updated outline color on hover
							fontSize: '14px',
				
						  },
						},
					  }}
					  InputLabelProps={{
						style: { color: '#2A3D4F',          fontSize: '14px',
					  }, // Updated label color
					  }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Iconify icon="bi:search" />
							</InputAdornment>
						),
					}}
				/>
				

				<CustomerEditModal
					open={openModal}
					onClose={handleCloseModal}
					customer={selectedRow}
					onUpdate={handleUpdateCustomer}
					loading={false}
				/>

				{isDeleteButtonVisible && (
					<Box sx={{mt: 3}}>
						<Button
							variant="contained"
							color="error"
							sx={{mr: 2}}>
							Delete
						</Button>
					</Box>
				)}
			</Box>
		</Box>
	);
};

const ProtectedMessagesFeed = withAuthenticationRequired(CustomersPage, {
	// Custom options, if needed
	// For example, onRedirecting, returnTo, etc.
});

export default CustomersPage;
