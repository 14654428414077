const EditLocationSubmit = async (getAccessTokenSilently, name, newLocationName, email, phone, businessHours, userId, locationId, right ) => {
  // Create the location data object
  const locationData = {
    name,
    newLocationName,
    email,  
    phone,
    businessHours,
    userId,
    locationId,
    users: right.map(user => ({
      user_id: user.isNewUser ? null : user.user_id,
      isNewUser: user.isNewUser,
      ...user,
    })),
  };

  try {
    const apiUrl = process.env.REACT_APP_API_URL;  

    // Get the access token silently
    const accessToken = await getAccessTokenSilently();

    // Send POST request with the access token in the Authorization header
    const response = await fetch(`${apiUrl}/manage/locations/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Include the access token
      },
      body: JSON.stringify(locationData),
    });

    // Check for response status
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.locationNameTaken) {
        throw new Error('Location name is already taken.');
      } else {
        throw new Error(`Error creating location: ${errorData.error || response.statusText}`);
      }
    }

    // Parse and return the response JSON
    const result = await response.json();
    return result; // Resolve with result on success
  } catch (error) {
    console.error('Error creating location:', error);
    throw error; // Reject with error on failure
  }
};

// Export the function as the default export
export default EditLocationSubmit;



