// src/components/StepOne.js
import React from 'react';
import { Box, Typography, TextField, Button, Paper, InputAdornment } from '@mui/material';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

const StepThree = ({
  updatedName,
  updatedNameTouched,
  handleUpdatedNameChange,
  email,
  emailTouched,
  handleEmailChange,
  phone,
  phoneTouched,
  handlePhoneChange,
  validateEmail,
  validatePhoneNumber,
  isFormValid,
  subUserData,
  assignedLocation,
  locationNameTaken
}) => {






  
  return (
    <Box sx={{    backgroundColor: '#F2F9FF',
      border: '1.25px solid rgba(173, 216, 230, 0.5)',
      borderRadius: '14px', padding: 2, mt: 2 }}>
              <Typography sx={{ color: '#48768C', mb: 1.5, fontWeight: 600, lineHeight: '-2%', letterSpacing: '-2%' }}>
        Create a Location Name For Your Exsisiting Location
      </Typography>
      <Typography sx={{ color: '#48768C', mb: 2.5, fontWeight: 600, lineHeight: '-2%', letterSpacing: '-2%', fontSize: '13px' }}>
        Your location name is what will be displayed inside your account to help you identify your locations. 
      </Typography>
      <TextField
   error={(!updatedName.trim() && updatedNameTouched) || locationNameTaken}
   helperText={
     locationNameTaken
       ? 'Location name is already taken'
       : (!updatedName.trim() && updatedNameTouched && 'Please enter a valid name')
   }
        placeholder="Location Name"
        variant="outlined"
        size="small"

        fullWidth
        value={updatedName}
        onChange={handleUpdatedNameChange}
        sx={{ 
          color: '#48768C', 
          pb: 2, 
          '& input': { 
            width: '100%', 
            textAlign: 'left', 
            color: '#48768C' 
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Paper
                elevation={1}
                sx={{
                  display: 'flex',
                  borderRadius: '10px',
                  padding: '2px',
                  border: '1.5px solid rgba(173, 216, 230, 0.9)',
                  backgroundColor: "#E3F2FD",
                  color: "#48768C"
                }}
              >
                <DriveFileRenameOutlineOutlinedIcon
                  sx={{
                    padding: '2px',
                    backgroundColor: "#E3F2FD",
                    color: "#48768C",
                    fontSize: '20px'
                  }}
                />
              </Paper>
            </InputAdornment>
          )
        }}
      />
      <TextField
        error={!validateEmail(email) && emailTouched}
        helperText={!validateEmail(email) && emailTouched && 'Please enter a valid email'}
        placeholder="Location Email"
        variant="outlined"
        size="small"
          disabled
        fullWidth
        value={email}   
        onChange={handleEmailChange}
        sx={{ 
          color: 'textSecondary', 
          pb: 2, 
          '& input': { 
            width: '100%', 
            textAlign: 'left', 
            color: 'textSecondary' 
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Paper
                elevation={1}
                sx={{
                  display: 'flex',
                  borderRadius: '10px',
                  padding: '2px',
                  border: '1.5px solid rgba(173, 216, 230, 0.9)',
                  backgroundColor: "#E3F2FD",
                  color: "#48768C"
                }}
              >
                <AlternateEmailOutlinedIcon
                  sx={{
                    padding: '2px',
                    backgroundColor: "#E3F2FD",
                    color: "#48768C",
                    fontSize: '20px'
                  }}
                />
              </Paper>
            </InputAdornment>
          )
        }}
      />
      <TextField
        error={!validatePhoneNumber(phone) && phoneTouched}
        helperText={!validatePhoneNumber(phone) && phoneTouched && 'Please enter a valid phone number'}
        placeholder="Location Phone"
        variant="outlined"
        size="small"
        fullWidth
        disabled
        value={phone}   
        onChange={handlePhoneChange}
        sx={{ 
          color: 'textSecondary', 
          pb: 2, 
          '& input': { 
            width: '100%', 
            textAlign: 'left', 
            color: 'textSecondary' 
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Paper
                elevation={1}
                sx={{
                  display: 'flex',
                  borderRadius: '10px',
                  padding: '2px',
                  border: '1.5px solid rgba(173, 216, 230, 0.9)',
                  backgroundColor: "#E3F2FD",
                  color: "#48768C"
                }}
              >
                <LocalPhoneOutlinedIcon
                  sx={{
                    padding: '2px',
                    backgroundColor: "#E3F2FD",
                    color: "#48768C",
                    fontSize: '20px'
                  }}
                />
              </Paper>
            </InputAdornment>
          )
        }}
      />

    </Box>
  );
};

export default StepThree;