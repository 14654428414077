import React, {useEffect, useState} from 'react';
import {Card, Typography, Divider, Button, Box, Badge, Fade, InputField, Input, TextField, Stack, Checkbox, Select, InputLabel, FormControl, MenuItem, } from '@mui/material';
import {minHeight, styled} from '@mui/system';




const BillingInfoContainer = styled(Box)({
	width: '100%',
	borderRadius: '10px',
	background: 'white',
	overflow: 'auto',
	paddingLeft:'1.5%',
	paddingBottom:'1.5%',
	paddingRight:'1.5%',
	paddingTop:'1.25%',
});

const BillingInfoTextField = styled(TextField)(({ theme, error, isFocused }) => ({
    input: {
      color: error ? '#B22222' : '#48768C',
      fontWeight: 500,
      fontSize: '15.5px',
      padding: 0,
      alignItems: 'center',
      backgroundColor: '#F2F9FF',
      fontFamily: 'Source Sans Pro, sans-serif',
      textAlign: 'left',
      paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '10px',
        height: 'auto',


        '&::placeholder': {
            color: '#9E9E9E', // Adjust placeholder color if needed
          },
        },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '4px',
        borderColor: error ? '#B22222' : '#48768C',
      },
      '&:hover fieldset': {
        borderColor: error ? '#B22222' : '#365D6C',
      },
      '&.Mui-focused fieldset': {
        borderColor: error ? '#8B0000' : '#1E4D5F',
      },
    },
  }));







const BillingInfoForm = ({sameAddress, 
  handleBlur,
  handleCheckboxChange, 
  values, 
  handleChange, 
  errors, 
  billingAddress,
  subUserData,
  setSameAddress,
  setBillingAddress,
  handleStateChange,
  states,
  selectedState,
  handleKeyDown,
  handleUpdateAddressSubmit,
  isLoading,
  buttonDisabled,
  showErrorMessage,
  setButtonDisabled,
  setShowErrorMessage,
  dataChanged,
  checked
}) => {





      
     

      const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: 4,
        width: 15,
        height: 15,
        border: '.5px solid #48768C', // Default border color
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 1,
        },
        'input:hover ~ &': {
          backgroundColor: '#48768C45',
     
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
         
        },
      
      }));
      
      const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#48768CBA',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&::before': {
          display: 'block',
          width: 15,
          height: 15,
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#48768CED',
        },
      });




      useEffect(() => {
        if (showErrorMessage) {
          const timer = setTimeout(() => {
            setShowErrorMessage(false); // Hide the message after 5 seconds
          }, 5000);
    
          // Cleanup the timer when the component unmounts or `showErrorMessage` changes
          return () => clearTimeout(timer);
        }
      }, [showErrorMessage]);






      function BpCheckbox(props) {
        return (
          <Checkbox
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
            disableRipple
            color="default"
            checked={checked}
            onChange={handleCheckboxChange}
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'billing address' }}
            {...props}
          />
        );
      }







    return (
      <BillingInfoContainer>
      <Box sx={{ width: '100%', pb: 0.5 }}>
        <Typography style={{ fontWeight: 'bold', color: '#48768C', fontSize: '17px' }}>
          Billing Information
        </Typography>
      </Box>

      <Stack direction="row" sx={{ width: '100%', ml: -1.15, textAlign: 'left', alignItems: 'center', justifyContent: 'flex-start' }}>
        <BpCheckbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: '#48768C' } }} />
        <Typography style={{ color: '#48768C', fontSize: '15.5px' }}>Use existing billing address</Typography>
      </Stack>
      <Divider style={{ width: '100%' }} />

      <Stack spacing={1.5} sx={{ width: '100%', mt: 2 }}>
        {/* First Name and Last Name */}
        <Stack direction="row" spacing={1.5} sx={{ width: '100%' }}>
          <BillingInfoTextField
            placeholder="First Name"
            value={values.firstName || ''}
            onChange={(e) => handleChange('firstName', e.target.value)}
            onBlur={() => handleBlur('firstName')}
            error={errors.firstName}
            fullWidth
            variant="outlined"
            size="small"
          />
          <BillingInfoTextField
            placeholder="Last Name"
            value={values.lastName || ''}
            onChange={(e) => handleChange('lastName', e.target.value)}
            onBlur={() => handleBlur('lastName')}
            error={errors.lastName}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Stack>
        {/* Email */}
         <BillingInfoTextField
          placeholder="Email"
          value={values.email || ''}
          onChange={(e) => handleChange('email', e.target.value)}
          onBlur={() => handleBlur('email')}
          error={errors.email}
          fullWidth
          variant="outlined"
          size="small"
        />

        {/* Address */}
        <Stack direction="column" spacing={1.5}>
        <BillingInfoTextField
          placeholder="Address"
          value={values.address || ''}
          onChange={(e) => handleChange('address', e.target.value)}
          onBlur={() => handleBlur('address')}
          error={errors.address}
          fullWidth
          variant="outlined"
          size="small"
        />
        <>    
         <BillingInfoTextField
          placeholder="Bulding, Suite, etc."
          value={values.address2 || ''}
          onChange={(e) => handleChange('address2', e.target.value)}
          onBlur={() => handleBlur('address2')}
          error={errors.address2}
          fullWidth
          variant="outlined"
          size="small"
        />
        </>
        </Stack>

        {/* City, State, ZIP */}
        <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
          <BillingInfoTextField
            placeholder="City"
            value={values.city || ''}
            onChange={(e) => handleChange('city', e.target.value)}
            onBlur={() => handleBlur('city')}
            error={errors.city}
            fullWidth
            variant="outlined"
            size="small"
          />
       <div onKeyDown={handleKeyDown}>
       <FormControl 
         fullWidth 
         size="small" 
         error={errors.state}
         sx={{ 
          fontSize: '13.5px',

          borderColor: '#48768C',
           pb: .5, 
           '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: errors.state ? '#B22222' : '#48768C',
                borderRadius: '4px',

              },
             '&:hover fieldset': {
               borderColor: '#2F5C71', // Border color on hover
             },
             '&.Mui-focused fieldset': {
               borderColor: '#48768CBA', // Focused border color
             },
           },
           '& .MuiInputLabel-root': {
             color: '#2F5C71', // Default label color
           },
           '& .MuiInputLabel-root.Mui-focused': {
             color: '#2F5C71', // Label color when focused
           },
           '& .MuiSelect-select': {
             color: '#2F5C71', // Text color inside the select
           },
         }}
       >
        {values.state === '' &&  
        
        <InputLabel sx={{width:'auto', }}>State</InputLabel>}

         <Select
            
                 value={values.state || ''}
                 onChange={handleStateChange}
                 sx={{      fontSize: '13.5px',      padding: 0,
                  backgroundColor: '#F2F9FF',
                  fontFamily: 'Source Sans Pro, sans-serif',
                  textAlign: 'left',
                  
         
                 }}
           MenuProps={{
             PaperProps: {
               sx: {
                 '& .MuiMenuItem-root': {
                   color: '#2F5C71', // Text color
                   fontSize: '14.5px',
                   

                   '&:hover': {
                     backgroundColor: '#ecf1f3', // Hover background color
                     color: '#2F5C71', // Text color on hover
                    },
                   '&.Mui-selected': {
                     backgroundColor: '#d9e2e7', // Selected background color
                     color: '#48768c', // Selected text color
                     borderRadius: '4px', // Rounded corners
                     fontWeight: 'bold', // Bold text  
       
                   },
                 },
               },
             },
           }}
         >
             {states.map(state => (
                   <MenuItem key={state} value={state}>
                     {state}
                   </MenuItem>
                 ))}
         </Select>
       </FormControl>
       </div>
          <BillingInfoTextField
            placeholder="ZIP Code"
            value={values.zip || ''}
            onChange={(e) => handleChange('zip', e.target.value)}
            onBlur={() => handleBlur('zip')}
            error={errors.zip}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Stack>
             {showErrorMessage  && (
                 <Fade in={showErrorMessage} timeout={{ enter: 500, exit: 2000 }}>
                 <Box
                   sx={{
                     flexDirection: 'column',
                     width: '100%',
                     mt: 1.5,
                     mb: -0.55,
                   }}
                 >
                   <Typography
                     style={{
                       fontWeight: 'bold',
                       color: '#7D4E4F',
                       fontSize: '15.5px',
                       alignItems: 'center',
                       justifyContent: 'center',
                       display: 'flex',
                       letterSpacing: -0.1,
                       lineHeight: '-0.03em',
                     }}
                   >
                     We couldn't verify your new address
                   </Typography>
                   <Typography
                     style={{
                       fontWeight: 'bold',
                       color: '#7D4E4F',
                       fontSize: '15.5px',
                       alignItems: 'center',
                       justifyContent: 'center',
                       display: 'flex',
                       letterSpacing: -0.1,
                       lineHeight: '-0.03em',
                       mb:-1.5,
                       pb:-1.5
                     }}
                   >
                     Please check your billing information and try again
                   </Typography>
                 </Box>
               </Fade>
              )}
 
      </Stack>

                 <Button
                 onClick={handleUpdateAddressSubmit}
                      type="submit"
                      disabled={buttonDisabled || isLoading || !dataChanged}
                      sx={{
                        fontSize: '14px',
                          display: "flex",
                        border: "1px solid #48768CA6",
                        borderRadius: "10px",
                        padding: "8px",
                        color: "#48768C",
                        backgroundColor: "#48768C14",
                        mt: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        ml: "auto",
                        mr: "auto",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        width: "98%",
                        zIndex: 2, 
                        "&:disabled": {
                          backgroundColor: "#48768C21", 
                          color: "#A0A0A0D9",
                        },  
                        "&:hover": {
                          backgroundColor: "#DBEAF0",
                        },
                        "&:active": {
                          backgroundColor: "#DBEAF0",
                        },
                      }}
                      color="primary"
                    >
                      {isLoading ? "Processing..." : "Update Billing Details"}
                    </Button>
    </BillingInfoContainer>
    );
};

export default BillingInfoForm;
