// EmployeeChoiceModal.js
import React, {useEffect, useState} from 'react';
import { Modal, Box, Typography, Button, CardMedia, Fade, Skeleton } from '@mui/material';
import multipleEmployees from 'src/public/assets/multipleEmployees.png';
import RestartSetupDialog from "./RestartSetupDialog";  



const EmployeeChoiceModal = ({ isOpen, onClose, handleEmployeeChoice }) => {


  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = multipleEmployees;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);


  const [showRestartDialog, setShowRestartDialog] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);    


  const handleBackdropClick = (event) => {
    // Check if the click is on the backdrop by comparing target and currentTarget
    if (event.target === event.currentTarget) {
      setConfirmRestart(false)
      setShowRestartDialog(true); // Open RestartSetupDialog
    }
  };

  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };


  return (
    <Modal open={isOpen} onClose={onClose}
    slotProps={{
      backdrop: {
        onClick: handleBackdropClick, // Disable closing the modal by clicking on the backdrop
      },
    }}
    disableEscapeKeyDown // Disable closing the modal by pressing the escape key
  >
      <Fade in={isOpen} timeout={400}>

<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'35%',
padding:1,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      > 
        <Typography
        sx={{
          mt:2,
          fontSize: 18.5,
          textAlign: 'center',
          mb: -3,
          fontWeight: 600,
        }}
      >


            Lets get some information about your team
          </Typography>

          {!imageLoaded && (
    <Skeleton
      variant="rectangular"
      sx={{
        height: 'auto',
        maxWidth: '60%', maxHeight:'60%'  ,
        objectFit: 'contain',
        borderRadius: '10px', 
        backgroundColor: '#EEF6F9', // Adjust as needed to match background
        transition: 'opacity 0.3s ease-in-out',
        opacity: imageLoaded ? 0 : 1,
      }}
    />
  )}
    <CardMedia
    component="img"
    image={multipleEmployees}
    sx={{
      display: "block",
      margin: "auto",
      marginBottom: "20px",
maxWidth: '60%', maxHeight:'60%'  ,
      objectFit: 'contain',
      transition: 'opacity 0.3s ease-in-out',
      opacity: imageLoaded ? 1 : 0,
    }}
    onLoad={() => setImageLoaded(true)}
  />



          <Typography
                   sx={{
                    color: '#48768C',
                    fontSize: 17,
                    fontWeight: 600,
                    textAlign: 'center',
                    mb: 3,
                    mt:-6  
                  }}
                >


            Does your location have multiple employees?
          </Typography>
          <Box sx={{ display:'flex', flexDirection:'row', gap:'60px', alignItems:'center', width:'100%',    justifyContent: 'center', mb:2
 }}>
            <Button onClick={() => handleEmployeeChoice('no')} 

sx={{
  display:'flex',
    backgroundColor: '#628197',
    color: '#FFFFFF',
    borderRadius: '10px',
    fontSize: 14,
    height: '40px', 
    fontWeight: 600,
    width: '40%',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',

    '&:hover': {
      backgroundColor: '#1C2A40',
    },
  }}
>

         
              It's just me
            </Button>
            <Button onClick={() => handleEmployeeChoice('yes')} 
            sx={{
              display:'flex',

                backgroundColor: '#28354F',
                color: '#FFFFFF',
                borderRadius: '10px',
                fontSize: 14,
                height: '40px', 
                fontWeight: 600,
                width: '43%',

                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',

                letterSpacing: '0.02em',
                '&:hover': {
                  backgroundColor: '#7589A2',
                },
              }}
            >

              We have multiple employees
            </Button>
          </Box>
          <RestartSetupDialog
        open={showRestartDialog}
        onClose={handleCloseRestartDialog}
        confirmRestart={confirmRestart}
        setConfirmRestart={setConfirmRestart}
/>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EmployeeChoiceModal;
