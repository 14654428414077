import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSubscriptionDetails } from '../routes/useSubscriptionDetails';

const usePlanDetails = () => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [planDetails, setPlanDetails] = useState(null);
  const [planError, setPlanError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [triggerFetch, setTriggerFetch] = useState(false); // Add a trigger to refetch
  


  useEffect(() => {
    const fetchPlanDetails = async () => {
      if (!isAuthenticated || !user) {
        return;
      }

      try {
        // Get the Auth0 access token
        const token = await getAccessTokenSilently();

        // Make the fetch request to your backend API
        const response = await fetch(`${apiUrl}/subscriptions/plans/plan-details?auth0_user=${user.sub}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch plan details');
        }

        const data = await response.json();
        setPlanDetails(data);
      } catch (err) {
        console.error('Error fetching plan details:', err);
        setPlanError(err.message);
      }
    };

    fetchPlanDetails();
  }, [getAccessTokenSilently, isAuthenticated, user,]);

  const fetchPlanDetails = async () => {
    if (!isAuthenticated || !user) {
      return;
    }

    try {
      // Get the Auth0 access token
      const token = await getAccessTokenSilently();

      // Make the fetch request to your backend API
      const response = await fetch(`${apiUrl}/subscriptions/plans/plan-details?auth0_user=${user.sub}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch plan details');
      }

      const data = await response.json();
      setPlanDetails(data);
    } catch (err) {
      console.error('Error fetching plan details:', err);
      setPlanError(err.message);
    }
  };



  return { planDetails, planError, fetchPlanDetails  };
};

export default usePlanDetails;