import React, {useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
Box,
DialogTitle,
Dialog,
DialogContent,
Typography,
IconButton,
Paper,
Button,
Stack,
Fade,
} from '@mui/material';
import { useAccountContext } from '../../../../context/AccountContext'; // Import AppProvider from AccountContext
import DetailsIcon from '../../../../public/assets/icons/icons/details.png';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import FailIcon from '../../../../public/assets/icons/icons/failedMessage.png';
import StatusText from '../../../color-utils/StatusText';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';
import {useAuth0} from '@auth0/auth0-react';    
import { useAppContext } from 'src/context/AppContext';
import ReviewDialog from 'src/components/modal/ReviewDialog/ReviewDialog';
import useDowngradeToSingleUser from 'src/components/payments/hooks/useDowngradeToSingleUser.js'
import useDowngradeToMultiUser from 'src/components/payments/hooks/useDowngradeToMultiUser';
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import DeleteIcon from 'src/public/assets/icons/icons/deleteRed.png';











function DowngradeDialog({ open, onClose, itemToDelete, fetchUsers, title, downgradeDialogTitle, isDowngradingLocation , locationToDelete, dialogSubText, downgradeError, isDowngradingToMultiUser, handleDowngradeDialogClose, handleCancel  }) {

  const [openReviewDialog, setOpenReviewDialog] = useState(false);  

    
  const { downgradeToSingleUser } = useDowngradeToSingleUser();
  const { downgradeToMultiUser } = useDowngradeToMultiUser();
  const [newPlanName, setNewPlanName] = useState(''); 
  const [newPlanFeatureOne, setNewPlanFeatureOne] = useState(''); 
  const [newPlanFeatureTwo, setNewPlanFeatureTwo] = useState('');
  const [newPlanFeatureThree, setNewPlanFeatureThree] = useState('');
  const [oldPlanName, setOldPlanName] = useState(''); 
  const [oldPlanFeatureOne, setOldPlanFeatureOne] = useState('');
  const [oldPlanFeatureTwo, setOldPlanFeatureTwo] = useState('');
  const [oldPlanFeatureThree, setOldPlanFeatureThree] = useState('');
  const [oldPlanPrice, setOldPlanPrice] = useState(''); 
  const [newPlanPrice, setNewPlanPrice] = useState(''); 
  const [buttonAction, setButtonAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);  
  const {resetContext} = useAuth();  








  const handleCloseReviewDialog = () => { 
    setOpenReviewDialog(false);
  };

  const handleOpenReviewDialog = () => {  
    setOpenReviewDialog(true);
  };





  useEffect(() => {
    if (open && !isDowngradingLocation) {
      // Call the function directly inside the useEffect when the conditions are met
      setOldPlanPrice('$9.99/month');
      setNewPlanPrice('$4.99/month');
      setNewPlanName('Single User Plan'); 
      setNewPlanFeatureOne('Unlimited Notifications');
      setNewPlanFeatureTwo('Two Way Notification Support');
      setNewPlanFeatureThree('Scheduled Notification Support');
      setOldPlanName('Multi User Plan');
      setOldPlanFeatureOne('Includes 2 Users');
      setOldPlanFeatureTwo('Additional User $5/month');
      setOldPlanFeatureThree('Add / Remove Users Anytime');
      
    }
  }, [open, isDowngradingLocation]); // Add isDowngradingLocation to the dependency array


  useEffect(() => {
    if (open && isDowngradingLocation) {
      // Call the function directly inside the useEffect when the conditions are met
      setOldPlanPrice('$19.99/month');
      setNewPlanName('Multi User Plan');
      setNewPlanFeatureOne('Add / Remove Users Anytime');
      setNewPlanFeatureTwo('Additional User $5/month');
      setNewPlanFeatureThree('Additional Location $10/month');
      setOldPlanName('Multi Location Plan');
      setNewPlanPrice('$9.99/month');
      setOldPlanFeatureOne('Unlimited Notifications');
      setOldPlanFeatureTwo('Two Way Notification Support');
      setOldPlanFeatureThree('Scheduled Notifications');
      
    }
  }, [open, isDowngradingLocation]); // Add isDowngradingLocation to the dependency array






useEffect(() => { 
  if (!open) {
    setOpenReviewDialog(false);
  }
}, [open]);


const handleLogout = () => {
  localStorage.clear();
  sessionStorage.clear();
  resetContext(); 
  navigate('/'); 
  window.location.reload();
};


    const { getAccessTokenSilently, user } = useAuth0(); // Destructure getAccessTokenSilently from the hook  
    const { accountData, updateSingleUserStatus, updateSingleLocationStatus, fetchAccountData } = useAccountContext(); // Use the accountData from the context
    const {showSnackBar} = useAppContext(); // Use the showSnackbar function from the context 
    const userId = user.sub
    const navigate = useNavigate(); // Initialize the navigate function

    
    





    




    const handleDeleteUser = async () => {
      setIsLoading(true);

      const userToDelete = itemToDelete.user_id;
      const accountId = accountData.id;
    
      const userDowngradeData = {
        userId,
        accountId,
        userToDelete,
      };
    
      try {
        // Call downgradeToMultiUser and handle the response
        const response = await downgradeToSingleUser(userDowngradeData);
    
        if (response?.success) {
          showSnackBar('Location deleted successfully.', true);
          // Add any additional logic for successful deletion here
        } else {
          showSnackBar('Failed to delete location. Please try again.', false);
        }
      } catch (error) {
        // Handle any unexpected errors
        showSnackBar('An unexpected error occurred. Please try again.', false);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };






    const handleDeleteLocation = async () => {
      setIsLoading(true);
      const locationId = locationToDelete.location_id;
      const accountId = accountData.id;
    
      const locationDowngradeData = {
        accountId,
        userId,
        locationId,
      };
    
      try {
        // Call downgradeToMultiUser and handle the response
        const response = await downgradeToMultiUser(locationDowngradeData);
    
        if (response?.success) {
          showSnackBar('Location deleted successfully.', true);
          // Add any additional logic for successful deletion here
        } else {
          showSnackBar('Failed to delete location. Please try again.', false);
        }
      } catch (error) {
        // Handle any unexpected errors
        showSnackBar('An unexpected error occurred. Please try again.', false);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };



      const handleDelete = async () => {
        if (isDowngradingLocation) {
          return await handleDeleteLocation();
        } else {
          return await handleDeleteUser();
        }
      };  




    


  return (
    <Dialog open={open} onClose={onClose} 
    TransitionComponent={Fade}
    transitionDuration={{ enter: 300, exit: 300 }}
    PaperProps={{
        style: {  
          backgroundColor: '#747C86', 
          width: '200px', 
          border: '1px solid rgba(173, 216, 230, 0.7)', 
          minWidth: '400px',
          borderRadius: '18px',
          bottom: '5%',
          maxWidth: '600px',
        },
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
>     
  <DialogTitle
    sx={{
      width: '100%',
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      padding: 3,
      color: "#374247",
      alignItems: "center",
    }}
  >
    <Box sx={{}}>
      <img
        src={DeleteIcon}
        alt="detailsIcon"
        style={{
          border: 'transparent',
          width: "auto",
          height: "40px",
          backgroundColor:'#8C435036',
          borderRadius: "6px",
          padding: "1px",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
          marginBottom: "5px", 
        }}
      />
    </Box>
    <Typography sx={{ color: "#48768C", mb: 0.5, fontWeight: 600, mt: 1.5, fontSize: '18px' }}>
    Change Account Type {" "}
    </Typography>
  </DialogTitle>
  <DialogContent
    sx={{         
      mt: -1,
      fontWeight: 600,
      letterSpacing: '0.02em',
      backgroundColor: "#FFFFFF",
      color: '#48768C',
      padding: '.75rem',
    
    }}
  >
     <Box sx={{ 
       display: 'flex', 
       flexDirection: 'column', 
       alignItems: 'center', 
       backgroundColor: 'rgba(191, 218, 231, 0.1)',
       border: '1px solid rgba(173, 216, 230, 0.7)', 
       borderRadius: '10px',  
       fontSize: 15, 
       padding: '.75rem', 
       pt: 2, 
       px:1.5,
     }}>            
       <Typography sx={{ fontSize: '15.5px',   alignItems: 'center', textAlign: 'center', }}>
       {downgradeDialogTitle} 
        </Typography>
                  <Typography sx={{ fontSize: '14.5px', mt: 1.5, fontWeight: 'bold',       alignItems: 'center', textAlign: 'center', width:'auto'
             }}>
                    {dialogSubText} 
                  </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2,pt:1, pb:2 }}>
                <Stack direction="column" spacing={2.5} sx={{width:'100%', display:'flex'}}>
                
                  <Button 
          onClick={handleOpenReviewDialog}    
          disabled={isLoading}     
                    sx={{
                      display: 'flex',
                      border: '1px solid #8C435029',
                      borderRadius: '10px',
                      padding: '8px',
                      color: '#8C4350',
                      width:'100%',
                      backgroundColor: '#8C435021',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        
        
        
        
                      '&:hover': {
                        backgroundColor: '#8C435017',
                      },
                      '&:active': {
                        backgroundColor: '#A4C5D230',
                      },
                      }}
                      color="primary"
                      >
       Continue
                  </Button>
                  <Button 
                    onClick={onClose}
                    sx={{
                      marginRight: '10px',
                      border: '1px solid #A4C5D2E8',
                      borderRadius: '10px',
                      padding: '8px',
                      color: '#48768C',
                      width:'100%',
                      backgroundColor: '#FFFFFF',
                      '&:hover': { backgroundColor: '#48768C12' },
                      '&:active': { backgroundColor: '#DBEAF0' },
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
              </Box>

            
      </DialogContent>
      <ReviewDialog
        open={openReviewDialog}
        onClose={handleCloseReviewDialog}
        addedUserPrice='+ $5/month'
        buttonText='Confirm & Downgrade Plan'
        changedPlan={true}
        buttonAction={handleDelete}  
       
        plan='singleUserMonthly'
        buttonLoadingText='Saving Changes...'
        newPlanName={newPlanName}
        newPlanFeatureOne={newPlanFeatureOne}
        newPlanFeatureTwo={newPlanFeatureTwo}
        newPlanFeatureThree={newPlanFeatureThree}
        oldPlanName={oldPlanName}
        oldPlanFeatureOne={oldPlanFeatureOne}
        oldPlanFeatureTwo={oldPlanFeatureTwo}
        oldPlanFeatureThree={oldPlanFeatureThree}
        oldPlanPrice={oldPlanPrice}
        newPlanPrice={newPlanPrice}
        isLoading={isLoading}
        handleCancel={handleCancel} 
        CancelCloseMessage="Are you sure you want to close?"
        CancelCloseSubText="Any changes you have made will not be saved."
        

/>
    </Dialog>
  );
}

export default DowngradeDialog; 