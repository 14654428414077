import React, {useEffect} from 'react';   
import { Dialog, DialogTitle, DialogContent, Typography, Box, Stack, Button, IconButton, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorImage from 'src/public/assets/illustrations/error.png';
import {Routes, Route, NavLink, useMatch} from 'react-router-dom';
import { set } from 'date-fns';

function InactiveSubscriptionDialog({ open, onClose, subscriptionValid ,verifying, CloseInactiveSubscriptionDialog, handleCloseDialog }) {






    return (
        <>
     
               <Dialog open={open} onClose={CloseInactiveSubscriptionDialog} PaperProps={{ sx: { borderRadius: "20px", maxWidth:'510px' } }}>
               <DialogTitle sx={{marginBottom:1}}>
                 <Box display="flex" justifyContent="center" alignItems="center">
                   <img src={ErrorImage} alt="Error" style={{ width: "250px", height: "250px" }} />
                 </Box>
               </DialogTitle>
               <DialogContent sx={{marginBottom:2}}>
                 <Stack direction="column" alignItems="center" spacing={2}>
                   <Typography align="center" sx={{fontSize:'20px', fontWeight:550, color:'#48768C', pt:2}}>
                   Ooops, there’s an issue with your account
                   </Typography>
                   <Typography align="center" sx={{fontSize:'16px', fontWeight:500, color:'#6c90a2'}}>
                     Your subscription has expired. Please update your billing information to send notifications.
                   </Typography>
                 </Stack>
               </DialogContent>
               <DialogActions sx={{ flexDirection: 'column', alignItems: 'center' }}>
                 <Stack direction="row" spacing={9} justifyContent="center" sx={{marginBottom:2}}>
                 <NavLink to="/dashboard/Account/Billing" style={{ textDecoration: 'none' }}>

                   <Button
                                       onClick={CloseInactiveSubscriptionDialog}

                     sx={{
                       display: 'flex',
                       border: '1px solid #48768C',
                       borderRadius: '10px',
                       padding: '8px',
                       color: '#48768C',
                       backgroundColor: '#DBEAF0',
                       justifyContent: 'center',
                       alignItems: 'center',
                       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                       fontSize: '14.5px', 
                       width: '150px',   
                       '&:hover': {
                         backgroundColor: '#FFFFFF',
                       },
                       '&:active': {
                         backgroundColor: '#FFFFFF',
                       },
                     }}
                     color="primary"
                   >
                     Update Billing
                   </Button>
                   </NavLink>
                   <Button
                    onClick={CloseInactiveSubscriptionDialog}
                     sx={{
                       display: 'flex',
                       border: '1px solid #48768C',
                       borderRadius: '10px',
                       padding: '8px',
                       color: '#48768C',
                       backgroundColor: '#FFFFFF',
                       justifyContent: 'center',
                       alignItems: 'center',
                       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                       fontSize: '14.5px', 
                       width: '150px',   

                       '&:hover': {
                         backgroundColor: '#DBEAF0',
                       },
                       '&:active': {
                         backgroundColor: '#DBEAF0',
                       },
                     }}
                     color="primary"
                   >
                     Do It Later
                   </Button>
                 </Stack>
                 {/* Ensure this Box is below the buttons */}
                 <Box sx={{ marginTop: '22px', textAlign: 'center', width:'85%', mb:1}}>
                   <Typography  sx={{fontSize:'14.25px', color:'#48768C'}}>
                     You can still manage your account and view your notification history in the meantime.
                   </Typography>
                 </Box>
               </DialogActions>
             </Dialog>
            
        </>
    );
}

export default InactiveSubscriptionDialog;