import React, { useState, useEffect } from 'react';
import { Box, Button, List, ListItem, ListItemText, ListItemIcon, Checkbox, Grid, Typography, Stack, Divider,IconButton, Radio, Paper, Avatar } from '@mui/material';
import ForwardRoundedIcon from '@mui/icons-material/ForwardRounded';
import AddUserIcon from 'src/public/assets/icons/icons/addUser.png';


function TransferList({ users, subUserData, name, handleCheckedLeft,   
    handleToggle,
    checked,
    left,
    right,
    handleCheckedRight, 
  setLeft,handleCreateNewEmployeeClick,
  accountData,
  isNewUserSaved,
  isAssignEmployeeVisible,
  enableEditButtons,
  hideCreateUserButton,
locationId,
setRight


}) {

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : '';
    const lastInitial = lastName ? lastName.charAt(0) : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };






const customList = (name, items, side) => (
  <Box
    sx={{
      flex: 1,
      minHeight: '20vh',
      maxHeight: '40vh',
      border: '1.25px solid rgba(173, 216, 230, 0.5)',
      borderRadius: '6px',
      backgroundColor: 'rgba(173, 216, 230, 0.1)',
      mb: 4,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {/* Header */}
    <Typography
      sx={{
        textAlign: 'center',
        fontSize: '14.5px',
        fontWeight: 500,
        color: '#4A6B75',
        px: '10px',
        letterSpacing: '-0.10px',
        py: '5px',
        borderBottom: '1.25px solid rgba(173, 216, 230, 0.5)',
        backgroundColor: 'rgba(191, 218, 231, 0.2)',
        textShadow: '0.05px 0.05px 0px rgba(0, 0, 0, 0.3)',
      }}
    >
      {name || 'New Location'}
    </Typography>

    {Array.isArray(items) && items.length > 0 ? (
      <List
        dense
        component="div"
        role="list"
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: 0,
          '& .MuiListItemIcon-root': {
            marginRight: 0,
            marginLeft: -1,
          },
        }}
      >
        {items.map((user, index) => {
          const labelId = `transfer-list-item-${user.user_id || index}-label`; // Use index as fallback

          return (
            <ListItem
              key={user.user_id || index}
              role="listitem"
              onClick={handleToggle(user)}
              sx={{ padding: 0, pl: 1 }}
            >
             <ListItemIcon>
  {side === 'right' && user.location_id === locationId ? (
    // Render Avatar if side is 'right' and locationId matches user's location_id
    <Avatar
      sx={{
        color: "#48768C",
        fontSize: "14px",
        width: 31,
        height: 31,
        background: 'radial-gradient(circle, #EEF6F9, #FFFFFF)',
        border: '2px solid rgba(173, 216, 230, 0.9)',
        borderRadius: "20px",
        padding: "4px",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.01)",
        marginRight: 1,
        fontWeight: 600,
        my: 0.5,
        ml: 0.75,
        mt: 0.5,
      }}
    >
      {getInitials(user.first_name, user.last_name)}
    </Avatar>
  ) : (
    // Render Checkbox otherwise
    <Checkbox
      sx={{
        color: '#4A6B75',
        borderRadius: '15px',
        fontSize: 28,
        '&.Mui-checked': {
          color: '#4A6B75',
        },
        '& .MuiSvgIcon-root': { fontSize: 21 },
      }}
      checked={checked.indexOf(user) !== -1}
      tabIndex={-1}
      disableRipple
      disabled={side === 'right' && user.location_id === locationId} // Optionally disable checkbox
      inputProps={{ 'aria-labelledby': labelId }}
    />
  )}
</ListItemIcon>

              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 600,
                  color: '#4A6B75',
                  letterSpacing: '-2%',
                  whiteSpace: 'nowrap',
                }}
                id={labelId}
              >
                {`${user.first_name} ${user.last_name}`}
              </Typography>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    ) : (
      // Placeholder for no employees
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 500,
            color: '#7A7A7A',
            mt:-4,
          }}
        >
          {side === 'left' ? 'No Available Employees' : 'No Assigned Employees'}
        </Typography>
      </Box>
    )}
  </Box>
);

  return (
<Box sx={{ width: '100%', display: 'flex', alignItems:'center' }}>
  <Stack
    direction="row"
    spacing={1}
    sx={{ width: '100%', display: 'flex', flexGrow: 1, m: 0 }}
  >
    {/* Left List Container */}
    <Box sx={{ flexGrow: 1, minWidth: 0, display: 'flex', width: '100%' }}> 
      <Box sx={{flexGrow: 1, minWidth: 0, display: 'flex', width: '100%' }}>
      {customList('Active Employees', left, 'left')}
      </Box>
    </Box>

    <Stack direction="column" alignItems="center"  sx={{ display: 'flex', gap: 1, width: 'auto',  height:'auto',pb:3, justifyContent:'center', flexGrow: 1  }}>
    <IconButton
  sx={{
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(173, 216, 230, 0.8)',
    },
  }}
  onClick={handleCheckedRight}
  disabled={
    checked.length === 0 || 
    !checked.some((user) => left.includes(user)) || 
    checked.some((user) => user.isNewUser) // Prevent moving if a new user is selected
  }
>
  <ForwardRoundedIcon
    sx={{
      color: checked.length === 0 || !checked.some((user) => left.includes(user)) || checked.some((user) => user.isNewUser)
        ? 'rgba(74, 107, 117, 0.2)'
        : '#4A6B75',
      backgroundColor: '#48768C26',
      border: '1.25px solid rgba(173, 216, 230, 0.8)',
      borderRadius: '8px',
      width: '30px',
      height: '30px',
      transition: 'background-color 0.3s, border-color 0.3s',
      '&:hover': {
        backgroundColor: '#D1E9F1',
        borderColor: '#A0C4D9',
      },
    }}
  />
</IconButton>



  <IconButton
  sx={{
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(173, 216, 230, 0.8)',
    },
  }}
  onClick={handleCheckedLeft}
  disabled={
    checked.length === 0 || 
    !checked.some((user) => right.includes(user) && !user.isNewUser) // Prevent moving new users to the left
  }
>
  <ForwardRoundedIcon
    sx={{
      transform: 'rotate(180deg)',
      color: checked.length === 0 || !checked.some((user) => right.includes(user) && !user.isNewUser)
        ? 'rgba(74, 107, 117, 0.2)'
        : '#4A6B75',
      backgroundColor: '#48768C26',
      border: '1.25px solid rgba(173, 216, 230, 0.8)',
      borderRadius: '8px',
      width: '30px',
      height: '30px',
      transition: 'background-color 0.3s, border-color 0.3s',
      '&:hover': {
        backgroundColor: '#D1E9F1',
        borderColor: '#A0C4D9',
      },
    }}
  />
</IconButton>
</Stack>

    {/* Right List Container */}
    <Box sx={{ flexGrow: 1, minWidth: 0, display: 'flex', flexDirection: 'column', width: '100%' }}>
  <Box sx={{ flexGrow: 1, display: 'flex', width: '100%' }}>
    <Box sx={{ flexGrow: 1, minWidth: 0, width: '100%' }}>
    {customList(name, right, 'right')}
    </Box>
    
  </Box>

  {/* Button Container */}
 
</Box>
  </Stack>
</Box>
  );
};


export default TransferList;