// NewUserMultiLocation.js

import React, { useState, useEffect } from 'react';
import FirstLocationPrompt from './FirstLocationPrompt';
import AddFirstLocationModal from './AddFirstLocationModal';
import FirstUserPrompt from './FirstUserPrompt';
import NewUserFromAuth0 from './NewUserFromAuth0';
import NewAccountSuccess from './NewAccountSuccess';
import { useAuth0 } from '@auth0/auth0-react';
import NewUserNotification from '../../../../src/hooks/NewUserNotification'



const NewUserMultiLocation = ({ onClose, onNext }) => {
  const [step, setStep] = useState(1);
  const [locationData, setLocationData] = useState(null);
  const [newPin, setNewPin] = useState('');
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const { user, getAccessTokenSilently } = useAuth0();
  const [isFormValid, setIsFormValid] = useState(false);
  const [pinError, setPinError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false); 


  

  const handleAddLocation = (newLocationData) => {
    setLocationData(newLocationData);
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };


  const handleNewUserFromAuth0 = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently(); // Get access token silently
  
      // Validate that PINs match
      if (newPin === confirmNewPin) {
        // Create a new user with the provided username and PIN
        const response = await fetch(`${apiUrl}/api/users/multi-location`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Add authorization header with access token
          },
          body: JSON.stringify({
            firstName: newFirstName,
            lastName: newLastName,
            userName: newUsername,
            pin: newPin,
            auth0UserId: user.sub,
            title: 'Account Administrator',
            //activeLocation: locationData.id, // Use the locationData id
            //is_admin: true, // Include the is_admin property set to true
          }),
        });
  
        const responseData = await response.json();
        if (response.ok) {
          
  
          // Call the function to create notifications for the new user
          await NewUserNotification(user.sub, getAccessTokenSilently); // Pass the user.sub as userId and use await
          setStep(5);
          setLoading(false);
        } else {
          console.error('Failed to create a new user:', responseData.error);
          setLoading(false);

        }
      } else {
        console.error('Entered PINs do not match');
        setLoading(false);

      }
    } catch (error) {
      console.error('Error creating a new user:', error);
      setLoading(false);

    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <FirstLocationPrompt isOpen={true} onNext={handleNext} />;
      case 2:
        return <AddFirstLocationModal isOpen={true} onClose={() => {}} onAddLocation={handleAddLocation} onNext={handleNext} />;
      case 3:
        return <FirstUserPrompt isOpen={true} onNext={handleNext} />;
      case 4:
        return   <NewUserFromAuth0 
        isOpen={true} 
        onClose={() => setStep(5)}
        onSave={handleNext}
        locationData={locationData}
        newPin={newPin}
         setNewPin={setNewPin} 
         confirmNewPin={confirmNewPin} 
         setConfirmNewPin={setConfirmNewPin}
          newFirstName={newFirstName}
            setNewFirstName={setNewFirstName} 
            newLastName={newLastName}
              setNewLastName={setNewLastName} 
              newUsername={newUsername} 
              setNewUsername={setNewUsername} 
              isFormValid={isFormValid} 
              setIsFormValid={setIsFormValid} 
              pinError={pinError} 
              setPinError={setPinError} 
              apiUrl={apiUrl} 
              user={user} 
              getAccessTokenSilently={getAccessTokenSilently} 
              loading={loading} 
              setLoading={setLoading} 
              onAddLocation={handleAddLocation} 
              handleNewUserFromAuth0={handleNewUserFromAuth0}
        
        />;
      case 5:
        return <NewAccountSuccess isOpen={true} onNext={handleNext} />;
      default:
        return null;
    }
  };





  useEffect(() => {
    if (step === 1) {
      renderStep(); // Start the flow when the component mounts
    }
  }, [step]);

  return (
    <>
      {renderStep()}
    </>
  );
};

export default NewUserMultiLocation;
