import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box, Stack, IconButton, DialogActions, Button, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import hoursIcon from '../../public/assets/icons/icons/hours.png';
import dayjs from 'dayjs';
import SleepIcon from '../../public/assets/icons/icons/sleep.png';




// Exporting the checkIfOpen function
export const checkIfOpen = (businessHours) => {
  const currentDay = dayjs().format('dddd'); // Get current weekday, e.g., 'Monday'
  const currentTime = dayjs(); // Get current time

  const todayHours = businessHours[currentDay];

  if (todayHours.is_closed || !todayHours.start_time || !todayHours.end_time) {

    return false;
  }

  const startTime = dayjs(todayHours.start_time, 'h:mm A');
  const endTime = dayjs(todayHours.end_time, 'h:mm A');

  return currentTime.isAfter(startTime) && currentTime.isBefore(endTime);
};

export const checkIfScheduledOpen = (businessHours, selectedDateTime) => {
  const selectedDay = dayjs(selectedDateTime).format('dddd'); // Get the weekday

  
  const selectedDayHours = businessHours[selectedDay];

  if (!selectedDayHours || selectedDayHours.is_closed || !selectedDayHours.start_time || !selectedDayHours.end_time) {
    return false;
  }

  // Parse selected time and business hours in 12-hour format
  const selectedTimeOnly = dayjs(selectedDateTime.format('h:mm A'), 'h:mm A');
  const startTime = dayjs(selectedDayHours.start_time, 'h:mm A');
  const endTime = dayjs(selectedDayHours.end_time, 'h:mm A');
  


  // Include boundaries: check if time is within or exactly matches start or end time
  return (selectedTimeOnly.isSame(startTime) || selectedTimeOnly.isAfter(startTime)) &&
         (selectedTimeOnly.isSame(endTime) || selectedTimeOnly.isBefore(endTime));
};



export const sendWhenOpen = (businessHours, setSelectedDateTime) => {
    const currentTime = dayjs(); // Get current time
    let nextAvailableTime = null;
  
    // Iterate through business hours starting from today
    for (let i = 0; i < 7; i++) {
      const dayToCheck = currentTime.add(i, 'day').format('dddd');
      const dayHours = businessHours[dayToCheck];
  
      if (dayHours && !dayHours.is_closed && dayHours.start_time && dayHours.end_time) {
        const startTime = dayjs(`${currentTime.add(i, 'day').format('MM/DD/YYYY')} ${dayHours.start_time}`, 'MM/DD/YYYY h:mm A');
        
        if (i === 0 && currentTime.isBefore(startTime)) {
          // If today and the current time is before the start time
          nextAvailableTime = startTime;
          break;
        } else if (i > 0) {
          // For future days, get the start time directly
          nextAvailableTime = startTime;
          break;
        }
      }
    }
  
    if (nextAvailableTime) {
      setSelectedDateTime(nextAvailableTime);
      // Note: Removed the log for selectedDateTime here
      return nextAvailableTime.format('dddd, MM/DD/YYYY h:mm A');
    } else {
      return 'No available time to send';
    }
  };


// AfterHoursDialog component
export default function AfterHoursDialog({ openDialog, setOpenDialog, loading,handleOpenScheduleDialog, selectedDateTime, setSelectedDateTime, setLoading, businessHours, disableScheduleClick }) {
  const [nextAvailableTime, setNextAvailableTime] = useState(null);
  const formattedDate = nextAvailableTime ? nextAvailableTime.format('dddd, MM/DD/YYYY') : '';
  const formattedTime = nextAvailableTime ? nextAvailableTime.format('h:mm A') : '';


  useEffect(() => {
    if (businessHours) {
      const open = checkIfOpen(businessHours);
      if (!open) {
        calculateNextAvailableTime(); // Trigger calculation if business hours are set
      }
    }
  }, [businessHours]);

  const calculateNextAvailableTime = () => {
    const currentTime = dayjs();
    let nextAvailableTime = null;

    for (let i = 0; i < 7; i++) {
      const dayToCheck = currentTime.add(i, 'day').format('dddd');
      const dayHours = businessHours[dayToCheck];

      if (dayHours && !dayHours.is_closed && dayHours.start_time && dayHours.end_time) {
        const startTime = dayjs(`${currentTime.add(i, 'day').format('MM/DD/YYYY')} ${dayHours.start_time}`, 'MM/DD/YYYY h:mm A');

        if (i === 0 && currentTime.isBefore(startTime)) {
          nextAvailableTime = startTime;
          break;
        } else if (i > 0) {
          nextAvailableTime = startTime;
          break;
        }
      }
    }

    setNextAvailableTime(nextAvailableTime);
  };

  
  const handleButtonClick = () => {
    setLoading(true);
    const result = sendWhenOpen(businessHours, setSelectedDateTime);
    setOpenDialog(false);
    setLoading(false);
  };




  const handleScheduleTime = () => {
    setOpenDialog(false);
    handleOpenScheduleDialog(); // Added parentheses to invoke the function
  };




  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
          style: {  
            backgroundColor: '#747C86', 
            width: '200px', 
            border: '1px solid rgba(173, 216, 230, 0.7)', 
            minWidth: '400px',
            borderRadius: '18px',
            bottom: '5%',
            maxWidth: '600px',
          },
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
  >     
    <DialogTitle
      sx={{
        width: '100%',
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 3,
        color: "#374247",
        alignItems: "center",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpenDialog(false)}       
         sx={{
          position: "absolute",
          right: 2,
          top: 4,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      <Box sx={{}}>
        <img
          src={SleepIcon}
          alt="detailsIcon"
          style={{
            border: 'transparent',
            width: "auto",
            height: "40px",
            backgroundColor:'#c6d4dc',
            borderRadius: "6px",
            padding: "1px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            marginBottom: "5px", 
          }}
        />
      </Box>
      <Typography sx={{ color: "#48768C", mb: 0.5, fontWeight: 600, mt: 1.5, fontSize: '18px' }}>
      Send Notification After Hours 
      </Typography>
      <Typography sx={{color: "#48768C", fontSize: '14.5px',   alignItems: 'center', textAlign: 'center', fontWeight:600, mb:1 }}>
       
       
       
       {disableScheduleClick ?  'Two-way notifications can only be sent during normal business hours.' : 'You can only notify your customers during normal business hours.' }
        </Typography>
    </DialogTitle>
    <DialogContent
      sx={{         
        mt: -1,
        fontWeight: 500,
        letterSpacing: '0.02em',
        backgroundColor: "#FFFFFF",
        color: '#48768C',
        padding: '.75rem',
      
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        backgroundColor: 'rgba(191, 218, 231, 0.1)',
        border: '1px solid rgba(173, 216, 230, 0.7)', 
        borderRadius: '10px',  
        fontSize: 15, 
        padding: '.75rem', 
        pt: 1, 
        px:1.5,
      }}>            


{disableScheduleClick ? (
      <Typography sx={{ fontSize: '14.5px', mt: 1.5,       alignItems: 'center', textAlign: 'center', width:'auto', fontWeight:600}}>

      Two way notifications cannot be scheduled to send at a later time. 
      </Typography>
) : (

<Box>
        <Typography sx={{ fontSize: '14.5px', mt: .5,       alignItems: 'center', textAlign: 'center', width:'auto', fontWeight:500
   }}>
        { nextAvailableTime ?  'We can schedule your message to automatically send the next time you are open.' : 'All days for your location are set to closed, or no hours have been set.'}  
          </Typography>
</Box>
)}

 
 


{disableScheduleClick ? (
    <Typography sx={{ fontSize: '14.5px', mt: 1.5,       alignItems: 'center', textAlign: 'center', width:'auto', fontWeight:500}}>

    Set your location hours to the current time or send your notification when your location is open.
    </Typography>
) : (


<Box>
  {nextAvailableTime ? (
    <>
              <Typography sx={{ fontSize: '14.5px', mt: 1.5,       alignItems: 'center', textAlign: 'center', width:'auto', fontWeight:500, whiteSpace:'no-wrap'}}>

      Your notification will automatically be sent on
      </Typography>

                <Typography sx={{ fontSize: '14.5px',       alignItems: 'center', textAlign: 'center', width:'auto', fontWeight:600}}>

    {formattedDate} at {formattedTime}
      </Typography>
      </>
  ) : (
    <Typography sx={{ fontSize: '14.5px', mt: 1.5,       alignItems: 'center', textAlign: 'center', width:'auto', fontWeight:600}}>

    Check your location hours and try again.
    </Typography>

  )}
  </Box>
)}
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2,pt:1, pb:1 }}>



{disableScheduleClick ? (
  
  <Button 
  onClick={() => setOpenDialog(false)}
  sx={{
marginRight: '10px',
border: '1px solid #A4C5D2E8',
borderRadius: '10px',
padding: '8px',
color: '#48768C',
width:'100%',
backgroundColor: '#FFFFFF',
boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',

'&:hover': { backgroundColor: '#48768C12' },
'&:active': { backgroundColor: '#DBEAF0' },
}}
color="primary"
>
Close
</Button>

) : (



          <Stack direction="column" spacing={2.5} sx={{width:'100%', display:'flex'}}>
          
          { nextAvailableTime && (
            <Button 
                        onClick={handleButtonClick}


              sx={{
                display: 'flex',
                border: '1px solid #8C435029',
                borderRadius: '10px',
                padding: '8px',
                color: '#48768C',
                width:'100%',
                backgroundColor: '#d9e2e7',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  
  
  
  
                '&:hover': {
                  backgroundColor: '#ecf1f3',
                },
                '&:active': {
                  backgroundColor: '#ecf1f3',
                },
                }}
                color="primary"
                >
            {loading ? "Scheduling..." : "Send When Open"}

            </Button>
          )}
            <Button 
                        onClick={handleScheduleTime}
                        sx={{
                marginRight: '10px',
                border: '1px solid #A4C5D2E8',
                borderRadius: '10px',
                padding: '8px',
                color: '#48768C',
                width:'100%',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',

                '&:hover': { backgroundColor: '#48768C12' },
                '&:active': { backgroundColor: '#DBEAF0' },
              }}
              color="primary"
            >
            {loading ? "Scheduling..." : "Schedule A Different Time"}
            </Button>
          </Stack>
        )}
        </Box>
      </Box>
    </DialogContent>
     

      </Dialog>
  );
}