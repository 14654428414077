import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper, 
  IconButton,
  Card, 
  Avatar,
  TablePagination

} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import bellIcon from "../../public/assets/icons/messageModal/bell.png";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import newCustomerIcon from "../../public/assets/icons/icons/newCustomer.png";
import { useAccountContext } from '../../context/AccountContext'; // Import AppProvider from AccountContext
import phoneIcon from "../../public/assets/icons/messageModal/phone.png";
import { useAuth0 } from '@auth0/auth0-react';
import StatusTag from 'src/components/color-utils/statusTags';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MessageDetails from './MessageDetails';  






const CustomerDetailsDialog = ({ open, onClose, customer, phone_number, userSub, notificationHistory }) => {
  const { accountData } = useAccountContext();
  const { getAccessTokenSilently } = useAuth0();  

  const phoneNumber = phone_number;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page
  const [isMessageDetailsOpen, setIsMessageDetailsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);



  const handleOpenMessageDetails = (row) => {
    setSelectedRow(row);  
    setIsMessageDetailsOpen(true);
  };


  
  const handleCloseMessageDetails = () => {
    setIsMessageDetailsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const StatusIcon = ({ status }) => {
    let icon;
    let color;
  
    switch (status) {
      case null:
        icon = <HelpOutlineIcon
        sx={{width:'7px', height:'auto', display:'flex', ml:.5}} 
         />;
        color = '#7589A2'; // Blue color
        break;
      case 'Sent':
        icon = <CheckCircleIcon 
        sx={{width:'20px', height:'auto', display:'flex', ml:.5}} 

        />;
        color = '#2BBB9E'; // Light green color
        break;
      case 'Failed':
        icon = <CancelPresentationIcon />;
        color = '#8C4350'; // Red color
        break;
      default:
        icon = null;
    }
  
    return icon ? <span style={{ color }}>{icon}</span> : null;
  };


  // Fetch notification history when the dialog opens or the customer changes

  
  

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${month}-${day}-${year}`;
  };
  

  const formatTime = (timeStr) => {
    const time = new Date(timeStr);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return time.toLocaleTimeString(undefined, options);
  };

  

  const getInitials = () => {
    if ( customer && customer.first_name && customer.last_name) {
      const firstNameInitial = customer.first_name[0];
      const lastNameInitial = customer.last_name[0];
      return `${firstNameInitial}${lastNameInitial}`;
    }
    return '';
  };


  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: '#FFFFFF',height: '42.5px', letterSpacing:'-1%', lineHeight:'-1%' ,minHeight:'65px'   

          },
        },
      },
    },
  });

  const StyledHeader = styled(TableCell)({  

    color: '#447c92',borderBottom: 'none',letterSpacing:'-2%', lineHeight:'-2%',minHeight:'65px'
  });

  const StyledRow = styled(TableCell)({  
    color:'#5e9fbc',letterSpacing:-.1, lineHeight:2.5 , backgroundColor: 'rgba(173, 216, 230, 0.2)',marginTop:1, fontSize:15,fontFamily: "Source Sans Pro, sans-serif", borderBottom: 'none', height:'50px'
  });

  const StyledHeaderText = styled(Typography)({ 
    fontWeight:500, fontSize:13.5, align:'center',margin:'auto', letterSpacing:'-2%', lineHeight:'-2%',minHeight:'20px'
  });



  return (
    <Dialog open={open} onClose={onClose} 
      PaperProps={{ sx: { borderRadius: "11px", minHeight:'200px', maxWidth:'650px',   } }}
    >
     



	
      <DialogContent
        sx={{
          width: '100%',
          backgroundColor: "transparent",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
                  
                  <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>

      
            <Box display="flex" flexDirection="column">
          
              

            <Card sx={{
  borderColor: 'rgba(173, 216, 230, 0.9)',
  borderWidth: '.75px',
  borderStyle: 'solid',
  borderRadius: '12px',
  backgroundColor: 'rgba(173, 216, 230, 0.2)',
  width: 'auto',
  minHeight: '120px',
  overflowY: 'auto',
  pt: .5
}}>
  <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', mt: '10px', mb: .5, ml: '10px' }}>
    <Typography variant="h6" sx={{
      color: '#48768C',
      fontWeight: 700,
      pl: '5px',
      lineHeight: '-2%',
      letterSpacing: '-2%'
    }}>
      {customer ? `${customer.first_name} ${customer.last_name}` : ''}
    </Typography>

    <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
      <img src={phoneIcon} alt="phone" style={{
        width: "auto",
        height: "20px",
        padding: "1px",
        border: "1px solid #B0BEC5",
        borderRadius: "8px",
        backgroundColor: '#FFFFFF',
        marginLeft: '5px',
        alignSelf: 'center',
        marginRight: '5px'
      }} />
      <Typography variant="body2" sx={{
        color: '#48768C',
        alignItems: 'center',
        lineHeight: '-2%',
        letterSpacing: '-2%',
        fontWeight: 600
      }}>
        {customer ? customer.phone_number : ''}
      </Typography>
    </Box>
  </Box>

  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Paper sx={{
      width: 'auto',
      overflow: 'hidden',
      minHeight: '260px',
      backgroundColor: 'transparent',
      mt: 1.5,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <TableContainer sx={{
        width: '100%',
        maxHeight: '58vh',
        overflowY: 'auto',
        border: '1.25px solid rgba(173, 216, 230, 0.6)',
        borderRadius: '15px',
        flexGrow: 1,  // Allow the table to take up remaining space
        backgroundColor: '#FFFFFF',  
        borderBottomLeftRadius: '1px', 
        borderBottomRightRadius: '1px',

        borderBottom:'none',  
        
        
      }}>
        <Table sx={{
          width: '600px',
  
          
        }} size="small" stickyHeader>
          <TableHead>
            <TableRow sx={{
              backgroundColor: 'rgba(255, 255, 255, 1.9)',
              "& > *": { whiteSpace: 'nowrap' }
            }}>
              <TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', padding: '5px', margin: '0', borderBottom: '1px solid rgba(173, 216, 230, 0.9)' }}>Date</TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', padding: '5px', margin: '0', borderBottom: '1px solid rgba(173, 216, 230, 0.9)' }}>Ticket</TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', padding: '5px', margin: '0', borderBottom: '1px solid rgba(173, 216, 230, 0.9)' }}>Type</TableCell>
              {!accountData.single_location && <TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', padding: '5px', margin: '0', borderBottom: '1px solid rgba(173, 216, 230, 0.9)' }}>Location</TableCell>}
              {!accountData.single_user && <TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', margin: '0', borderBottom: '1px solid rgba(173, 216, 230, 0.9)' }}>Sent By</TableCell>}
              <TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', padding: '5px', margin: '0', borderBottom: '1px solid rgba(173, 216, 230, 0.9)' }}>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{}}>
            {Array.isArray(notificationHistory) && notificationHistory.length > 0 ? notificationHistory.map((notification) => (
              <TableRow key={notification.communication_id} sx={{
                borderBottom: 'none', cursor: 'pointer',
                "& > *": { whiteSpace: 'nowrap' },

              }} onClick={() => handleOpenMessageDetails(notification)}>
                <StyledRow align="center" sx={{fontWeight:'bold'}}  >{formatDate(notification.created_at)}</StyledRow>
                <StyledRow align="center">{notification.ticket_number}</StyledRow>
                <StyledRow align="center"><StatusTag status={notification.status} size="small" /></StyledRow>
                {!accountData.single_location && <StyledRow align="center">{notification.location}</StyledRow>}
                {!accountData.single_user && <StyledRow align="center">{notification.username}</StyledRow>}
                <StyledRow align="center"><StatusIcon status={notification.message_status} /></StyledRow>
              </TableRow>
            )) : null}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        sx={{ flexShrink: 0, alignSelf: 'flex-end',     backgroundColor: '#FFFFFF',
           width:'100%', color:'#48768C', border:'1px solid #48768C5E',borderLeft:'none', borderRight:'none', borderBottom:'none', }} // Ensure pagination stays at the bottom
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={notificationHistory.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  </Box>
</Card>
        </Box>

        <MessageDetails 
    open={isMessageDetailsOpen} 
    selectedRow={selectedRow}
     onClose={handleCloseMessageDetails}
    setSelectedPhoneNumber={setSelectedPhoneNumber} 
    />
        
      </DialogContent>
    </Dialog>
  );
};


CustomerDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired, // Assuming customer is an object with properties like first_name, last_name, phone_number, etc.
};

export default CustomerDetailsDialog;
