import React, {useEffect, useState} from 'react';   
import { Card, Grid, Typography, Button, Paper, Box, Stack, Divider } from '@mui/material'; 
import NanoIcon from 'src//public/assets/illustrations/logo512.png'
import BlurCircularTwoToneIcon from '@mui/icons-material/BlurCircularTwoTone';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import MultiLocationIcon from 'src/public/assets/icons/icons/multiLocationIcon.png';




const PlanCard = ({ planDetails, name, status, cost, reoccurrence, paymentMethod, subscriptionId, customerId, priceId, onSubscriptionUpdate, subscriptionValid, stripePromise }) => {

    const [showNewSubscription, setShowNewSubscription] = React.useState(false);








    return (
        <Paper align="center" elevation={6} sx={{        border: '1.25px solid rgba(173, 216, 230, 0.5)',  maxWidth:'54%', borderRadius:'15px'      
        }}   >
        <Card sx={{backgroundColor: 'rgba(173, 216, 230, 0.3)'}}>
            <Box sx={{padding:1}}>
            <Paper elevation={6}
  sx={{
    mb: 1,
    backgroundColor: '#FFFFFF',
    width: '31px',
    height: '31px',
    mr: 'auto',
    display: 'flex', // Use flexbox to center content
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    borderRadius: '5px',  
    border: '1.25px solid rgba(173, 216, 230, 0.9)',
    mt:'5px'
  }}
>
  <img
    src={MultiLocationIcon}
    alt="Nano Logo"


    style={{ width: '28px', height: '28px', color: '#48B09B' }}
  />
</Paper>
            <Stack direction="column"sx={{ml:0,}}>
                <Typography align="left" sx={{fontSize:'16px', fontWeight:550, color:'#48768C',letterSpacing:'-.2px', lineHeight:'-2'}}>
{planDetails ? planDetails.plan_name : ''}  
                </Typography>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <Typography align="left" sx={{fontSize:'31px', fontWeight:550, color:'#48768C',letterSpacing:'-.2px', lineHeight:'-2'}}>
{planDetails ? '$' + (planDetails.plan_cost / 100).toFixed(2) : ''}  
                </Typography>
                <Typography align="left" sx={{fontSize:'15px', fontWeight:500, color:'#48768C',letterSpacing:'-.2px', lineHeight:'-2', ml:1}}>
/month
                </Typography>
                </Box>
                <Typography align="left" sx={{fontSize:'13.5px', fontWeight:550, color:'#6c90a2',letterSpacing:'-.2px', lineHeight:'-2',  mb:1}}>
                    {planDetails ? planDetails.description : ''}  

                </Typography>
                <Divider sx={{ borderBottomWidth: '1.5px' }} />
                </Stack>
                </Box>
                <Box sx={{mb:2,}}>
                <Stack direction="row" sx={{alignItems:'center', padding:1, ml:.75, mt:-1}}>
                    <BlurCircularTwoToneIcon sx={{color:'#6c90a2', fontSize:'15px'}}/>
                    <Typography align="left" sx={{fontSize:'13.5px', fontWeight:550, color:'#6c90a2',letterSpacing:'-.2px', lineHeight:'-2', whiteSpace:'noWrap', ml:.5}}>
                    {planDetails ? planDetails.subText1 : ''}  

                    </Typography>
                    </Stack> 
           
                    <Stack direction="row" sx={{alignItems:'center', padding:1, ml:.75, mt:-1}}>
                    <BlurCircularTwoToneIcon sx={{color:'#6c90a2', fontSize:'15px'}}/>
                    <Typography align="left" sx={{fontSize:'13.5px', fontWeight:550, color:'#6c90a2',letterSpacing:'-.2px', lineHeight:'-2', whiteSpace:'noWrap', ml:.5}}>
                    {planDetails ? planDetails.subText2 : ''}  
                    </Typography>
                    </Stack> 
              
                    <Stack direction="row" sx={{alignItems:'center', padding:1, ml:.75, mt:-1}}>
                    <BlurCircularTwoToneIcon sx={{color:'#6c90a2', fontSize:'15px'}}/>
                    <Typography align="left" sx={{fontSize:'13.5px', fontWeight:550, color:'#6c90a2',letterSpacing:'-.2px', lineHeight:'-2', whiteSpace:'noWrap', ml:.5}}>
                    {planDetails ? planDetails.subText3 : ''}  
                    </Typography>
                    </Stack> 
                    <Stack direction="row" sx={{alignItems:'center', padding:1, ml:.75, mt:-1}}>
                    <BlurCircularTwoToneIcon sx={{color:'#6c90a2', fontSize:'15px'}}/>
                    <Typography align="left" sx={{fontSize:'13.5px', fontWeight:550, color:'#6c90a2',letterSpacing:'-.2px', lineHeight:'-2', whiteSpace:'noWrap', ml:.5}}>
                    {planDetails ? planDetails.subText4 : ''}  
                    </Typography>
                    </Stack>  
                  {planDetails && planDetails.subText5 && (
                    <Stack direction="row" sx={{alignItems:'center', padding:1, ml:.75, mt:-1}}>
                    <BlurCircularTwoToneIcon sx={{color:'#6c90a2', fontSize:'15px'}}/>
                    <Typography align="left" sx={{fontSize:'13.5px', fontWeight:550, color:'#6c90a2',letterSpacing:'-.2px', lineHeight:'-2', whiteSpace:'noWrap', ml:.5}}>
                    {planDetails ? planDetails.subText5 : ''}  
                    </Typography>
                    </Stack>  
                    )}
                 {planDetails && planDetails.subText6 && (
                    <Stack direction="row" sx={{alignItems:'center', padding:1, ml:.75, mt:-1}}>
                    <BlurCircularTwoToneIcon sx={{color:'#6c90a2', fontSize:'15px'}}/>
                    <Typography align="left" sx={{fontSize:'13.5px', fontWeight:550, color:'#6c90a2',letterSpacing:'-.2px', lineHeight:'-2', whiteSpace:'noWrap', ml:.5}}>
                    {planDetails ? planDetails.subText6 : ''}  
                    </Typography>
                    </Stack>  
                    )}
                   
               
                    </Box>
                   
        </Card>
        </Paper>


    )

}

export default PlanCard;    