import React, {useState, useEffect} from 'react';   
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,Box, Fade, Stack, Card, CardContent, TextField,InputAdornment, IconButton, Switch, Portal  } from '@mui/material';
import { useAccountContext } from 'src/context/AccountContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import TransferList  from './TransferList';   
import AddUserIcon from 'src/public/assets/icons/icons/addUser.png';
import { styled } from '@mui/material/styles';





const EmployeeSetupCard = ({ open, onClose, onConfirm, itemName, item, isLoading, header, message, subText, buttonAction, addedUserPrice,hideReviewDialog,
    firstName,
    lastName,
    setUserName,
    pin,
    userName,
    confirmNewPin,
    showPin,
    showConfirmNewPin,
    isAssignEmployeeVisible,
    setFirstName,
    setLastName,
    setPin,
    handleConfirmNewPinChange,
    handleTogglePinVisibility,
    toggleShowConfirmNewPin,
    setIsAssignEmployeeVisible,
    users,
    subUserData,
    name,
    handleToggle,
    checked,
    left,
    right,
    handleCheckedRight,
    handleCreateNewEmployeeClick, 
    handleCheckedLeft,
    setLeft,
    userNameTaken,
    hideCreateUserButton,
    setHideCreateUserButton,
    handleSaveNewUserClick,
    newUser,
    newUserId,
    newUserCreated,
    enableEditButtons,
    isNewUserSaved,
    isAdmin,
    setIsAdmin,
    handleCancelAddUser,
    isSaveUserButtonEnabled,
    handleClearNewUserClick,
    handleConfirmUsers,
    confirmUsers,
    isEditingUser,
    handleCancelEdit,
    locationId,
    setRight
    
    

}) => {
  const { accountData } = useAccountContext();
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);


    useEffect(() => {
        if (accountData.single_user)
        setIsAssignEmployeeVisible(true);
      }, [accountData.single_location]);
      
      
      const handleToggleSwitch = () => {
        setIsAdmin(!isAdmin);
        // Open the dialog when the switch is checked
        if (!isAdmin) {
          setInfoDialogOpen(true);
        }
      };
    
      const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
      };


      const scaleFactor = 0.75;

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42 * scaleFactor,
    height: 27.5 * scaleFactor,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: `translateX(${16 * scaleFactor}px)`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2BBB9E" : "#2BBB9E",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: `${6 * scaleFactor}px solid #fff`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22 * scaleFactor,
      height: 22 * scaleFactor,
    },
    "& .MuiSwitch-track": {
      borderRadius: (26 * scaleFactor) / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

    return (

      <Box sx={{        backgroundColor: "transparent",    height: 'auto',            // Set a fixed height
        width:'100%', gap:1,  }}>          
        

<Box sx={{ 
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'flex-start', 
  alignItems: 'flex-start', 
  width: '100%'
}}>
                    <Typography
                sx={{
                  color: '#48768C',
                  fontWeight: 600,
                  fontSize: 16.36,
                  mb: '15px',
                  mt: '5px',
                  letterSpacing: -.1,
                  lineHeight: -2,
                }}
              >
    Transfer Employees To {name}
                </Typography>
    <TransferList 
      users={users} 
      subUserData={subUserData}
      name={name}
      handleToggle={handleToggle}
      checked={checked}
      left={left}
      right={right}
      handleCheckedRight={handleCheckedRight}
      handleCheckedLeft={handleCheckedLeft}
      setLeft={setLeft}
      newUser={newUser}
      handleSaveNewUserClick={handleSaveNewUserClick}
      accountData={accountData}
      isNewUserSaved={isNewUserSaved}
      isAssignEmployeeVisible={isAssignEmployeeVisible}
      enableEditButtons={enableEditButtons}
      hideCreateUserButton={hideCreateUserButton}
      locationId={locationId}
      
    />
  </Box>







               
            </Box>


    )
}

export default EmployeeSetupCard;
