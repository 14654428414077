import react, {useEffect, useState} from 'react';   
import {Modal,  Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Card, Stack, IconButton, Paper  } from '@mui/material';
import { useAppContext } from 'src/context/AppContext';
import CloseIcon from '@mui/icons-material/Close';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PlanCard from './PlanCard';



const UpgradePlanDialog = ({ open, onClose, planDetails, users, locations, showMultiUserUpgrade, showMultiLocationUpgrade, handleOpenMultiLocationUpgrade, handleOpenMultiUserUpgrade }) => {
    const { showSnackBar } = useAppContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hideCard, setHideCard] = useState(false);  
   


    const currentPlan = planDetails?.plan_name;


    useEffect(() => {
      if (showMultiUserUpgrade || showMultiLocationUpgrade) {

        setHideCard(true);
      }
      else {
        setHideCard(false);
      }
    }, [showMultiUserUpgrade, showMultiLocationUpgrade]);




    
    return (
        <Modal open={open} onClose={onClose}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Adjust the background color and opacity
            backdropFilter: 'blur(3px)', // Apply a blur effect
          },
        }}
      >
  <Box
         style={{
           position: 'absolute',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
                backgroundColor: '#F7F9FA',
           borderRadius: '15px',
           display: hideCard ? 'none' : 'block',

         }}
       >
               <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 3,
          color: "#7589A2",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
        </IconButton>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt:1.25, width:'100%', textAlign: 'center', flexDirection:'column', padding:1}}> 
        <Typography sx={{textAlign:'center', mt:1, fontSize:"32px", fontWeight:"bold",             color: '#48768C', letterSpacing:-2.05, lineHeight:-2,       textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)'
}} >Upgrade Your Plan</Typography>
        <Typography sx={{textAlign:'center',fontSize:"20px", fontWeight:"normal",             color: '#5A8498', letterSpacing:-1, lineHeight:-2, mt:-.5, mb:1}} >
          NanoNotify works great for all time sizes. Upgrade or change your plan at anytime</Typography>
        </Box>
        <Box></Box>
        <Stack spacing={2} direction="row" sx={{ display:'flex',  width:'100%', my:2, px:2, pb:2, width:'100%', alignItems:'center', justifyContent:'center' }}> 

        {currentPlan !== 'Multi User Plan' && (
           <PlanCard
           onClose={onClose}
            loading={loading}
            title="Single User Plan"
            subtitle="For companies with one employee or who only need one user"
            price={'$5'}
            feature1="Includes support for one user"
            feature2="Schedule Notifications Included"
            feature3="Two-Way Notification Included"
            feature4="Create & Send Custom Notifications"
            isCurrentPlan={planDetails && planDetails.plan_name === 'Single User Plan'} 
            
           /> 
        )}



<PlanCard
           onClose={onClose}
            loading={loading}
            title="Multi User Plan"
            subtitle="For companies with multiple employees in a single location"
            price={'$10'}
            feature1="Includes additional user at no extra cost"
            feature2="Add or remove users at anytime"
            feature3="Manage user roles & permissions for your team"  
            feature4="Additional Users $5/month" 
            isCurrentPlan={planDetails && planDetails.plan_name === 'Multi User Plan'}
            users={users}
            locations={locations}
            buttonAction={handleOpenMultiUserUpgrade}
           /> 

<PlanCard
           onClose={onClose}
            loading={loading}
            title="Multi Location Plan"
            subtitle="For companies with multiple locations and larger teams"
            price={'$20'}
            feature1="Includes additional user & location at no extra cost"
            feature2="Add or remove users or locations at anytime"
            feature3="Additional locations $10/month"  
            feature4="Additional users $5/month" 
            isCurrentPlan={planDetails && planDetails.plan_name === 'Multi Location Plan'}
            users={users}
            locations={locations}
            buttonAction={handleOpenMultiLocationUpgrade}
           /> 
        </Stack>
        </Box>
      
        </Modal>
       
    );
}

export default UpgradePlanDialog;
